import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from '@azure/msal-react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { AutoUpdateField } from '../../components';
import { IconMicrosoft } from '../../components/Icons';
import { loginRequest, UserRole } from '../../constants';
import { User } from '../../models';
import { useOvermind } from '../../overmind';
import ChangeAvatar from './ChangeAvatar';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		summary: {
			backgroundColor: theme.palette.grey[300],
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5,
		},
		details: {
			padding: theme.spacing(2),
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: '33.33%',
			flexShrink: 0,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
		formControl: {
			margin: theme.spacing(1, 0, 2),
			minWidth: 120,
		},
		field: {
			margin: theme.spacing(1, 0),
		},
	})
);

interface Props {
	user: User;
}

function FormEdit(props: Props) {
	const { user } = props;
	const classes = useStyles();
	const { instance } = useMsal();
	const [role, setRole] = useState<string>('');

	const {
		state: { authentication },
		actions,
	} = useOvermind();

	useEffect(() => {
		if (user) {
			setRole(user.role);
		}
	}, [user]);

	const handleUpdate = async (payload: Partial<User>) => {
		await actions.user.update({
			userId: user.id.toString(),
			payload: payload,
		});
	};

	const handleChangeRole = async (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		const role = event.target.value as string;

		setRole(role);
		await actions.user.update({
			userId: user.id.toString(),
			payload: {
				role,
			},
		});
	};

	const handleConnectMicrosoft = () => {
		instance.loginPopup({
			...loginRequest,
		});
	};

	return (
		<>
			<Accordion expanded={true}>
				<AccordionSummary className={classes.summary}>
					<Typography variant="h6" className={classes.heading}>
						Personal Information
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<AutoUpdateField
								label="Full name"
								name="name"
								value={user.name}
								onUpdate={handleUpdate}
							/>
							<DatePicker
								name="name"
								disableFuture
								openTo="year"
								format="dd/MM/yyyy"
								label="Date of birth"
								views={['year', 'month', 'date']}
								value={user.birthDay}
								onChange={(date) => {
									if (!date) return;

									handleUpdate({
										birthDay: format(date, 'yyyy-MM-dd'),
									});
								}}
								animateYearScrolling
								className={classes.field}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<AutoUpdateField
								label="Prefix"
								name="prefix"
								value={user.prefix}
								onUpdate={handleUpdate}
							/>

							<FormControl component="fieldset" className={classes.field}>
								<FormLabel component="legend">Gender</FormLabel>
								<RadioGroup
									row
									aria-label="gender"
									name="sex"
									value={user.sex}
									onChange={(event, value) => handleUpdate({ sex: value })}
								>
									<FormControlLabel
										value="female"
										control={<Radio />}
										label="Female"
									/>
									<FormControlLabel
										value="male"
										control={<Radio />}
										label="Male"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={true}>
				<AccordionSummary className={classes.summary}>
					<Typography variant="h6" className={classes.heading}>
						Contact Information
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Grid container>
						<Grid item md={4}>
							<AutoUpdateField
								label="Email"
								name="email"
								value={user.email}
								readOnly
							/>

							<AutoUpdateField
								label="Phone"
								name="phone"
								value={user.phone}
								onUpdate={handleUpdate}
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={true}>
				<AccordionSummary className={classes.summary}>
					<Typography variant="h6" className={classes.heading}>
						User Information
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Grid container>
						<Grid item md={4}>
							<AutoUpdateField
								label="Position"
								name="position"
								value={user.position}
								onUpdate={handleUpdate}
							/>

							<AutoUpdateField
								label="Username"
								name="username"
								value={user.username}
								onUpdate={handleUpdate}
							/>

							{authentication.user.role === 'admin' &&
								authentication.user.id !== user.id && (
									<FormControl className={classes.formControl}>
										<InputLabel id="role-label">Role</InputLabel>
										<Select
											labelId="role-label"
											id="role"
											value={role}
											onChange={handleChangeRole}
										>
											{Object.values(UserRole).map((role, index) => (
												<MenuItem key={index} value={role}>
													{role.toUpperCase()}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}

							<ChangeAvatar userId={user.id} value={user.avatarUrl} />
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={true}>
				<AccordionSummary className={classes.summary}>
					<Typography variant="h6" className={classes.heading}>
						Security
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<ChangePassword userId={user.id} />
				</AccordionDetails>
			</Accordion>

			{user.id === authentication.user.id && (
				<Accordion expanded={true}>
					<AccordionSummary className={classes.summary}>
						<Typography variant="h6" className={classes.heading}>
							User Connects
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<UnauthenticatedTemplate>
							<Button
								variant="contained"
								color="primary"
								startIcon={<IconMicrosoft />}
								onClick={handleConnectMicrosoft}
							>
								Connect with Microsoft
							</Button>
						</UnauthenticatedTemplate>

						<AuthenticatedTemplate>
							<Button
								variant="outlined"
								startIcon={<IconMicrosoft />}
								onClick={() => instance.logout()}
							>
								Disconnect with Microsoft
							</Button>
						</AuthenticatedTemplate>
					</AccordionDetails>
				</Accordion>
			)}
		</>
	);
}

export default FormEdit;
