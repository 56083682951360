import { MsGraph } from '../../helpers';
import { AddEventPayload } from '../../models';

export const api = {
	async fetch(startDate: string, endDate: string, accessToken: string) {
		try {
			const client = new MsGraph(accessToken);

			const response = await client.getCalendar(startDate, endDate);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async create(payload: AddEventPayload, accessToken: string) {
		try {
			const client = new MsGraph(accessToken);

			const response = await client.createEvent(payload);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
