import { json } from 'overmind';
import React from 'react';
import { Comment, User } from '../../../../../../models';
import { useOvermind } from '../../../../../../overmind';
import CommentItem from './Comment';
import Create from './Create';

interface Props {
	ticketId: string;
	projectUsers: User[];
}

function Comments(props: Props) {
	const { ticketId, projectUsers } = props;

	const {
		state: {
			comment: { data: comments },
		},
	} = useOvermind();

	const sortByNewest = (items: Comment[], sortField: string) =>
		items.sort((a, b) => -a[sortField].localeCompare(b[sortField]));

	return (
		<>
			<Create ticketId={ticketId} projectUsers={projectUsers} />
			{sortByNewest(json(comments), 'createdAt').map((comment: Comment) => (
				<CommentItem key={comment.id} comment={comment} />
			))}
		</>
	);
}

export default Comments;
