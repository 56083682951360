import { Grid, Paper, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import logo from '../../assets/images/css-logo.png';
import {
	ContainedButton,
	Page,
	PageTitle,
	PasswordField,
} from '../../components';
import { isFieldError } from '../../helpers';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { LoginPayload } from './models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			padding: theme.spacing(1),
			[theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
				width: 400,
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		logo: {
			margin: theme.spacing(5, 0, 0),
		},
		paper: {
			marginTop: theme.spacing(8),
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2),
			width: '90%',
			background: theme.palette.grey[100],
			border: `1px solid ${theme.palette.grey[300]}`,
		},
		button: {
			marginTop: theme.spacing(2),
		},
		field: {
			marginTop: theme.spacing(2),
			margingBottom: theme.spacing(2),
		},
		title: {
			fontWeight: 'bold',
			color: theme.palette.primary.main,
		},
	})
);

const validationSchema = yup.object().shape({
	email: yup
		.string()
		.required('general.validation.required')
		.email('general.validation.email.pattern'),
	password: yup.string().required('general.validation.required'),
});

const LoginScreen = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { search } = useLocation();
	const initialValues: LoginPayload = { email: '', password: '' };

	const {
		state: {
			authentication: { isLoggedIn, isLoading },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (isLoggedIn) {
			actions.authentication.logout();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = (values: LoginPayload) => {
		actions.authentication.login(values);
	};

	useEffect(() => {
		if (!isLoggedIn) {
			return;
		}

		const query = new URLSearchParams(search);
		const returnUrl = query.get('returnUrl');
		if (returnUrl) {
			history.replace(returnUrl);
		} else {
			history.replace('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn]);

	return (
		<Page title={t('login.title')}>
			<div className={classes.root}>
				<img src={logo} alt="CSS Logo" className={classes.logo} />
				<Paper className={classes.paper} elevation={0}>
					<PageTitle> {t('login.title')}</PageTitle>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
						}) => (
							<form noValidate onSubmit={handleSubmit}>
								<Grid item className={classes.field}>
									<TextField
										label={t('login.form.emailAddress.label')}
										id="email"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										variant="filled"
										size="small"
										fullWidth
										error={isFieldError(touched.email, errors.email)}
										helperText={
											touched.email && errors.email && t(errors.email)
										}
									/>
								</Grid>
								<Grid item className={classes.field}>
									<PasswordField
										label={t('login.form.password.label')}
										id="password"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										variant="filled"
										size="small"
										fullWidth
										error={isFieldError(touched.password, errors.password)}
										helperText={
											touched.password && errors.password && t(errors.password)
										}
									/>
								</Grid>
								<Grid item className={classes.field}>
									<ContainedButton
										fullWidth
										color="primary"
										type="submit"
										disabled={isLoading}
										isLoading={isLoading}
									>
										{t('general.button.login')}
									</ContainedButton>
								</Grid>
								{/* <Grid item className={classes.button}>
									<OutlinedButton
										fullWidth
										color="primary"
										type="button"
										onClick={() => history.push('forgot-password')}
									>
										{t('general.button.forgotPassword')}
									</OutlinedButton>
								</Grid> */}
							</form>
						)}
					</Formik>
				</Paper>
			</div>
		</Page>
	);
};

export default LoginScreen;
