import { Configuration, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
	auth: {
		clientId: '76b85372-e7a5-46f7-96fe-181e94f87a32',
	},
};

export const loginRequest: PopupRequest = {
	scopes: [
		'User.Read',
		'Mailboxsettings.Read',
		'Mail.ReadWrite',
		'Mail.Send',
		'Calendars.Read',
		'Calendars.ReadWrite',
		'offline_access',
	],
};
