/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';

import { KeyCodes } from '../../../../../../../../constants';
import { Box } from '@material-ui/core';

interface Props {
	setFormOpen: (value: boolean) => void;
}

const ProTip = (props: Props) => {
	const { setFormOpen } = props;
	const isFocusedElementEditable = () =>
		!!document.activeElement!.getAttribute('contenteditable') ||
		['TEXTAREA', 'INPUT'].includes(document.activeElement!.tagName);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (!isFocusedElementEditable() && event.keyCode === KeyCodes.M) {
				event.preventDefault();
				setFormOpen(true);
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [setFormOpen]);

	return (
		<Box display="flex" alignItems="center" paddingTop={1} fontSize={13}>
			<strong>Pro tip:</strong>press
			<Box
				bgcolor={'#dfe1e6'}
				color={'#172b4d'}
				marginX={'4px'}
				paddingX={'4px'}
			>
				M
			</Box>
			to comment
		</Box>
	);
};

export default ProTip;
