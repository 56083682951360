import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components';
import { UserRole } from './constants/users';
import { LoginScreen } from './features/authentication';
import { CalendarScreen } from './features/calendar';
import {
	AddScreen as ClientAddScreen,
	DetailsScreen as ClientDetailsScreen,
	ListScreen as ClientListScreen,
} from './features/clients';
import { SettingsScreen } from './features/configurations';
import {
	DetailsScreen as MailDetailsScreen,
	ListScreen as MailListScreen,
} from './features/mails';
import {
	AddScreen as ProductAddScreen,
	EditScreen as ProductEditScreen,
	ListScreen as ProductListScreen,
} from './features/products';
import { ProfileScreen } from './features/profile';
import {
	CreateQuotationScreen,
	DetailsScreen as ProjectDetailsScreen,
	EditQuotation,
	EditScreen as ProjectEditScreen,
	ListScreen as ProjectListScreen,
	SendEmailScreen,
} from './features/projects';
import {
	AddScreen as TemplateAddScreen,
	EditScreen as TemplateEditScreen,
	ListScreen as TemplateListScreen,
} from './features/templates';
import {
	AddScreen as UserAddScreen,
	EditScreen as UserEditScreen,
	ListScreen as UserListScreen,
} from './features/users';

const Routes = () => (
	<Switch>
		<Route path="/" exact>
			<Redirect to="/projects" />
		</Route>

		<Route path="/login">
			<LoginScreen />
		</Route>

		<PrivateRoute
			exact
			path="/projects"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ProjectListScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/projects/ticket/:ticketId/edit"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ProjectEditScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/projects/ticket/:ticketId/create-quotation"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<CreateQuotationScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/projects/ticket/:ticketId/edit-quotation"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<EditQuotation />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/projects/ticket/:ticketId/send-email"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<SendEmailScreen />
		</PrivateRoute>

		<PrivateRoute
			path="/projects/ticket/:ticketId"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ProjectDetailsScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/mailbox/id/:messageId"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<MailDetailsScreen />
		</PrivateRoute>

		<PrivateRoute path="/mailbox" roles={[UserRole.ADMIN, UserRole.CHANDLER]}>
			<MailListScreen />
		</PrivateRoute>

		<PrivateRoute path="/calendar" roles={[UserRole.ADMIN, UserRole.CHANDLER]}>
			<CalendarScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/products"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ProductListScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/products/add"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ProductAddScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/products/:productId/edit"
			roles={[UserRole.ADMIN]}
		>
			<ProductEditScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/clients"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ClientListScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/clients/add"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ClientAddScreen />
		</PrivateRoute>

		<PrivateRoute
			path="/clients/:clientId"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ClientDetailsScreen />
		</PrivateRoute>

		<PrivateRoute exact path="/templates" roles={[UserRole.ADMIN]}>
			<TemplateListScreen />
		</PrivateRoute>

		<PrivateRoute exact path="/templates/new" roles={[UserRole.ADMIN]}>
			<TemplateAddScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/templates/:templateId/edit"
			roles={[UserRole.ADMIN]}
		>
			<TemplateEditScreen />
		</PrivateRoute>

		<PrivateRoute exact path="/users" roles={[UserRole.ADMIN]}>
			<UserListScreen />
		</PrivateRoute>

		<PrivateRoute exact path="/users/new" roles={[UserRole.ADMIN]}>
			<UserAddScreen />
		</PrivateRoute>

		<PrivateRoute exact path="/users/:userId/edit" roles={[UserRole.ADMIN]}>
			<UserEditScreen />
		</PrivateRoute>

		<PrivateRoute exact path="/settings" roles={[UserRole.ADMIN]}>
			<SettingsScreen />
		</PrivateRoute>

		<PrivateRoute
			exact
			path="/profile"
			roles={[UserRole.ADMIN, UserRole.CHANDLER]}
		>
			<ProfileScreen />
		</PrivateRoute>
	</Switch>
);

export default Routes;
