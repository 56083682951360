import { Box, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add as AddIcon, Backup as BackupIcon } from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useRouteMatch } from 'react-router-dom';
import { ContainedButton, Page, PageTitle, TextField } from '../../components';
import { UserHelper } from '../../helpers';
import { useCurrentUser } from '../../hooks';
import { Product } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { tableStyles } from '../../utils/styles';
import { LinearIndeterminate } from '../mails/components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			padding: theme.spacing(3, 0, 0),
		},
		action: {
			'& > *': {
				marginLeft: theme.spacing(1),
			},
		},
		dropzone: {
			marginBottom: theme.spacing(2),
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

const ButtonAccept = (props: any) => {
	const { row } = props;
	const {
		state: {
			product: { isLoading },
		},
		actions,
	} = useOvermind();

	const handleClick = async () => {
		await actions.product.accept(row.id);
	};

	return (
		<ContainedButton
			size="small"
			color="default"
			onClick={handleClick}
			isLoading={isLoading}
		>
			Accept
		</ContainedButton>
	);
};

function ListScreen() {
	const classes = useStyles();
	const { url } = useRouteMatch();

	const {
		state: {
			product: { data, isLoading },
		},
		actions,
	} = useOvermind();

	const { currentUser } = useCurrentUser();

	const userHelper = new UserHelper(currentUser);

	const [open, setOpen] = useState(false);
	const [filterText, setFilterText] = useState('');

	useEffect(() => {
		actions.product.fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filteredItems = (products: Product[]) =>
		products.filter(
			(product: Product) =>
				(product.name &&
					product.name.toLowerCase().includes(filterText.toLowerCase())) ||
				(product.tags &&
					product.tags.toLowerCase().includes(filterText.toLowerCase()))
		);

	const handleImport = async (files: File[]) => {
		const formData = new FormData();
		formData.append('filename', files[0]);

		await actions.product.importProducts(formData);
		actions.product.fetch();

		setOpen(false);
	};

	const handleRowClicked = (row: any) => {
		if (userHelper.isAdmin()) {
			history.push(`${url}/${row.id}/edit`);
		}
	};

	const columns = [
		{
			name: 'Name',
			selector: 'name',
			sortable: true,
			grow: 3,
			style: {
				color: '#202124',
				fontSize: '14px',
				fontWeight: 500,
			},
		},
		{
			name: 'Unit',
			selector: 'unit',
			style: {
				color: 'rgba(0,0,0,.54)',
			},
			width: '75px',
		},
		{
			name: 'Purchase Price (f)',
			selector: 'price',
			sortable: true,
			style: {
				color: 'rgba(0,0,0,.54)',
			},
		},
		{
			name: 'Margin (%)',
			selector: 'margin',
			sortable: true,
			style: {
				color: 'rgba(0,0,0,.54)',
			},
		},
		{
			name: 'Selling Price ($)',
			selector: 'sellingPrice',
			sortable: true,
			style: {
				color: 'rgba(0,0,0,.54)',
			},
		},
		{
			name: 'Company',
			selector: 'company',
			sortable: true,
			style: {
				color: 'rgba(0,0,0,.54)',
			},
		},
		{
			name: 'Category',
			selector: 'category.name',
			sortable: true,
			style: {
				color: 'rgba(0,0,0,.54)',
			},
		},
		{
			// eslint-disable-next-line react/display-name
			cell: (row) =>
				!row.isAccepted && userHelper.isAdmin() ? (
					<ButtonAccept row={row} />
				) : null,
			name: '',
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	return (
		<>
			<Page title="Products">
				<PageTitle>Products</PageTitle>

				<Box paddingY={3}>
					<Box
						display="flex"
						justifyContent="flex-end"
						className={classes.action}
					>
						<TextField
							variant="outlined"
							placeholder="Search"
							type="search"
							value={filterText}
							onChange={(e) => setFilterText(e.target.value)}
						/>

						<Button
							variant="contained"
							color="inherit"
							startIcon={<BackupIcon />}
							onClick={() => setOpen(true)}
						>
							Import
						</Button>

						<Button
							variant="contained"
							color="inherit"
							startIcon={<AddIcon />}
							onClick={() => history.push('/products/add')}
						>
							New
						</Button>
					</Box>
					<DataTable
						customStyles={tableStyles}
						columns={columns}
						data={filteredItems(data)}
						pagination
						highlightOnHover
						pointerOnHover
						noHeader
						progressPending={isLoading}
						progressComponent={<LinearIndeterminate />}
						persistTableHead
						className={classes.table}
						onRowClicked={handleRowClicked}
					/>
				</Box>
			</Page>

			<DropzoneDialog
				filesLimit={1}
				acceptedFiles={['.xlsx', '.xls', '.csv']}
				cancelButtonText={'Cancel'}
				submitButtonText={'Import'}
				maxFileSize={5000000}
				open={open}
				onClose={() => setOpen(false)}
				onSave={handleImport}
				showPreviews={true}
				showFileNamesInPreview={true}
				showPreviewsInDropzone={false}
				useChipsForPreview
				previewGridProps={{
					container: { spacing: 1, direction: 'row' },
				}}
				previewChipProps={{ classes: { root: classes.previewChip } }}
				previewText="Selected file"
				dropzoneClass={classes.dropzone}
				showAlerts={false}
			/>
		</>
	);
}

export default ListScreen;
