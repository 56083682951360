import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { ContainedButton, TextButton } from '../../../components';
import { isFieldError } from '../../../helpers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		body: {
			minHeight: 200,
		},
		bodyField: {
			height: 150,
		},
		button: {
			'& > *': {
				marginRight: theme.spacing(1),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	name: Yup.string().required('This field is required'),
	body: Yup.string().required('This field is required'),
});

interface Props {
	initialValues: { name: string; body: string };
	onSubmit: (values: any) => void;
	onDelete?: () => void;
	isEdit?: boolean;
	isLoading: boolean;
}

const Form = (props: Props) => {
	const { initialValues, onSubmit, onDelete, isEdit, isLoading } = props;
	const classes = useStyles();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				values,
				errors,
				touched,
			}) => (
				<form onSubmit={handleSubmit} className={classes.form}>
					<TextField
						fullWidth
						label="Name"
						name="name"
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.name, errors.name)}
						helperText={touched.name && errors.name}
						value={values.name}
					/>

					<FormControl
						fullWidth
						error={errors.body ? true : false}
						className={classes.body}
					>
						<ReactQuill
							theme="snow"
							id="body"
							value={values.body}
							onChange={(value) => setFieldValue('body', value)}
							className={classes.bodyField}
						/>
					</FormControl>
					{errors.body && <FormHelperText error>{errors.body}</FormHelperText>}

					<div className={classes.button}>
						{isEdit ? (
							<>
								<ContainedButton type="submit" isLoading={isLoading}>
									Edit template
								</ContainedButton>
								<TextButton startIcon={<Icon>delete</Icon>} onClick={onDelete}>
									Delete
								</TextButton>
							</>
						) : (
							<ContainedButton type="submit" isLoading={isLoading}>
								Add template
							</ContainedButton>
						)}
					</div>
				</form>
			)}
		</Formik>
	);
};

export default Form;
