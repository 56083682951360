import { useAccount, useMsal } from '@azure/msal-react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import SendIcon from '@material-ui/icons/Send';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { forwardRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ContainedButton } from '../../../components';
import { loginRequest } from '../../../constants';
import { getBase64, MsGraph } from '../../../helpers';
import { useOvermind } from '../../../overmind';
import { Attachment, Recipient } from '../models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			margin: theme.spacing(5, 0, 2),
			padding: theme.spacing(2),
			backgroundColor: theme.palette.grey[100],
			borderRadius: 10,

			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		icon: {
			marginRight: theme.spacing(1),
		},
		body: {
			backgroundColor: theme.palette.common.white,

			'& .ql-toolbar': {
				borderTopRightRadius: 5,
				borderTopLeftRadius: 5,
			},

			'& .ql-container': {
				height: 150,
				borderBottomRightRadius: 5,
				borderBottomLeftRadius: 5,
			},
		},
		dropzone: {
			minHeight: 95,
		},
		dropzoneParagraph: {
			fontSize: 14,
			margin: theme.spacing(1, 0),
		},
		previewChip: {
			maxWidth: 210,
		},
	})
);

interface Props {
	subject: string;
	toRecipients: Recipient[];
	onHideForm: () => void;
}

function FormCompose(props: Props, ref) {
	const { subject, toRecipients, onHideForm } = props;
	const classes = useStyles();
	const [isLoading, setLoading] = useState(false);
	const [body, setBody] = useState<string>('');
	const [attachments, setAttachments] = useState<File[]>([]);
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});

	const { actions } = useOvermind();

	const handleSendEmail = async () => {
		const payload = {
			message: {
				subject: `RE: ${subject}`,
				body: {
					contentType: 'html',
					content: body,
				},
				attachments: await generateAttachments(attachments),
				toRecipients,
			},
			saveToSentItems: true,
		};

		if (account) {
			setLoading(true);
			instance
				.acquireTokenSilent({
					...loginRequest,
					account,
				})
				.then(async (response) => {
					const client = new MsGraph(response.accessToken);
					await client.sendEmail(payload);

					setBody('');

					setLoading(false);
					onHideForm();

					actions.alert.setAlert({
						type: 'success',
						message: 'Message sent.',
					});
				});
		}
	};

	const generateAttachments = async (files: File[]) => {
		const attachments: Attachment[] = [];

		await Promise.all(
			files.map(async (file) => {
				const res = await getBase64(file).then((result) => {
					const contentType = (result as string)
						.split(';base64,')[0]
						.split(':')[1];
					const contentBytes = (result as string).split(';base64,')[1];

					return {
						'@odata.type': '#microsoft.graph.fileAttachment',
						name: file.name,
						contentType: contentType,
						contentBytes: contentBytes,
					};
				});

				attachments.push(res);
			})
		);

		return attachments;
	};

	return (
		<div ref={ref} className={classes.wrapper}>
			<Box display="flex" alignItems="center">
				{toRecipients.length > 1 ? (
					<ReplyAllIcon className={classes.icon} />
				) : (
					<ReplyIcon className={classes.icon} />
				)}

				{toRecipients
					.map(
						(recipient: Recipient) =>
							`
							${recipient.emailAddress.name} <${recipient.emailAddress.address}>`
					)
					.join(', ')}
			</Box>

			<ReactQuill
				theme="snow"
				className={classes.body}
				value={body}
				onChange={setBody}
			/>

			<DropzoneArea
				onChange={(files) => setAttachments(files)}
				showPreviews={true}
				showPreviewsInDropzone={false}
				useChipsForPreview
				previewGridProps={{
					container: { spacing: 1, direction: 'row' },
				}}
				previewChipProps={{ classes: { root: classes.previewChip } }}
				previewText="Selected files"
				dropzoneText="Drop files to attach or click"
				dropzoneClass={classes.dropzone}
				dropzoneParagraphClass={classes.dropzoneParagraph}
				showAlerts={false}
			/>

			<ContainedButton
				startIcon={<SendIcon />}
				onClick={handleSendEmail}
				disabled={!body.length || isLoading}
				isLoading={isLoading}
			>
				Send
			</ContainedButton>
		</div>
	);
}

export default forwardRef(FormCompose);
