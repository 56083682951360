import { CreateClientPayload } from '../../features/projects/models';
import { Client, ClientVessel, Contact } from '../../models';

export const fetch = async ({ effects, actions, state }) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.fetch();

		state.client = { ...state.client, data: response };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const fetchVessel = async (
	{ effects, actions, state },
	clientId: string
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.fetchVessel(clientId);

		state.client.currentClient = {
			...state.client.currentClient,
			vessels: response,
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const fetchContact = async (
	{ effects, actions, state },
	clientId: string
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.fetchContact(clientId);

		state.client.currentClient = {
			...state.client.currentClient,
			contacts: response,
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const getById = async (
	{ effects, actions, state },
	clientId: string
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.getById(clientId);

		state.client = {
			...state.client,
			currentClient: { ...state.client.currentClient, ...response },
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const getInvoices = async ({ effects, actions, state }) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.getInvoices(
			state.client.currentClient.id
		);

		state.client = {
			...state.client,
			invoices: response,
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const create = async (
	{ effects, actions, state },
	payload: CreateClientPayload
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.create(payload);

		state.client.data = [...state.client.data, response];
		state.client.currentClient = response;
		state.ticket.currentTicket = {
			...state.ticket.currentTicket,
			client: response,
		};

		actions.alert.setAlert({
			type: 'success',
			message: `${payload.name} was created successfully`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const createVessel = async (
	{ effects, actions, state },
	payload: Partial<ClientVessel>
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.createVessel(
			state.client.currentClient.id,
			payload
		);

		const vessels = [...state.client.currentClient.vessels, response];
		state.client.currentClient = { ...state.client.currentClient, vessels };

		actions.alert.setAlert({
			type: 'success',
			message: `${payload.name} was created successfully`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const createContact = async (
	{ effects, actions, state },
	payload: Partial<Contact>
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.createContact(
			state.client.currentClient.id,
			payload
		);

		const contacts = [...state.client.currentClient.contacts, response];
		state.client.currentClient = { ...state.client.currentClient, contacts };

		actions.alert.setAlert({
			type: 'success',
			message: `${payload.name} was created successfully`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const update = async (
	{ effects, actions, state },
	payload: Partial<Client>
) => {
	state.client.isEditing = true;

	try {
		const response = await effects.client.api.update(
			state.client.currentClient.id,
			payload
		);

		state.client.data.map((client: Client) => {
			return client.id === response.id ? { ...client, ...response } : client;
		});
		state.client.currentClient = { ...state.client.currentClient, ...response };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isEditing = false;
};

export const updateVessel = async (
	{ effects, actions, state },
	payload: Partial<ClientVessel>
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.updateVessel(
			state.client.currentClient.id,
			payload
		);

		const vessels = state.client.currentClient.vessels.map(
			(vessel: ClientVessel) => {
				return vessel.id === response.id ? { ...vessel, ...response } : vessel;
			}
		);

		state.client.currentClient = { ...state.client.currentClient, vessels };

		actions.alert.setAlert({
			type: 'success',
			message: `${payload.name} updated successfully`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const updateContact = async (
	{ effects, actions, state },
	payload: Partial<Contact>
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.updateContact(
			state.client.currentClient.id,
			payload
		);

		const contacts = state.client.currentClient.contacts.map(
			(contact: ClientVessel) => {
				return contact.id === response.id
					? { ...contact, ...response }
					: contact;
			}
		);

		state.client.currentClient = { ...state.client.currentClient, contacts };

		actions.alert.setAlert({
			type: 'success',
			message: `${payload.name} updated successfully`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const remove = async ({ effects, actions, state }, clientId: string) => {
	state.client.isDeleting = true;

	try {
		const response = await effects.client.api.remove(clientId);

		const data = state.client.data.filter((item) => item.id !== response.id);

		state.client = { data, currentClient: null };
		actions.alert.setAlert({
			type: 'success',
			message: `${response.name} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isDeleting = false;
};

export const removeVessel = async (
	{ effects, actions, state },
	vesselId: string
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.removeVessel(
			state.client.currentClient.id,
			vesselId
		);

		const vessels = state.client.currentClient.vessels.filter(
			(vessel: ClientVessel) => vessel.id.toString() !== vesselId
		);

		state.client.currentClient = { ...state.client.currentClient, vessels };
		actions.alert.setAlert({
			type: 'success',
			message: `${response.name} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const removeContact = async (
	{ effects, actions, state },
	contactId: string
) => {
	state.client.isLoading = true;

	try {
		const response = await effects.client.api.removeContact(
			state.client.currentClient.id,
			contactId
		);

		const contacts = state.client.currentClient.contacts.filter(
			(contact: ClientVessel) => contact.id.toString() !== contactId
		);

		state.client.currentClient = { ...state.client.currentClient, contacts };
		actions.alert.setAlert({
			type: 'success',
			message: `${response.name} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = false;
};

export const importClients = async (
	{ effects, actions, state },
	payload: FormData
) => {
	state.client.isLoading = true;

	try {
		const data = await effects.client.api.import(payload);

		state.client = { data };

		actions.alert.setAlert({
			type: 'success',
			message: 'Clients succesfully imported.',
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.client.isLoading = true;
};

export const setCurrentClient = async ({ state }, client: Partial<Client>) => {
	state.client.currentClient = { ...state.client.currentClient, ...client };
};
