import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { parseISO, subDays } from 'date-fns';
import React from 'react';
import { TicketStatusColumn } from '../../../../../../constants';
import { useWindowSize } from '../../../../../../hooks';
import { Project, Ticket } from '../../../../../../models';
import Item from './Item';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'table-cell',
			flexDirection: 'column',
			margin: theme.spacing(0, 1),
			borderRadius: '3px',
			width: '20%',
			background: 'linear-gradient(#eeeeee, #fdfdfd)',

			[theme.breakpoints.down('md')]: {
				minWidth: '120px',
				maxWidth: '130px',
			},
		},
		title: {
			textTransform: 'uppercase',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			display: 'inline-block',
			flex: '0 100 auto',
			color: 'rgb(94 108 128 / 1)',
		},
		titlebox: {
			fontSize: 10,
			lineHeight: 1.33,
			padding: theme.spacing(1, 0),
			display: 'flex',
			maxWidth: '100%',
		},
		titleWrapBox: {
			display: 'flex',
			flexWrap: 'wrap',
			padding: theme.spacing(0, 1),
		},
		count: {
			textTransform: 'lowercase',
		},
		tickets: {
			padding: theme.spacing(0, 1),
		},
	})
);

interface Props {
	status: string;
	project: Project;
	filters: any;
	currentUserId: number;
}

function List(props: Props) {
	const classes = useStyles();
	const { status, project, filters, currentUserId } = props;
	const { tickets } = project;

	const filteredTickets = filterTickets(tickets, filters, currentUserId);
	const filteredListTickets = getSortedListTickets(filteredTickets, status);
	const allListTickets = getSortedListTickets(tickets, status);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [width, height] = useWindowSize();

	return (
		<div className={classes.root}>
			<div className={classes.titleWrapBox}>
				<div className={classes.titlebox}>
					<div
						className={classes.title}
						title={`${TicketStatusColumn[status]} `}
					>
						{`${TicketStatusColumn[status]} `}
					</div>
					<span className={classes.count}>
						({formatTicketsCount(allListTickets, filteredListTickets)})
					</span>
				</div>
			</div>

			<div
				className={classes.tickets}
				style={{
					minHeight: height - 206,
				}}
			>
				{filteredListTickets.map((ticket: Ticket) => (
					<Item key={ticket.id} ticket={ticket} />
				))}
			</div>
		</div>
	);
}
export default List;

const filterTickets = (
	projectTickets: Array<Ticket>,
	filters: any,
	currentUserId: number
) => {
	const { searchTerm, userIds, myOnly, recent } = filters;
	let tickets = projectTickets;

	if (searchTerm) {
		tickets = tickets.filter((ticket: Ticket) =>
			ticket.title.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}
	if (userIds.length > 0) {
		tickets = tickets.filter((ticket: Ticket) => {
			return ticket.user ? userIds.includes(ticket.user.id) : [];
		});
	}
	if (myOnly && currentUserId) {
		tickets = tickets.filter((ticket: Ticket) => {
			return (ticket.user && ticket.user.id) === currentUserId;
		});
	}
	if (recent) {
		tickets = tickets.filter((ticket: Ticket) =>
			subDays(parseISO(ticket.updatedAt), 3)
		);
	}
	return tickets;
};

const getSortedListTickets = (tickets, status) =>
	tickets
		.filter((ticket: Ticket) => ticket.status === status)
		.sort((a, b) => a.listPosition - b.listPosition);

const formatTicketsCount = (
	allListTickets: Array<Ticket>,
	filteredListTickets: Array<Ticket>
) => {
	if (allListTickets.length !== filteredListTickets.length) {
		return `${filteredListTickets.length} of ${allListTickets.length}`;
	}
	return allListTickets.length;
};
