import instance from '../../api/axios';

export const api = {
	async fetch() {
		try {
			const response = await instance.get(`/users`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async getById(id) {
		try {
			const response = await instance.get(`/users/${id}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async create(payload) {
		try {
			const response = await instance.post(`/users`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async update(id, payload) {
		try {
			const response = await instance.put(`/users/${id}`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async remove(id) {
		try {
			const response = await instance.delete(`/users/${id}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async uploadAvatar(id, payload) {
		try {
			const response = await instance.post(`/users/${id}/avatar`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
