import { UserRole } from '../constants/users';
import { User } from './../models';

class UserHelper {
	constructor(public user: User | null) {}

	isChandler() {
		return this.user?.role === UserRole.CHANDLER;
	}

	isAdmin() {
		return this.user?.role === UserRole.ADMIN;
	}
}

export default UserHelper;
