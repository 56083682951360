import instance from '../../api/axios';

export const api = {
	async fetch() {
		try {
			const response = await instance.get(`/vessels/types`);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
