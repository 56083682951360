/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Breadcrumbs,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	TextField,
	Typography,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ContainedButton, Page } from '../../components';
import { pattern } from '../../constants';
import { isFieldError, DateHelper } from '../../helpers';
import { Attachment, Contact, Template, Email } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { useLocation } from 'react-router-dom';

import {
	Assignee,
	Attachments,
	Description,
	Details,
} from './components/details';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			padding: theme.spacing(3),
		},
		title: {
			marginBottom: theme.spacing(3),
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			display: 'column',
			flexDirection: 'row',

			'& > *': {
				width: '80%',
				marginBottom: theme.spacing(3),
			},
		},
		body: {
			'& .ql-container': {
				height: 150,
			},
		},
		chips: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		chip: {
			margin: 2,
		},
		noLabel: {
			marginTop: theme.spacing(3),
		},
		button: {
			'& > *': {
				marginRight: theme.spacing(3),
			},
		},
		dropzone: {
			minHeight: 140,
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
		breadcrumbs: {
			marginBottom: theme.spacing(2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: 'none',

			'&:hover': {
				color: theme.palette.primary.light,
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	recipients: Yup.array().min(1, 'This field is required'),
	subject: Yup.string().required('This field is required'),
	body: Yup.string().required('This field is required'),
});

interface InitValues {
	recipients: Contact[];
	subject: string;
	template: string;
	body: string;
	attachments: Attachment[];
	filenames: File[];
}

const SendEmail = () => {
	const classes = useStyles();
	const { ticketId } = useParams<{
		ticketId: string;
	}>();
	const { t } = useTranslation();
	const emailPattern = new RegExp(pattern.email);

	const { state } = useLocation<{
		email: Email;
		isReply: boolean;
		isForward: boolean;
	}>();

	const {
		state: {
			ticket: { currentTicket, isLoading },
			client: { currentClient },
			template,
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (ticketId) actions.ticket.getById(ticketId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketId]);

	useEffect(() => {
		actions.template.fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentTicket && currentTicket.client) {
			const clientId = currentTicket.client.id as string;
			actions.client.fetchContact(clientId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTicket]);

	const handleSubmit = async (values: InitValues) => {
		const payload = { ...values };

		const recipients = payload.recipients.map((recipient) => {
			if (typeof recipient === 'string') {
				return recipient;
			} else {
				return `${recipient.name} <${recipient.email}>`;
			}
		});

		const attachments = payload.attachments.map(
			(attachment: Attachment) => attachment.filename
		);

		const formData = new FormData();
		formData.append('attachments', JSON.stringify(attachments));
		formData.append('recipients', JSON.stringify(recipients));
		formData.append('subject', payload.subject);
		formData.append('body', payload.body);

		for (let x = 0; x < payload.filenames.length; x++) {
			formData.append('filename', payload.filenames[x]);
		}

		await actions.ticket.sendEmail(formData);

		history.push({
			pathname: `/projects/ticket/${ticketId}`,
		});
	};

	const validateEmail = (email: string) => {
		return emailPattern.test(email.toLowerCase());
	};

	let contact: Contact = {
		id: 0,
		name: '',
		function: '',
		email: '',
		phone: '',
		createdAt: '',
		updatedAt: '',
	};
	let isReply = false;
	let isForward = false;
	let sender = '<br><br>---------------------------------<br>';

	if (state) {
		isReply = state?.isReply;
		isForward = state?.isForward;
		contact = {
			id: 0,
			name: state?.email.from,
			function: '',
			email: state?.email.from,
			phone: '',
			createdAt: '',
			updatedAt: '',
		};
		sender +=
			'From: ' +
			state?.email.from.replace('<', ' - [').replace('>', ']') +
			'<br>';
		sender +=
			'Sent: ' +
			DateHelper.formatDate(
				new Date(state?.email.createdAt),
				'yyyy-MM-dd, HH:mm:ss'
			) +
			'<br>';
		sender +=
			'To: ' + state?.email.to.replace('<', ' - [').replace('>', ']') + '<br>';
		sender +=
			'Subject: ' +
			state?.email.subject +
			'<br>---------------------------------<br>';
	}

	if (!currentTicket) return null;
	console.log(state?.email);
	const initialValues: InitValues = {
		recipients: state?.isReply
			? [contact]
			: currentTicket?.contact
			? [currentTicket?.contact as Contact]
			: [],
		subject: isReply
			? 'Re: ' + state?.email.subject
			: isForward
			? 'Fwd: ' + state?.email.subject
			: '',
		template: '',
		body: isForward
			? '---------- Forwarded message ---------<br>' + state?.email.body
			: isReply
			? sender + state?.email.body
			: '',
		attachments: isForward ? state?.email.attachments : [],
		filenames: [],
	};

	// const initialFiles: any[] = [];
	// currentTicket.attachments.map((attachment: Attachment) => {
	// 	initialFiles.push(
	// 		`https://cors-anywhere.herokuapp.com/https://atsemo-tickets.s3.eu-west-3.amazonaws.com/${attachment.filename}`
	// 	);
	// });

	return (
		<Page title="Mail">
			<Breadcrumbs
				aria-placeholder="breadcrumb"
				className={classes.breadcrumbs}
			>
				<Link to={`/projects`} className={classes.link}>
					Overview
				</Link>
				<Link to={`/projects/ticket/${ticketId}`} className={classes.link}>
					PRO - {ticketId}
				</Link>
				<Typography color="textPrimary">Mail</Typography>
			</Breadcrumbs>

			<Grid container>
				<Grid item xs={8}>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						{({
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
							values,
							errors,
							touched,
						}) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<FormControl
									error={
										touched.recipients &&
										errors.recipients &&
										errors.recipients.length > 0
											? true
											: false
									}
								>
									<Autocomplete
										freeSolo
										multiple
										selectOnFocus
										blurOnSelect
										disableClearable
										options={currentClient?.contacts || []}
										value={values.recipients}
										onChange={(event, newValue) => {
											setFieldValue('recipients', newValue);
										}}
										getOptionLabel={(option) => {
											if (!option) return '';

											if (typeof option === 'string') {
												const email = validateEmail(option);

												if (!email) {
													actions.alert.setAlert({
														type: 'error',
														message: t('general.validation.email.pattern'),
													});
												}

												return option;
											}

											return option.name;
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Recipient(s) to"
												placeholder="add recipient"
												error={
													touched.recipients && errors.recipients ? true : false
												}
												onKeyDown={(event: any) => {
													const value = event?.target.value;
													if (
														(event.keyCode === 13 ||
															event.keyCode === 32 ||
															event.keyCode === 188) &&
														value
													) {
														if (!validateEmail(value)) {
															actions.alert.setAlert({
																type: 'error',
																message: t('general.validation.email.pattern'),
															});

															return;
														}

														setFieldValue(
															'recipients',
															values.recipients.concat(value)
														);
													}
												}}
												onBlur={(event: any) => {
													const value = event?.target.value;
													if (!value) return;

													if (!validateEmail(value)) {
														actions.alert.setAlert({
															type: 'error',
															message: t('general.validation.email.pattern'),
														});

														return;
													}

													setFieldValue(
														'recipients',
														values.recipients.concat(value)
													);
												}}
											/>
										)}
									/>

									{errors.recipients && touched.recipients && (
										<FormHelperText error>{errors.recipients}</FormHelperText>
									)}
								</FormControl>

								<TextField
									placeholder="Subject"
									name="subject"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.subject}
									error={isFieldError(touched.subject, errors.subject)}
									helperText={touched.subject && errors.subject}
								/>

								{template.data.length && (
									<Select
										displayEmpty
										name="template"
										value={values.template}
										onChange={(event) => {
											handleChange(event);

											const templateId = event.target.value;
											template.data.forEach((template: Template) => {
												if (template.id === templateId)
													setFieldValue('body', template.body + values.body);
											});
										}}
									>
										<MenuItem value="" disabled>
											Template
										</MenuItem>
										{template.data.map((template: Template, index) => (
											<MenuItem value={template.id} key={index}>
												{template.name}
											</MenuItem>
										))}
									</Select>
								)}

								<FormControl
									fullWidth
									error={isFieldError(touched.body, errors.body)}
								>
									<ReactQuill
										theme="snow"
										value={values.body}
										onChange={(value) => setFieldValue('body', value)}
										className={classes.body}
									/>

									{errors.body && touched.body && (
										<FormHelperText error>{errors.body}</FormHelperText>
									)}
								</FormControl>

								{currentTicket?.attachments
									.filter((attachment) => attachment.isTrash !== true)
									.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)).length >
									0 && (
									<FormControl
										error={
											errors.attachments &&
											touched.attachments &&
											errors.attachments.length > 0
												? true
												: false
										}
									>
										<Autocomplete
											multiple
											id="tags-standard"
											onChange={(event, newValue) => {
												setFieldValue('attachments', newValue);
											}}
											options={
												currentTicket?.attachments.filter(
													(attachment) => attachment.isTrash !== true
												)!
											}
											getOptionLabel={(option) => option.filename}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													placeholder="Attach files"
												/>
											)}
										/>

										{errors.attachments && touched.attachments && (
											<FormHelperText error>
												{errors.attachments}
											</FormHelperText>
										)}
									</FormControl>
								)}

								<DropzoneArea
									onChange={(files) => {
										console.log(files);
										setFieldValue('filenames', files);
									}}
									showPreviews={true}
									showPreviewsInDropzone={false}
									useChipsForPreview
									previewGridProps={{
										container: { spacing: 1, direction: 'row' },
									}}
									previewChipProps={{ classes: { root: classes.previewChip } }}
									previewText="Selected files"
									dropzoneText="Drop files to attach or click"
									dropzoneClass={classes.dropzone}
									showAlerts={false}
								/>

								<div className={classes.button}>
									<ContainedButton
										endIcon={<Icon>send</Icon>}
										isLoading={isLoading}
										type="submit"
									>
										Send
									</ContainedButton>
									<Button
										color="primary"
										className="ml-2"
										onClick={() => {
											history.push({
												pathname: `/projects/ticket/${ticketId}`,
											});
										}}
									>
										Cancel
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</Grid>
				<Grid item xs={4}>
					<Details />
					<Description ticket={currentTicket!} />
					<Assignee user={currentTicket?.user!} />
					<Attachments />
				</Grid>
			</Grid>
		</Page>
	);
};
export default SendEmail;
