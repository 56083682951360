export const appName = 'Atsemo';
export const snackbarTimer = 3000;
export const defaultLimit = 20;

export const password = {
	minLength: 8,
};

export const pattern = {
	phoneNumber: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, // eslint-disable-line no-useless-escape
	decimalPattern: /^\s*(?=.*[1-9])\d*(?:\,\d{1,2})?\s*$/, // eslint-disable-line no-useless-escape
	dutchPostalCode: /^[0-9]{4}\s*([a-zA-Z]{2})?$/,
	digit: /^\d+$/,
	email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
