import { User } from '../models';

export const getStoredAuthToken = (): string | null =>
	localStorage.getItem('accessToken');

export const storeAuthToken = (token: string) =>
	localStorage.setItem('accessToken', token);

export const removeStoredAuthToken = () =>
	localStorage.removeItem('accessToken');

export const getStoredAuthUser = (): User =>
	localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user') || '{}')
		: null;

export const storeAuthUser = (user: User) =>
	localStorage.setItem('user', JSON.stringify(user));

export const removeStoredAuthUser = () => localStorage.removeItem('user');
