import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { AutoUpdateField, Page, PageTitle } from '../../components';
import { useOvermind } from '../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		summary: {
			backgroundColor: theme.palette.grey[300],
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5,
		},
		details: {
			padding: theme.spacing(2),
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: '33.33%',
			flexShrink: 0,
		},
		title: {
			marginBottom: 20,
		},
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		content: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

function SettingsScreen() {
	const classes = useStyles();

	const {
		state: {
			configuration: { data },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		actions.configuration.fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getConfiguration = (field) => {
		const configuration = data.find(
			(configuration) => configuration.field === field
		);

		return configuration ? configuration.value : '';
	};

	const handleUpdate = async (values: any) => {
		const payload: any = [];

		for (const [field, value] of Object.entries<string>(values)) {
			payload.push({
				field,
				value,
			});
		}

		actions.configuration.update(payload);
	};

	return (
		<Page title="Templates">
			<PageTitle>Settings</PageTitle>

			<Accordion expanded={true}>
				<AccordionSummary className={classes.summary}>
					<Typography variant="h6" className={classes.heading}>
						Basis Information
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<AutoUpdateField
								label="Company name"
								name="COMPANY_NAME"
								value={getConfiguration('COMPANY_NAME')}
								onUpdate={handleUpdate}
							/>
							<AutoUpdateField
								label="Email"
								name="COMPANY_EMAIL"
								value={getConfiguration('COMPANY_EMAIL')}
								onUpdate={handleUpdate}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<AutoUpdateField
								label="Telephone"
								name="COMPANY_PHONE"
								value={getConfiguration('COMPANY_PHONE')}
								onUpdate={handleUpdate}
							/>
							<AutoUpdateField
								label="Fax"
								name="COMPANY_FAX"
								value={getConfiguration('COMPANY_FAX')}
								onUpdate={handleUpdate}
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={true}>
				<AccordionSummary className={classes.summary}>
					<Typography variant="h6" className={classes.heading}>
						Address
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<AutoUpdateField
								label="Street name"
								name="COMPANY_STREET_NAME"
								value={getConfiguration('COMPANY_STREET_NAME')}
								onUpdate={handleUpdate}
							/>
							<AutoUpdateField
								label="City"
								name="COMPANY_CITY"
								value={getConfiguration('COMPANY_CITY')}
								onUpdate={handleUpdate}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<AutoUpdateField
								label="Country"
								name="COMPANY_COUNTRY"
								value={getConfiguration('COMPANY_COUNTRY')}
								onUpdate={handleUpdate}
							/>
							<AutoUpdateField
								label="Postal code"
								name="COMPANY_POSTAL_CODE"
								value={getConfiguration('COMPANY_POSTAL_CODE')}
								onUpdate={handleUpdate}
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			{/* <Accordion expanded={true}>
				<AccordionSummary className={classes.summary}>
					<Typography variant="h6" className={classes.heading}>
						Email Options
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<Typography variant="h6" className={classes.title}>
								Logon Information
							</Typography>

							<AutoUpdateField
								label="Full name"
								name="MAIL_NAME"
								value={getConfiguration('MAIL_NAME')}
								onUpdate={handleUpdate}
							/>
							<AutoUpdateField
								label="Username"
								name="MAIL_USERNAME"
								value={getConfiguration('MAIL_USERNAME')}
								onUpdate={handleUpdate}
							/>
							<AutoUpdateField
								label="Password"
								name="MAIL_PASSWORD"
								value=""
								type="password"
								onUpdate={handleUpdate}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography variant="h6" className={classes.title}>
								Server Information
							</Typography>
							<AutoUpdateField
								label="Host"
								name="MAIL_HOST"
								value={getConfiguration('MAIL_HOST')}
								onUpdate={handleUpdate}
							/>
							<AutoUpdateField
								label="Port"
								name="MAIL_PORT"
								value={getConfiguration('MAIL_PORT')}
								onUpdate={handleUpdate}
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion> */}
		</Page>
	);
}

export default SettingsScreen;
