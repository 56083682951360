import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '../../components';
import { FormEdit } from '../../components/User';
import { User } from '../../models';
import { useOvermind } from '../../overmind';

function EditScreen() {
	const { userId } = useParams<{
		userId: string;
	}>();

	const {
		state: {
			user: { data },
		},
		actions,
	} = useOvermind();

	const user = data.find((user: User) => user.id.toString() === userId);

	useEffect(() => {
		actions.user.getById(userId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Page title="Edit user">{user && <FormEdit user={user} />}</Page>;
}

export default EditScreen;
