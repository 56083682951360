import { Template } from '../../models';

export const fetch = async ({ effects, actions, state }) => {
	state.template.isLoading = true;

	try {
		const templates = await effects.template.api.fetch();

		state.template = { data: templates, isLoading: false };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.template.isLoading = false;
};

export const getById = async ({ effects, actions, state }, id: string) => {
	state.template.isLoading = true;

	try {
		const template = await effects.template.api.getById(id);

		state.template = {
			data: [template],
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.template.isLoading = false;
};

export const create = async (
	{ effects, actions, state },
	payload: Template
) => {
	state.template.isLoading = true;

	try {
		const templates = await effects.template.api.create(payload);

		state.template = {
			data: [...state.template.data, templates],
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.template.isLoading = false;
};

export const update = async (
	{ effects, actions, state },
	data: {
		templateId: string;
		payload: Template;
	}
) => {
	state.template.isLoading = true;

	try {
		const template = await effects.template.api.update(
			data.templateId,
			data.payload
		);

		state.template = {
			data: state.template.data.map((item) =>
				item.clientId === template.id ? { ...item, ...template } : item
			),
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.template.isLoading = false;
};

export const remove = async ({ effects, actions, state }, id: string) => {
	state.template.isLoading = true;

	try {
		const template = await effects.template.api.remove(id);

		const data = state.template.data.filter((item) => item.id !== template.id);

		state.template = { data, isLoading: false };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.template.isLoading = false;
};
