import { Project } from '../../models';

export const get = async ({ effects, actions, state }) => {
	state.project.isLoading = true;

	try {
		const response: Project = await effects.project.api.get();

		state.project = {
			...state.project,
			data: response,
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}
	state.project.isLoading = false;
};
