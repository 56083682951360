import React from 'react';
import { TicketStatus } from '../../../../../constants';
import { useCurrentUser } from '../../../../../hooks';
import { Project } from '../../../../../models';
import List from './List';

interface Props {
	project: Project;
	filters: any;
}

function Lists(props: Props) {
	const { project, filters } = props;

	const { currentUser } = useCurrentUser();

	return (
		<>
			{Object.values(TicketStatus).map((status) => (
				<List
					key={status}
					status={status}
					project={project}
					filters={filters}
					currentUserId={currentUser?.id}
				/>
			))}
		</>
	);
}

export default Lists;
