import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(3, 0, 1),
			textTransform: 'uppercase',
			fontSize: 12.5,
			fontWeight: 700,
			color: theme.palette.grey[600],
		},
	})
);

interface Props {
	children: React.ReactNode;
}

function SectionTitle(props: Props) {
	const classes = useStyles();
	const { children } = props;

	return <div className={classes.root}>{children}</div>;
}

export default SectionTitle;
