import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useAccount,
	useMsal,
} from '@azure/msal-react';
import { Box } from '@material-ui/core';
import { format, getDay, parse, startOfWeek } from 'date-fns';
import { formatISO } from 'date-fns/esm/fp';
import React, { useEffect, useState } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../components';
import { ConnectMicrosoft } from '../../components/User';
import { loginRequest, UserRole } from '../../constants';
import { DateHelper } from '../../helpers';
import { useWindowSize } from '../../hooks';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import AddScreen from './AddScreen';

const locales = {
	'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales,
});

function CalendarScreen() {
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});
	const { path, url } = useRouteMatch();

	const [view, setView] = useState<string>('month');
	const [accessToken, setAccessToken] = useState<string>('');
	// const [data, setData] = useState<any[]>([]);

	const {
		state: {
			event: { data },
		},
		actions,
	} = useOvermind();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [width, height] = useWindowSize();

	useEffect(() => {
		if (account) {
			instance
				.acquireTokenSilent({
					...loginRequest,
					account,
				})
				.then(async (response) => {
					setAccessToken(response.accessToken);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	useEffect(() => {
		if (accessToken.length > 0) {
			const startDate = DateHelper.startOfMonthString(new Date());
			const endDate = DateHelper.endOfMonthString(new Date());

			getCalendar(startDate, endDate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

	const getCalendar = async (startDate, endDate) => {
		actions.event.fetch({
			startDate,
			endDate,
			accessToken,
		});
	};

	const onNavigate = (date) => {
		const startDate = DateHelper.startOfMonthString(date);
		const endDate = DateHelper.endOfMonthString(date);

		getCalendar(startDate, endDate);
	};

	const onSelectSlot = (slot: any) => {
		history.push(
			`${url}/add?startDate=${encodeURIComponent(
				formatISO(slot.start)
			)}&endDate=${encodeURIComponent(formatISO(slot.end))}&isAllDay=${
				view === 'month' ? true : false
			}`
		);
	};

	return (
		<>
			<UnauthenticatedTemplate>
				<ConnectMicrosoft description="To access your calendar, connect your email." />
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				<Box padding={2}>
					<BigCalendar
						popup
						selectable
						localizer={localizer}
						events={data}
						startAccessor="start"
						endAccessor="end"
						style={{ height: `${height - 96}px` }}
						onNavigate={onNavigate}
						onSelectSlot={onSelectSlot}
						onView={setView}
					/>
				</Box>
			</AuthenticatedTemplate>

			<Switch>
				<PrivateRoute
					path={`${path}/add`}
					roles={[UserRole.ADMIN, UserRole.CHANDLER]}
				>
					<AddScreen modalClose={() => history.push(url)} />
				</PrivateRoute>
			</Switch>
		</>
	);
}

export default CalendarScreen;
