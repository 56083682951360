import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { ContainedButton } from '../../../components';
import { pattern } from '../../../constants';
import { isFieldError } from '../../../helpers';
import { UserInitValues } from '../models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		summary: {
			backgroundColor: theme.palette.grey[300],
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5,
		},
		details: {
			padding: theme.spacing(2),
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: '33.33%',
			flexShrink: 0,
		},
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		item: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		body: {
			minHeight: 200,
		},
		bodyField: {
			height: 150,
		},
		button: {
			'& > *': {
				marginRight: theme.spacing(1),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	name: Yup.string().required('This field is required'),
	prefix: Yup.string(),
	email: Yup.string()
		.required('This field is required')
		.email('This email address is invalid. Please enter a valid address.'),
	phone: Yup.string()
		.required('This field is required')
		.matches(
			pattern.phoneNumber,
			'This phone number is invalid. Please enter a valid phone number.'
		),
	username: Yup.string().required('This field is required'),
	role: Yup.string().required('This field is required'),
	password: Yup.string().required('This field is required'),
	confirmPassword: Yup.string()
		.required('This field is required')
		.when('password', {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('password')],
				'Both password need to be the same'
			),
		}),
});

interface Props {
	initialValues: UserInitValues;
	onSubmit: (values: any) => void;
	isLoading: boolean;
}

const Form = (props: Props) => {
	const { initialValues, onSubmit, isLoading } = props;
	const classes = useStyles();

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(
		false
	);

	const generateUserName = (name: string) => {
		name = name.trim();
		name = name.toLowerCase();

		// remove accents, swap ñ for n, etc
		const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
		const to = 'aaaaaaeeeeiiiioooouuuunc------';

		for (let i = 0, l = from.length; i < l; i++) {
			name = name.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		return name
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-') // collapse dashes
			.replace(/^-+/, '') // trim - from start of text
			.replace(/-+$/, '') // trim - from end of text
			.replace(/-/g, '-');
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				values,
				errors,
				touched,
			}) => (
				<form onSubmit={handleSubmit}>
					<Accordion expanded={true}>
						<AccordionSummary
							aria-controls="panel4bh-content"
							id="panel4bh-header"
							className={classes.summary}
						>
							<Typography variant="h6" className={classes.heading}>
								Personal Information
							</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.details}>
							<Grid container>
								<Grid item md={4} className={classes.item}>
									<TextField
										fullWidth
										label="Name"
										name="name"
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											handleChange(event);

											const username = generateUserName(event.target.value);
											setFieldValue('username', username);
										}}
										onBlur={handleBlur}
										error={isFieldError(touched.name, errors.name)}
										helperText={touched.name && errors.name}
										value={values.name}
									/>

									<TextField
										fullWidth
										label="Prefix"
										name="prefix"
										onChange={handleChange}
										onBlur={handleBlur}
										error={isFieldError(touched.prefix, errors.prefix)}
										helperText={touched.prefix && errors.prefix}
										value={values.prefix}
									/>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={true}>
						<AccordionSummary
							aria-controls="panel4bh-content"
							id="panel4bh-header"
							className={classes.summary}
						>
							<Typography variant="h6" className={classes.heading}>
								Contact Information
							</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.details}>
							<Grid container>
								<Grid item md={4} className={classes.item}>
									<TextField
										fullWidth
										label="Email"
										name="email"
										onChange={handleChange}
										onBlur={handleBlur}
										error={isFieldError(touched.email, errors.email)}
										helperText={touched.email && errors.email}
										value={values.email}
									/>

									<TextField
										fullWidth
										label="Phone"
										name="phone"
										onChange={handleChange}
										onBlur={handleBlur}
										error={isFieldError(touched.phone, errors.phone)}
										helperText={touched.phone && errors.phone}
										value={values.phone}
									/>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={true}>
						<AccordionSummary
							aria-controls="panel4bh-content"
							id="panel4bh-header"
							className={classes.summary}
						>
							<Typography variant="h6" className={classes.heading}>
								User Information
							</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.details}>
							<Grid container>
								<Grid item md={4} className={classes.item}>
									<TextField
										fullWidth
										label="Username"
										name="username"
										onChange={handleChange}
										onBlur={handleBlur}
										error={isFieldError(touched.username, errors.username)}
										helperText={touched.username && errors.username}
										value={values.username}
									/>

									<FormControl>
										<InputLabel id="role-label">Role</InputLabel>
										<Select
											labelId="role-label"
											name="role"
											value={values.role}
											onChange={handleChange}
											onBlur={handleBlur}
											style={{ width: 200 }}
										>
											<MenuItem value="admin">Admin</MenuItem>
											<MenuItem value="chandler">Chandler</MenuItem>
										</Select>
										{touched.role && errors.role && (
											<FormHelperText error>{errors.role}</FormHelperText>
										)}
									</FormControl>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={true}>
						<AccordionSummary
							aria-controls="panel4bh-content"
							id="panel4bh-header"
							className={classes.summary}
						>
							<Typography variant="h6" className={classes.heading}>
								Security
							</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.details}>
							<Grid container>
								<Grid item md={4} className={classes.item}>
									<FormControl fullWidth>
										<InputLabel id="password-label">Password</InputLabel>
										<Input
											id="password-label"
											name="password"
											placeholder="Password"
											onChange={handleChange}
											onBlur={handleBlur}
											error={isFieldError(touched.password, errors.password)}
											type={showPassword ? 'text' : 'password'}
											value={values.password}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														onClick={() => setShowPassword((prev) => !prev)}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
											style={{ width: 300 }}
										/>
										{touched.password && errors.password && (
											<FormHelperText error>{errors.password}</FormHelperText>
										)}
									</FormControl>

									<FormControl fullWidth>
										<InputLabel id="password-confirm-label">
											Confirm password
										</InputLabel>
										<Input
											id="password-confirm-label"
											name="confirmPassword"
											placeholder="Confirm password"
											onChange={handleChange}
											onBlur={handleBlur}
											error={isFieldError(
												touched.confirmPassword,
												errors.confirmPassword
											)}
											type={showPasswordConfirm ? 'text' : 'password'}
											value={values.confirmPassword}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPasswordConfirm((prev) => !prev)
														}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
											style={{ width: 300 }}
										/>
										{touched.confirmPassword && errors.confirmPassword && (
											<FormHelperText error>
												{errors.confirmPassword}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>

					<ContainedButton type="submit" isLoading={isLoading}>
						Add user
					</ContainedButton>
				</form>
			)}
		</Formik>
	);
};

export default Form;
