import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const GreenButton = withStyles({
	root: {
		color: '#ffffff',
		backgroundColor: '#4caf50',
		'&:hover': {
			backgroundColor: '#4caf50',
		},
	},
})(Button);

export default GreenButton;
