import {
	Box,
	IconButton,
	LinearProgress,
	Link,
	Tooltip,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
	Delete as DeleteIcon,
	Visibility as ShowIcon,
	Restore as RestoreIcon,
} from '@material-ui/icons';
import React, { Fragment } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { SectionTitle } from '../../../../components';
import { Attachment } from '../../../../models';
import { useOvermind } from '../../../../overmind';
import { UserRole } from '../../../../constants';
import { useCurrentUser } from '../../../../hooks';
import { DateHelper } from '../../../../helpers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 2px 0px',
			transition: 'background 0.1s ease 0s',
			userSelect: 'none',
			margin: theme.spacing(0, 0, 1),
		},
		button: {
			boxShadow: 'rgba(9, 30, 66, 0.35) 0px 1px 2px 0px',
			transition: 'background 0.1s ease 0s',
			userSelect: 'none',
			marginLeft: 2,
			marginRight: 2,
			color: theme.palette.grey[500],
		},
		fileName: {
			fontSize: '12px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			padding: '0px 12px',
			color: 'black',
			textAlign: 'left',
		},
		fileType: {
			fontSize: '13px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			padding: '0px 12px',
			color: '#666666',
			textTransform: 'uppercase',
			textAlign: 'left',
		},
	})
);

function Attachments() {
	const classes = useStyles();

	const {
		state: {
			ticket: { currentTicket, isDeleting },
		},
		actions,
	} = useOvermind();

	const { currentUser } = useCurrentUser();

	const getExtensionFile = (fileName: string) => {
		return fileName.substr(fileName.lastIndexOf('.') + 1);
	};

	const getTimeCreated = (timeCreated: string) => {
		const dateTimeCreated = new Date(timeCreated);
		return DateHelper.formatDate(dateTimeCreated, 'yyyyMMdd, HH:mm:ss');
	};

	const handleDelete = async (attachmentId: number, fileName: string) => {
		await actions.ticket.removeDocument({ attachmentId, fileName });
	};

	const handleDeleteTrash = async (attachment: Attachment) => {
		if (attachment.isTrash === true) {
			handleDelete(attachment.id, attachment.filename);
		} else {
			await actions.ticket.setTrashDocument(attachment);
		}
	};

	const handlePutBackFromTrash = async (attachment: Attachment) => {
		await actions.ticket.putBackDocument(attachment);
	};

	const handleShow = async (filePath: string) => {
		window.open(
			filePath,
			'_blank' // <- This is what makes it open in a new window.
		);
	};

	const renderAttachmentCard = (attachment: Attachment) => (
		<Card key={attachment.id} elevation={0} className={classes.root}>
			<Box display="block" textAlign="center">
				<Box padding={1} display="flex" alignItems="center" flexWrap="nowrap">
					<Box width={32}>
						<Tooltip title={attachment.filename} arrow>
							<Link
								href={`${process.env.REACT_APP_AWS_S3_URL}/${attachment.filename}`}
								target="_blank"
							>
								<FileIcon
									extension={getExtensionFile(attachment.filename)}
									{...defaultStyles[getExtensionFile(attachment.filename)]}
								/>
							</Link>
						</Tooltip>
					</Box>
					<Box flexWrap="nowrap" flexGrow={1} alignItems="left" display="grid">
						<span className={classes.fileName}>{attachment.filename}</span>
						<span className={classes.fileType}>
							{getExtensionFile(attachment.filename) +
								' - ' +
								getTimeCreated(attachment.createdAt)}
						</span>
					</Box>

					{currentUser?.role === UserRole.ADMIN && attachment.isTrash === true && (
						<IconButton
							className={classes.button}
							size="small"
							aria-label="restore"
							title={`Resotre ${attachment.filename}`}
							onClick={() => handlePutBackFromTrash(attachment)}
						>
							<RestoreIcon />
						</IconButton>
					)}
					<IconButton
						className={classes.button}
						size="small"
						aria-label="show"
						title={`Preview ${attachment.filename}`}
						onClick={() =>
							handleShow(
								`${process.env.REACT_APP_AWS_S3_URL}/${attachment.filename}`
							)
						}
					>
						<ShowIcon />
					</IconButton>
					<IconButton
						className={classes.button}
						size="small"
						aria-label="delete"
						title={`Delete ${attachment.filename}`}
						onClick={() => handleDeleteTrash(attachment)}
					>
						<DeleteIcon />
					</IconButton>
				</Box>
			</Box>
		</Card>
	);

	return (
		<Fragment>
			<SectionTitle>Attachments</SectionTitle>
			{isDeleting && <LinearProgress color="secondary" />}

			{currentTicket?.attachments
				.filter((attachment) => attachment.isTrash !== true)
				.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
				.map((attachment: Attachment) => renderAttachmentCard(attachment))}
			{currentUser?.role === UserRole.ADMIN && (
				<div>
					<SectionTitle>Trash Attachments</SectionTitle>
					{isDeleting && <LinearProgress color="secondary" />}

					{currentTicket?.attachments
						.filter((attachment) => attachment.isTrash !== false)
						.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
						.map((attachment: Attachment) => renderAttachmentCard(attachment))}
				</div>
			)}
		</Fragment>
	);
}

export default Attachments;
