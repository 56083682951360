import { json } from 'overmind';
import { useOvermind } from '../overmind';

function useCurrentUser() {
	const {
		state: {
			authentication: { user },
		},
	} = useOvermind();

	return { currentUser: json(user) };
}

export default useCurrentUser;
