import { TicketStatus } from '../../constants';
import { QuotationPayload } from '../../features/projects/models';
import { Quotation, Ticket, Attachment } from '../../models';

export const getById = async (
	{ effects, actions, state },
	ticketid: string
) => {
	state.ticket.isLoading = true;

	try {
		const response: Ticket = await effects.ticket.api.getById(ticketid);

		state.ticket = {
			...state.ticket,
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};
		state.comment = { ...state.comment, data: response.comments };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isLoading = false;
};

export const update = async (
	{ effects, actions, state },
	payload: Partial<Ticket>
) => {
	state.ticket.isLoading = true;

	try {
		const response = await effects.ticket.api.update(
			state.ticket.currentTicket.id,
			payload
		);

		state.ticket = {
			...state.ticket,
			data: state.ticket.data.map((ticket: Ticket) => {
				return ticket.id === response.id ? { ...ticket, ...response } : ticket;
			}),
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Updated succesfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isLoading = false;
};

export const getQuotationById = async (
	{ effects, actions, state },
	quotationId: string
) => {
	state.ticket.isLoading = true;

	try {
		const quotation = await effects.ticket.api.getQuotationById(quotationId);

		state.ticket.quotation = quotation;
	} catch (error) {
		actions.setAlert({ type: 'error', message: error });
	}
	state.ticket.isLoading = false;
};

export const createQuotation = async (
	{ effects, actions, state },
	payload: QuotationPayload
) => {
	state.ticket.isLoadingQuotation = true;

	try {
		state.ticket.isQuotationCreated = true;
		const response = await effects.ticket.api.createQuotation(
			state.ticket.currentTicket.id,
			payload
		);

		state.ticket = {
			...state.ticket,
			data: state.ticket.data.map((ticket: Ticket) => {
				return ticket.id === response.id ? { ...ticket, ...response } : ticket;
			}),
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Quotation was created successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({
			type: 'error',
			message: `(PRO - ${state.ticket.currentTicket.id}) Quotation failed to create.`,
		});
	}

	state.ticket.isLoadingQuotation = false;
};

export const resetCreateQuotation = async ({ state }) => {
	state.ticket.isQuotationCreated = false;
};

export const updateQuotation = async (
	{ effects, actions, state },
	payload: Partial<Quotation>
) => {
	state.ticket.isLoadingQuotation = true;

	try {
		const response = await effects.ticket.api.updateQuotation(
			state.ticket.currentTicket.id,
			state.ticket.currentTicket.quotation.id,
			payload
		);

		state.ticket = {
			...state.ticket,
			data: state.ticket.data.map((ticket: Ticket) => {
				return ticket.id === response.id ? { ...ticket, ...response } : ticket;
			}),
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Quotation updated successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isLoadingQuotation = false;
};

export const createInvoice = async ({ effects, actions, state }) => {
	state.ticket.isLoadingInvoice = true;

	try {
		const response = await effects.ticket.api.createInvoice(
			state.ticket.currentTicket.id
		);

		state.ticket = {
			...state.ticket,
			data: state.ticket.data.map((ticket: Ticket) => {
				return ticket.id === response.id ? { ...ticket, ...response } : ticket;
			}),
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};

		state.ticket.isInvoiceCreated = true;

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Invoice was created successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isLoadingInvoice = false;
};

export const resetCreateInvoice = async ({ state }) => {
	state.ticket.isInvoiceCreated = false;
};

export const createPO = async (
	{ effects, actions, state },
	payload: {
		companyName: string;
		products: any[];
		totalPrice: number;
	}
) => {
	state.ticket.isLoadingPO = true;

	try {
		const response = await effects.ticket.api.createPO(
			state.ticket.currentTicket.id,
			payload
		);

		state.ticket = {
			...state.ticket,
			data: state.ticket.data.map((ticket: Ticket) => {
				return ticket.id === response.id ? { ...ticket, ...response } : ticket;
			}),
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};

		state.ticket.isPOCreated = true;

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) PO was created successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isLoadingPO = false;
};

export const resetCreatePO = async ({ state }) => {
	state.ticket.isPOCreated = false;
};

export const createDeliveryNote = async ({ effects, actions, state }) => {
	state.ticket.isLoadingDeliveryNote = true;

	try {
		const response = await effects.ticket.api.createDeliveryNote(
			state.ticket.currentTicket.id
		);

		state.ticket = {
			...state.ticket,
			data: state.ticket.data.map((ticket: Ticket) => {
				return ticket.id === response.id ? { ...ticket, ...response } : ticket;
			}),
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Delivery note was created successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isLoadingDeliveryNote = false;
};

export const uploadDocuments = async (
	{ effects, actions, state },
	payload: FormData
) => {
	state.ticket.isUploading = true;

	try {
		const response = await effects.ticket.api.uploadDocuments(
			state.ticket.currentTicket.id,
			payload
		);

		const status =
			state.ticket.currentTicket.status === TicketStatus.SHOPPING
				? TicketStatus.UPLOAD
				: state.ticket.currentTicket.status;
		const attachments = [
			...state.ticket.currentTicket.attachments,
			...response,
		];
		state.ticket = {
			...state.ticket,
			currentTicket: { ...state.ticket.currentTicket, attachments, status },
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Documents uploaded successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isUploading = false;
};

export const sendEmail = async (
	{ effects, actions, state },
	payload: FormData
) => {
	state.ticket.isLoading = true;

	try {
		const response = await effects.ticket.api.sendEmail(
			state.ticket.currentTicket.id,
			payload
		);

		state.ticket = {
			...state.ticket,
			data: state.ticket.data.map((ticket: Ticket) => {
				return ticket.id === response.id ? { ...ticket, ...response } : ticket;
			}),
			currentTicket: { ...state.ticket.currentTicket, ...response },
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Email successfully sent.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isLoading = false;
};

export const remove = async ({ effects, actions, state }, ticketId: string) => {
	state.ticket.isDeleting = true;

	try {
		await effects.ticket.api.remove(ticketId);
		await actions.project.get();

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) Deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isDeleting = false;
};

export const putBackDocument = async (
	{ effects, actions, state },
	payload: Partial<Attachment>
) => {
	state.ticket.isDeleting = true;
	payload.isTrash = false;
	try {
		await effects.ticket.api.updateDocuments(
			state.ticket.currentTicket.id,
			payload.id,
			payload
		);

		state.ticket.currentTicket = {
			...state.ticket.currentTicket,
			...state.ticket.currentTicket.attachments,
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) ${payload.filename} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isDeleting = false;
};

export const setTrashDocument = async (
	{ effects, actions, state },
	payload: Partial<Attachment>
) => {
	state.ticket.isDeleting = true;
	payload.isTrash = true;
	try {
		await effects.ticket.api.updateDocuments(
			state.ticket.currentTicket.id,
			payload.id,
			payload
		);

		state.ticket.currentTicket = {
			...state.ticket.currentTicket,
			...state.ticket.currentTicket.attachments,
		};

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) ${payload.filename} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isDeleting = false;
};

export const removeDocument = async (
	{ effects, actions, state },
	data: {
		attachmentId: number;
		fileName: string;
	}
) => {
	state.ticket.isDeleting = true;

	try {
		await effects.ticket.api.removeDocument(
			state.ticket.currentTicket.id,
			data.attachmentId,
			data.fileName
		);

		const attachments = state.ticket.currentTicket.attachments.filter(
			(attachment) => attachment.id !== data.attachmentId
		);
		state.ticket.currentTicket = { ...state.ticket.currentTicket, attachments };

		actions.alert.setAlert({
			type: 'success',
			message: `(PRO - ${state.ticket.currentTicket.id}) ${data.fileName} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.ticket.isDeleting = false;
};
