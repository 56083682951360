/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Avatar,
	Button,
	ClickAwayListener,
	createStyles,
	InputBase,
	makeStyles,
	Popper,
	Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { SectionTitle } from '../../../../components';
import { useCurrentProject } from '../../../../hooks';
import { User } from '../../../../models';
import { useOvermind } from '../../../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		option: {
			fontSize: 15,
			'& > span': {
				marginRight: 10,
				fontSize: 18,
			},
		},
		avatar: {
			width: 24,
			height: 24,
			marginRight: theme.spacing(1),
			background: '#42526E',
			textTransform: 'uppercase',
			fontSize: '11px !important',
			fontWeight: 'bold',
		},
		close: {
			opacity: 0.6,
			width: 18,
			height: 18,
		},
		inputBase: {
			minWidth: 250,
			padding: 0,
			'& input': {
				display: 'none',
			},
		},
	})
);

interface Props {
	user: User;
}

function nameToInitials(fullName) {
	const namesArray = fullName.trim().split(' ');
	if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
	else
		return `${namesArray[0].charAt(0)}${namesArray[
			namesArray.length - 1
		].charAt(0)}`;
}

function Assignee(props: Props) {
	const classes = useStyles();
	const { user } = props;

	const { currentProject } = useCurrentProject();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [value, setValue] = useState<User>(user);
	const [inputValue, setInputValue] = useState<User>();

	const { actions } = useOvermind();

	useEffect(() => {
		if (!inputValue) return;

		setValue(inputValue);

		if (anchorEl) {
			anchorEl.focus();
		}

		setAnchorEl(null);

		actions.ticket.update({
			user: inputValue,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setInputValue(value);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		if (anchorEl) {
			anchorEl.focus();
		}
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'github-label' : undefined;

	return (
		<>
			<SectionTitle>Assignee</SectionTitle>

			<ClickAwayListener onClickAway={handleClose}>
				<Button
					disableElevation
					variant={!user ? 'text' : 'contained'}
					aria-describedby={id}
					onClick={handleClick}
					startIcon={
						user ? (
							<Avatar src={user.avatarUrl} className={classes.avatar}>
								{nameToInitials(user.name)}
							</Avatar>
						) : null
					}
					style={!user ? { paddingLeft: 0, paddingRight: 0 } : {}}
				>
					{user ? user.name : 'Unassign'}
				</Button>
			</ClickAwayListener>

			<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
				<Autocomplete
					open
					filterSelectedOptions
					onClose={handleClose}
					classes={{
						option: classes.option,
					}}
					value={inputValue}
					onChange={(event, newValue) => {
						if (newValue) {
							setInputValue(newValue);
						}
					}}
					options={currentProject?.users || []}
					getOptionLabel={(option) => option.name}
					getOptionSelected={(option) => option.id === inputValue?.id}
					renderOption={(option) => (
						<>
							<Avatar src={option.avatarUrl} className={classes.avatar}>
								{nameToInitials(option.name)}
							</Avatar>
							{option.name}
						</>
					)}
					renderInput={(params) => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							className={classes.inputBase}
						/>
					)}
				/>
			</Popper>
		</>
	);
}

export default Assignee;
