/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import React from 'react';
import { useOvermind } from '../../../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dropzone: {
			minHeight: 200,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		dropzoneParagraph: {
			fontSize: 14,
			margin: theme.spacing(1, 0),
		},
		previewChip: {
			maxWidth: 210,
		},
	})
);

interface Props {
	open: boolean;
	onClose: () => void;
}

function UploadDocuments(props: Props) {
	const { open, onClose } = props;
	const classes = useStyles();
	const {
		state: {
			ticket: { currentTicket },
		},
		actions,
	} = useOvermind();

	const handleSubmit = async (files: File[]) => {
		const formData = new FormData();
		formData.append('currentStatus', currentTicket?.status as string);

		for (let x = 0; x < files.length; x++) {
			formData.append('filename', files[x]);
		}

		onClose();

		await actions.ticket.uploadDocuments(formData);
	};

	return (
		<DropzoneDialog
			open={open}
			onClose={onClose}
			dialogTitle="Upload documents"
			submitButtonText="Upload"
			showPreviews={true}
			showPreviewsInDropzone={false}
			useChipsForPreview
			previewGridProps={{
				container: { spacing: 1, direction: 'row' },
			}}
			previewChipProps={{
				classes: { root: classes.previewChip },
			}}
			previewText="Selected files"
			dropzoneText="Drop files to attach or click to upload"
			dropzoneClass={classes.dropzone}
			dropzoneParagraphClass={classes.dropzoneParagraph}
			showAlerts={false}
			onSave={handleSubmit}
			filesLimit={10}
		/>
	);
}

export default UploadDocuments;
