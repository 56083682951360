/* eslint-disable react/display-name */
import { Box, Button, InputAdornment } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add as AddIcon, Backup as BackupIcon } from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useRouteMatch } from 'react-router-dom';
import { Page, PageTitle, TextField } from '../../components';
import { Client, Contact, ClientVessel } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { tableStyles } from '../../utils/styles';
import { LinearIndeterminate } from '../mails/components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			padding: theme.spacing(2, 0, 0),
		},
		action: {
			'& > *': {
				marginLeft: theme.spacing(1),
			},
		},
		dropzone: {
			marginBottom: theme.spacing(2),
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

const columns = [
	{
		name: 'Name',
		selector: 'name',
		sortable: true,
	},
	{
		name: 'Vessel',
		cell: (row: Client) =>
			row.vessels
				.map(
					(vessel: ClientVessel) => `
						${vessel.name} `
				)
				.join(', '),
	},
	{
		name: 'Contact Person',
		cell: (row: Client) =>
			row.contacts
				.map(
					(contact: Contact) => `
					${contact.name}`
				)
				.join(', '),
	},
	{
		name: 'Invoice Address',
		selector: 'invoiceAddress',
	},
];

function ListScreen() {
	const classes = useStyles();
	const { url } = useRouteMatch();

	const {
		state: {
			client: { data, isLoading },
		},
		actions,
	} = useOvermind();

	const [open, setOpen] = useState(false);
	const [filterText, setFilterText] = useState('');
	const filteredItems = data.filter(
		(client: Client) =>
			client.name &&
			client.name.toLowerCase().includes(filterText.toLowerCase())
	);

	useEffect(() => {
		actions.client.fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleImport = async (files: File[]) => {
		const formData = new FormData();
		formData.append('filename', files[0]);

		await actions.client.importClients(formData);
		actions.client.fetch();

		setOpen(false);
	};

	const handleRowClicked = (row: any) => {
		history.push(`${url}/${row.id}/basic`);
	};

	return (
		<>
			<Page title="Clients">
				<PageTitle>Clients</PageTitle>

				<Box paddingX={2} paddingY={3}>
					<Box
						display="flex"
						justifyContent="flex-end"
						className={classes.action}
					>
						<TextField
							variant="outlined"
							placeholder="Search"
							type="search"
							value={filterText}
							onChange={(e) => setFilterText(e.target.value)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Icon>search</Icon>
									</InputAdornment>
								),
							}}
						/>

						<Button
							variant="contained"
							color="inherit"
							startIcon={<BackupIcon />}
							onClick={() => setOpen(true)}
						>
							Import
						</Button>

						<Button
							variant="contained"
							color="inherit"
							startIcon={<AddIcon />}
							onClick={() => history.push(`${url}/add`)}
						>
							New
						</Button>
					</Box>

					<DataTable
						customStyles={tableStyles}
						columns={columns}
						data={filteredItems}
						pagination
						highlightOnHover
						pointerOnHover
						noHeader
						progressPending={isLoading}
						progressComponent={<LinearIndeterminate />}
						persistTableHead
						className={classes.table}
						onRowClicked={handleRowClicked}
					/>
				</Box>
			</Page>

			<DropzoneDialog
				filesLimit={1}
				acceptedFiles={['.xlsx', '.xls', '.csv']}
				cancelButtonText={'Cancel'}
				submitButtonText={'Import'}
				maxFileSize={5000000}
				open={open}
				onClose={() => setOpen(false)}
				onSave={handleImport}
				showPreviews={true}
				showFileNamesInPreview={true}
				showPreviewsInDropzone={false}
				useChipsForPreview
				previewGridProps={{
					container: { spacing: 1, direction: 'row' },
				}}
				previewChipProps={{ classes: { root: classes.previewChip } }}
				previewText="Selected file"
				dropzoneClass={classes.dropzone}
				showAlerts={false}
			/>
		</>
	);
}

export default ListScreen;
