import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ContainedButton } from '../../components';
import { TicketStatus } from '../../constants';
import { isFieldError } from '../../helpers';
import { useOvermind } from '../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			padding: theme.spacing(3),
		},
		title: {
			marginBottom: theme.spacing(3),
		},
		form: {
			'& > *': {
				width: '100%',
				marginBottom: theme.spacing(2),
			},
		},
		formControl: {
			margin: theme.spacing(1),
		},
	})
);

const initialValues = {
	reason: '',
	note: '',
};

const validationSchema = yup.object().shape({
	reason: yup.string().required('general.validation.required'),
	note: yup.string().required('general.validation.required'),
});

interface Props {
	modalClose: () => void;
}

const NoDealScreen = (props: Props) => {
	const { modalClose } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const { actions } = useOvermind();

	const onSubmit = async (values: any) => {
		await actions.ticket.update({
			status: TicketStatus.DONE,
			noDealReason: values.reason,
			noDealNote: values.note,
		});

		modalClose();
	};

	return (
		<Dialog open onClose={modalClose}>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => (
					<form noValidate onSubmit={handleSubmit}>
						<DialogTitle disableTypography>No Deal</DialogTitle>
						<DialogContent>
							<Grid container direction="column">
								<Grid item>
									<FormControl className={classes.formControl}>
										<InputLabel id="reason-label">Reason of no deal</InputLabel>
										<Select
											labelId="reason-label"
											name="reason"
											style={{ width: 250 }}
											value={values.reason}
											onChange={handleChange}
											onBlur={handleBlur}
										>
											<MenuItem value="Quotation was to high">
												Quotation was to high
											</MenuItem>
											<MenuItem value="Client accepted another deal">
												Client accepted another deal
											</MenuItem>
											<MenuItem value="Other">Other</MenuItem>
										</Select>

										{errors.reason && touched.reason && (
											<FormHelperText error>{t(errors.reason)}</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid item>
									<FormControl className={classes.formControl}>
										<TextField
											label="Note"
											name="note"
											multiline
											rowsMax={4}
											value={values.note}
											onChange={handleChange}
											onBlur={handleBlur}
											error={isFieldError(touched.note, errors.note)}
											helperText={touched.note && errors.note && t(errors.note)}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<ContainedButton type="submit">Done</ContainedButton>
						</DialogActions>
					</form>
				)}
			</Formik>
		</Dialog>
	);
};

export default NoDealScreen;
