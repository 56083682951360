import {
	Avatar,
	createStyles,
	Grid,
	makeStyles,
	Theme,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import { Ticket } from '../../../../../../models';
import history from '../../../../../../utils/history';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 2px 0px',
			transition: 'background 0.1s ease 0s',
			cursor: 'pointer',
			userSelect: 'none',
			margin: theme.spacing(0, 0, 1),
		},
		content: {
			padding: theme.spacing(1),
		},
		titleProject: {
			color: 'rgba(0, 0, 0, 0.54)',
			fontSize: '10px',
			fontWeight: 'bold',
		},
		titleTicket: {
			color: 'textPrimary',
			fontSize: '11px',
			fontFamily: 'Roboto',
			marginTop: '8px',
		},
		vesselName: {
			color: 'textPrimary',
			fontSize: '12px',
			padding: '3px',
			borderRadius: '3px',
			fontWeight: 'bold',
			display: 'inline-table',
			marginTop: '8px',
		},
		avatarSmall: {
			height: '20px',
			width: '20px',
			float: 'right',
			fontSize: '10px',
			textAlign: 'center',
			fontWeight: 'bold',
			background: '#42526E',
			textTransform: 'uppercase',
		},
	})
);

interface Props {
	ticket: Ticket;
}

function getDifferenceInHours(date1, date2) {
	const diffInMs = Math.abs(date2 - date1);
	return diffInMs / (1000 * 60 * 60);
}

function nameToInitials(fullName) {
	const namesArray = fullName.trim().split(' ');
	if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
	else
		return `${namesArray[0].charAt(0)}${namesArray[
			namesArray.length - 1
		].charAt(0)}`;
}

export default function Item(props: Props) {
	const classes = useStyles();
	const { ticket } = props;

	const dateNow = new Date();
	const dateTicket = new Date(ticket.updatedAt);
	const timeTicket = getDifferenceInHours(dateNow, dateTicket);

	const handleClick = () => {
		history.push(`projects/ticket/${ticket.id}`);
	};

	return (
		<Card elevation={0} className={classes.root}>
			<CardActionArea onClick={handleClick}>
				<CardContent className={classes.content}>
					<Grid>
						<Grid>
							<div className={classes.titleProject}>PRO - {ticket.id}</div>

							<Grid item style={{ display: 'list-item' }}>
								{ticket.status !== 'done' &&
									ticket.status !== 'invalid' &&
									ticket.status !== 'waiting' && (
										<div style={{ display: 'inline' }}>
											<FiberManualRecordIcon
												style={{
													color: timeTicket >= 4 ? 'yellow' : 'white',
													fontSize: 7,
												}}
											/>
											<FiberManualRecordIcon
												style={{
													color: timeTicket >= 6 ? 'orange' : 'white',
													fontSize: 7,
												}}
											/>
											<FiberManualRecordIcon
												style={{
													color: timeTicket >= 8 ? 'red' : 'white',
													fontSize: 7,
												}}
											/>
										</div>
									)}
								{ticket.user && (
									<Avatar
										className={classes.avatarSmall}
										variant="circular"
										key={ticket.user?.id}
										src={ticket.user?.avatarUrl}
									>
										{nameToInitials(ticket.user?.name)}
									</Avatar>
								)}
							</Grid>
							<div className={classes.titleTicket}>{ticket.title}</div>
							{ticket.vessel && (
								<span
									className={classes.vesselName}
									style={{
										background: ticket.color,
									}}
								>
									{ticket.vessel.name}
								</span>
							)}
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
