import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = responsiveFontSizes(
	createMuiTheme({
		palette: {
			primary: {
				main: '#15559a',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#f3b544',
				contrastText: '#ffffff',
			},
			text: {
				primary: '#42526E',
			},
			background: {
				default: '#ffffff',
			},
		},
		typography: {
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'Segoe UI',
				'Roboto',
				'Noto Sans',
				'Ubuntu',
				'Droid Sans',
				'Helvetica Neue',
				'sans-serif',
			].join(','),
			h5: {
				fontWeight: 600,
				fontSize: 24,
			},
			h6: {
				fontWeight: 700,
				fontSize: 16,
			},
		},
		props: {
			MuiTab: {
				disableRipple: true,
			},
		},
		mixins: {
			toolbar: {
				minHeight: 64,
			},
		},
	})
);

theme = {
	...theme,
	overrides: {
		MuiAppBar: {
			root: {
				boxShadow:
					'0 0.46875rem 2.1875rem rgba(59,62,102,.03), 0 0.9375rem 1.40625rem rgba(59,62,102,.03), 0 0.25rem 0.53125rem rgba(59,62,102,.05), 0 0.125rem 0.1875rem rgba(59,62,102,.03)',
			},
			colorPrimary: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.common.white,
			},
		},
		MuiDrawer: {
			paper: {
				backgroundColor: '#FAFBFC',
			},
			paperAnchorDockedLeft: {
				borderRightColor: theme.palette.grey[200],
			},
		},
		MuiButton: {
			root: {
				padding: '4px 10px',
				borderRadius: 3,
				fontWeight: 'normal',
			},
			label: {
				textTransform: 'none',
			},
			contained: {
				boxShadow: 'none',
				'&:hover': {
					boxShadow: 'none',
				},

				'&:active': {
					boxShadow: 'none',
				},
			},
		},
		MuiButtonGroup: {
			groupedContainedPrimary: {
				borderColor: theme.palette.background.default,
			},
		},
		MuiTabs: {
			root: {
				borderBottomWidth: 3,
				borderBottomStyle: 'solid',
				borderBottomColor: theme.palette.grey[200],
			},
			indicator: {
				height: 3,
				borderTopLeftRadius: 3,
				borderTopRightRadius: 3,
				backgroundColor: theme.palette.primary.main,
			},
		},
		MuiTab: {
			root: {
				textTransform: 'none',
				margin: '0 24px',
				minWidth: 0,
				padding: 0,
				[theme.breakpoints.up('md')]: {
					padding: 0,
					minWidth: 0,
				},
			},
		},
		MuiIconButton: {
			root: {
				padding: theme.spacing(1),
			},
		},
		MuiTooltip: {
			tooltip: {
				borderRadius: 4,
			},
		},
		MuiList: {
			root: {
				zIndex: 1,
			},
		},
		MuiListItemIcon: {
			root: {
				color: 'inherit',
				marginRight: 0,
				'& svg': {
					fontSize: 20,
				},
			},
		},
		MuiAvatar: {
			root: {
				width: 32,
				height: 32,
			},
		},
		MuiDialogTitle: {
			root: {
				fontSize: '20px',
				fontWeight: 700,
			},
		},
		MuiTable: {
			root: {
				tableLayout: 'fixed',
			},
		},
		MuiTableCell: {
			head: { fontWeight: 700, padding: '12px 0px' },
			root: {
				fontSize: '14px',
				whiteSpace: 'pre-wrap',
				padding: '8px 0px',
			},
		},
		MuiAccordion: {
			root: {
				backgroundColor: theme.palette.background.default,

				'&:before': {
					height: 0,
				},
			},
		},
		MuiAccordionSummary: {
			content: {
				margin: theme.spacing(1, 0),
			},
		},
		MuiMenuItem: {
			root: {
				fontSize: 14,
			},
		},
	},
};

export default theme;
