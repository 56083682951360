import { Configuration } from '../../models';

export const fetch = async ({ effects, actions, state }) => {
	state.configuration.isLoading = true;

	try {
		const data = await effects.configuration.api.fetch();

		state.configuration = { data };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.configuration.isLoading = false;
};

export const update = async (
	{ effects, actions, state },
	payload: Configuration[]
) => {
	state.configuration.isLoading = true;

	try {
		const data = await effects.configuration.api.update(payload);

		state.configuration = { data };

		actions.alert.setAlert({
			type: 'success',
			message: 'Configuration successfully updated.',
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.configuration.isLoading = false;
};
