import instance from '../../api/axios';

export const api = {
	async fetch() {
		try {
			const response = await instance.get(`/comments`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async create(payload) {
		try {
			const response = await instance.post(`/comments`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
