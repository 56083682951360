import React from 'react';
import NumberFormat from 'react-number-format';

interface Props {
	inputRef: (instance: NumberFormat | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
	decimalScale?: number;
}

function NumberField(props: Props) {
	const { inputRef, onChange, decimalScale, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			isNumericString
			decimalSeparator=","
			thousandSeparator="."
			decimalScale={decimalScale}
			allowNegative={false}
		/>
	);
}

export default NumberField;
