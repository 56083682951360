import { Client } from '@microsoft/microsoft-graph-client';
import { addDays, parseISO } from 'date-fns';
import { AddPayload } from '../features/calendar/models';
require('isomorphic-fetch');

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function getAuthenticatedClient(accessToken: string) {
	// Initialize Graph client
	const client = Client.init({
		// Use the provided access token to authenticate
		// requests
		authProvider: (done) => {
			done(null, accessToken);
		},
	});

	return client;
}

class MsGraph {
	constructor(public accessToken: string) {}

	async getUserDetails() {
		const client = getAuthenticatedClient(this.accessToken);

		const user = await client
			.api('/me')
			.select('displayName,mail,mailboxSettings,userPrincipalName')
			.get();

		return user;
	}

	async getEmails(limit: number) {
		const client = getAuthenticatedClient(this.accessToken);

		const emails = await client
			.api("/me/mailFolders('Inbox')/messages")
			.top(limit)
			.get();

		return emails;
	}

	async getEmailById(id: string) {
		const client = getAuthenticatedClient(this.accessToken);

		const email = await client.api(`/me/messages/${id}`).get();

		return email;
	}

	async getAttachmentsByEmailId(emailId: string) {
		const client = getAuthenticatedClient(this.accessToken);

		const attachments = await client
			.api(`/me/messages/${emailId}/attachments`)
			.get();

		return attachments;
	}

	async updateEmail(emailId: string, payload: any) {
		const client = getAuthenticatedClient(this.accessToken);

		const email = await client.api(`/me/messages/${emailId}`).patch(payload);

		return email;
	}

	async deleteEmail(emailId: string) {
		const client = getAuthenticatedClient(this.accessToken);

		const email = await client.api(`/me/messages/${emailId}/move`).post({
			destinationId: 'deleteditems',
		});

		return email;
	}

	async sendEmail(payload: any) {
		const client = getAuthenticatedClient(this.accessToken);

		const email = await client.api(`/me/sendMail`).post(payload);

		return email;
	}

	async getCalendar(start: string, end: string) {
		const client = getAuthenticatedClient(this.accessToken);

		const events = await client
			.api('/me/calendarview')
			// Add Prefer header to get back times in user's timezone
			.header('Prefer', `outlook.timezone="${timeZone}"`)
			.query({ startDateTime: start, endDateTime: end })
			.get();

		return events;
	}

	async createEvent(payload: AddPayload) {
		const client = getAuthenticatedClient(this.accessToken);

		// Build a Graph event
		const newEvent = {
			subject: payload.title,
			start: {
				dateTime: parseISO(payload.startDate).toLocaleString('sv'),
				timeZone: timeZone,
			},
			end: {
				dateTime: payload.isAllDay
					? addDays(parseISO(payload.endDate), 1).toLocaleString('sv')
					: parseISO(payload.endDate).toLocaleString('sv'),
				timeZone: timeZone,
			},
			body: {
				contentType: 'text',
				content: payload.body,
			},
			attendees: [],
		};

		payload.attendees.forEach((attendee: any) => {
			if (typeof attendee === 'string') {
				(newEvent.attendees as any).push({
					type: 'required',
					emailAddress: {
						address: attendee,
					},
				});
			} else {
				(newEvent.attendees as any).push({
					type: 'required',
					emailAddress: {
						address: attendee.email,
					},
				});
			}
		});

		return await client
			.api('/me/events')
			.header('Prefer', `outlook.timezone="${timeZone}"`)
			.post(newEvent);
	}
}

export default MsGraph;
