import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { DateHelper } from '../../../../../../../helpers';
import { Comment as CommentModel } from '../../../../../../../models';

interface Props {
	comment: CommentModel;
}

function Comment(props: Props) {
	const { comment } = props;

	const processMention = (body) => {
		return body
			.replace(
				/(?!\b)@(\[(.*?)\])/gi,
				`<span
				style="color: #02567a">
				@$1
			</span>`
			)
			.replace(/[\[\]']+/g, '') // eslint-disable-line no-useless-escape
			.replace(/(\(.*?\)) */g, '');
	};

	return (
		<Box mt={2}>
			<Grid container spacing={2}>
				<Grid item>
					{comment.user.avatarUrl ? (
						<Avatar src={comment.user.avatarUrl} />
					) : (
						<Avatar>{comment.user.name.charAt(0)}</Avatar>
					)}
				</Grid>
				<Grid item sm={11}>
					<Typography variant="body1">{comment.user.name}</Typography>

					<Fragment>
						<Typography variant="body2">
							<span
								dangerouslySetInnerHTML={{
									__html: processMention(comment.body),
								}}
							/>
						</Typography>
						<Typography variant="caption">
							{DateHelper.distanceToNowFormat(comment.createdAt)}
						</Typography>
					</Fragment>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Comment;
