import { Icon } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import * as Yup from 'yup';
import { ContainedButton, TextButton } from '../../../../components';
import { Client } from '../../../../models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			display: 'flex',
			flexDirection: 'column',
			width: 500,

			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		button: {
			'& > *': {
				marginRight: theme.spacing(1),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	name: Yup.string().required('This field is required'),
	nationality: Yup.string(),
	durationStay: Yup.string(),
	crewMembers: Yup.string(),
	invoiceAddress: Yup.string(),
});

interface Props {
	initialValues: Partial<Client>;
	onSubmit: (
		values: any,
		formikHelpers: FormikHelpers<Partial<Client>>
	) => void;
	onDelete?: () => void;
	isEdit?: boolean;
	isLoading: boolean;
	isDeleting?: boolean;
}

function Form(props: Props) {
	const {
		initialValues,
		onSubmit,
		onDelete,
		isEdit,
		isLoading,
		isDeleting,
	} = props;
	const classes = useStyles();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({ submitForm }) => (
				<FormikForm className={classes.form}>
					<Field component={TextField} name="name" label="Name" />

					<Field component={TextField} name="nationality" label="Nationality" />

					<Field
						component={TextField}
						name="durationStay"
						label="Duration stay"
					/>

					<Field
						component={TextField}
						name="crewMembers"
						label="Crew members"
					/>

					<Field
						component={TextField}
						name="invoiceAddress"
						label="Invoice Address"
					/>

					<div className={classes.button}>
						{isEdit ? (
							<>
								<ContainedButton
									type="submit"
									isLoading={isLoading}
									onClick={submitForm}
								>
									Edit client
								</ContainedButton>
								<TextButton
									startIcon={<Icon>delete_outline</Icon>}
									isLoading={isDeleting}
									onClick={onDelete}
								>
									Delete
								</TextButton>
							</>
						) : (
							<ContainedButton
								type="submit"
								endIcon={<Icon>add</Icon>}
								isLoading={isLoading}
								onClick={submitForm}
							>
								Add client
							</ContainedButton>
						)}
					</div>
				</FormikForm>
			)}
		</Formik>
	);
}

export default Form;
