import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(3),
		},
		dark: {
			padding: theme.spacing(2),
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary.main,
		},
	})
);

interface Props {
	children: React.ReactNode;
	gutterBottom?: boolean;
	isLoading?: boolean;
	dark?: boolean;
}

const PageTitle = (props: Props) => {
	const { children, gutterBottom, isLoading, dark } = props;
	const classes = useStyles();

	return (
		<Typography
			color="inherit"
			variant="h5"
			component="h1"
			className={clsx(
				gutterBottom ? classes.root : '',
				dark ? classes.dark : ''
			)}
		>
			{isLoading ? (
				<Skeleton variant="text" animation="wave" width={200} />
			) : (
				children
			)}
		</Typography>
	);
};

export default PageTitle;
