/* eslint-disable react-hooks/exhaustive-deps */
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { isFieldError } from '../../../helpers';
import { Vessel, VesselType } from '../../../models';
import { useOvermind } from '../../../overmind';

interface Fields {
	clientId: string;
	vesselId: string;
	vesselName: string;
	vesselType: string;
}

const VesselFields = () => {
	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
	} = useFormikContext<Fields>();

	const {
		state: {
			vessel,
			ticket: { currentTicket },
			client: { currentClient },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		actions.vessel.fetchTypes();
	}, []);

	useEffect(() => {
		if (vessel.types.length && currentTicket?.vessel)
			setFieldValue('vesselType', currentTicket?.vessel.type);
	}, [vessel.types]);

	useEffect(() => {
		if (!values.vesselName) return;

		if (values.clientId) {
			const vesselSelected =
				currentClient?.vessels &&
				currentClient?.vessels.find(
					(vessel) => vessel.name === values.vesselName
				);

			const vesselId = vesselSelected?.id;
			const vesselType = vesselSelected?.type;

			setFieldValue('vesselId', vesselId);
			setFieldValue('vesselType', vesselType);
		}
	}, [values.vesselName]);

	useEffect(() => {
		if (values.clientId) {
			actions.client.fetchVessel(values.clientId);

			if (
				currentTicket?.vessel &&
				values.clientId === (currentTicket?.client && currentTicket?.client.id)
			) {
				setFieldValue('vesselName', currentTicket?.vessel.name);
				setFieldValue('vesselType', currentTicket?.vessel.type);
			} else {
				if (currentClient?.vessels) {
					setFieldValue(
						'vesselName',
						currentClient?.vessels.length ? currentClient?.vessels[0].name : ''
					);

					setFieldValue(
						'vesselType',
						currentClient?.vessels.length ? currentClient?.vessels[0].type : ''
					);
				}
			}
		} else {
			setFieldValue('vesselName', '');
			setFieldValue('vesselType', '');
		}
	}, [values.clientId]);

	return (
		<>
			<Field type="hidden" name="vesselId" value={values.vesselId} />

			{values.clientId ? (
				<>
					{currentClient && (
						<FormControl fullWidth>
							<InputLabel id="vessel-name-label">Vessel Name</InputLabel>
							<Select
								labelId="vessel-name-label"
								name="vesselName"
								value={values.vesselName}
								onChange={handleChange}
								onBlur={handleBlur}
							>
								{currentClient?.vessels &&
									currentClient?.vessels.map(
										(vessel: Vessel, index: number) => (
											<MenuItem key={index} value={vessel.name}>
												{vessel.name}
											</MenuItem>
										)
									)}
							</Select>

							{touched.vesselName && errors.vesselName && (
								<FormHelperText error>{errors.vesselName}</FormHelperText>
							)}
						</FormControl>
					)}

					<FormControl fullWidth>
						<InputLabel id="vessel-type-label">Vessel Type</InputLabel>
						<Select
							labelId="vessel-type-label"
							name="vesselType"
							value={values.vesselType}
							onChange={handleChange}
							onBlur={handleBlur}
							error={isFieldError(touched.vesselType, errors.vesselType)}
						>
							{vessel.types.map((vessel: VesselType, index: number) => (
								<MenuItem key={index} value={vessel.name}>
									{vessel.name}
								</MenuItem>
							))}
						</Select>

						{touched.vesselType && errors.vesselType && (
							<FormHelperText error>{errors.vesselType}</FormHelperText>
						)}
					</FormControl>
				</>
			) : (
				<>
					<TextField
						label="Vessel Name"
						name="vesselName"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.vesselName}
						error={isFieldError(touched.vesselName, errors.vesselName)}
						helperText={touched.vesselName && errors.vesselName}
					/>

					<FormControl fullWidth>
						<InputLabel id="vessel-type-label">Vessel Type</InputLabel>
						<Select
							labelId="vessel-type-label"
							name="vesselType"
							value={values.vesselType}
							onChange={handleChange}
							onBlur={handleBlur}
							error={isFieldError(touched.vesselType, errors.vesselType)}
						>
							{vessel.types.map((vessel: VesselType, index: number) => (
								<MenuItem key={index} value={vessel.name}>
									{vessel.name}
								</MenuItem>
							))}
						</Select>

						{touched.vesselType && errors.vesselType && (
							<FormHelperText error>{errors.vesselType}</FormHelperText>
						)}
					</FormControl>
				</>
			)}
		</>
	);
};

export default VesselFields;
