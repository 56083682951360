/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import {
	Link,
	Route,
	Switch,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { Page, PageTitle } from '../../components';
import { useOvermind } from '../../overmind';
import {
	AddContact,
	AddVessel,
	Basic,
	Contacts,
	EditContact,
	EditVessel,
	Projects,
	Vessels,
} from './components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tabs: {
			marginTop: theme.spacing(2),
		},
	})
);

function DetailsScreen() {
	const classes = useStyles();
	const { clientId } = useParams<{
		clientId: string;
	}>();
	const { path, url } = useRouteMatch();
	const location = useLocation();

	const {
		state: {
			client: { currentClient, isEditing, isDeleting },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		actions.client.getById(clientId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientId]);

	const conditions = ['add', 'edit'];
	const isChild = conditions.some((el) => location.pathname.includes(el));
	const activeLocation = isChild
		? location.pathname.replace(/\/add|\/edit\/([0-9]+)/gi, '')
		: location.pathname;

	return (
		<Page title={currentClient?.name}>
			<PageTitle>{currentClient?.name}</PageTitle>

			<Tabs textColor="inherit" value={activeLocation} className={classes.tabs}>
				<Tab
					textColor="inherit"
					label="Basic"
					component={Link}
					to={`${url}/basic`}
					value={`${url}/basic`}
				/>
				<Tab
					textColor="inherit"
					label="Projects"
					component={Link}
					to={`${url}/projects`}
					value={`${url}/projects`}
				/>
				<Tab
					textColor="inherit"
					label="Vessels"
					component={Link}
					to={`${url}/vessels`}
					value={`${url}/vessels`}
				/>
				<Tab
					textColor="inherit"
					label="Contacts"
					component={Link}
					to={`${url}/contacts`}
					value={`${url}/contacts`}
				/>
			</Tabs>

			<Box paddingX={2} paddingY={3}>
				<Switch>
					<Route exact path={`${path}/basic`}>
						<Basic
							client={currentClient!}
							isEditing={isEditing}
							isDeleting={isDeleting}
						/>
					</Route>
					<Route exact path={`${path}/projects`}>
						<Projects tickets={currentClient?.tickets || []} />
					</Route>
					<Route exact path={`${path}/vessels`}>
						<Vessels vessels={currentClient?.vessels || []} />
					</Route>
					<Route exact path={`${path}/vessels/add`}>
						<AddVessel />
					</Route>
					<Route exact path={`${path}/vessels/edit/:vesselId`}>
						<EditVessel />
					</Route>
					<Route exact path={`${path}/contacts`}>
						<Contacts contacts={currentClient?.contacts || []} />
					</Route>
					<Route exact path={`${path}/contacts/add`}>
						<AddContact />
					</Route>
					<Route exact path={`${path}/contacts/edit/:contactId`}>
						<EditContact />
					</Route>
				</Switch>
			</Box>
		</Page>
	);
}

export default DetailsScreen;
