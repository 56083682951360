import { FormHelperText, TextareaAutosize } from '@material-ui/core';
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useOvermind } from '../../../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textarea: {
			width: '100%',
			border: 0,
			fontFamily: 'Roboto',
			fontSize: 24,
			color: theme.palette.text.primary,
			padding: theme.spacing(1),
			overflowY: 'hidden',
			borderRadius: 3,
			resize: 'none',

			'&:focus': {
				outlineColor: theme.palette.primary.main,
			},

			'&:hover': {
				backgroundColor: theme.palette.grey[200],
			},
		},
	})
);

interface Props {
	title: string;
}
function Title(props: Props) {
	const { title } = props;
	const classes = useStyles();
	const [error, setError] = useState<string | null>(null);

	const { actions } = useOvermind();
	console.log(title);

	const handleTitleChange = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		setError(null);

		const newTitle = event.target.value;
		if (newTitle === title) return;

		if (!newTitle) {
			setError('This field is required');
		} else if (newTitle.length >= 200) {
			setError('Must be at most 200 characters');
		} else {
			actions.ticket.update({
				title: newTitle,
			});
		}
	};

	return (
		<>
			<TextareaAutosize
				rowsMin={1}
				placeholder="Short summary"
				defaultValue={title}
				onBlur={handleTitleChange}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						event.currentTarget.blur();
					}
				}}
				className={classes.textarea}
			/>

			{error && <FormHelperText error>{error}</FormHelperText>}
		</>
	);
}

export default Title;
