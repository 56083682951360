import { Button, Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { ContainedButton } from '../../../../../../../components';
import { User } from '../../../../../../../models';

const style = {
	control: {
		backgroundColor: '#fff',
		fontSize: 14,
		fontWeight: 'normal',
	},

	'&multiLine': {
		control: {
			fontFamily: 'monospace',
			minHeight: 50,
		},
		highlighter: {
			padding: 9,
			border: '1px solid transparent',
		},
		input: {
			padding: 9,
			border: '1px solid silver',
		},
	},

	'&singleLine': {
		display: 'inline-block',
		width: 180,

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},
		input: {
			padding: 1,
			border: '2px inset',
		},
	},

	suggestions: {
		list: {
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 14,
		},
		item: {
			padding: '5px 15px',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#cee4e5',
			},
		},
	},
};

interface Props {
	value: string;
	onChange: any;
	isWorking: boolean;
	onSubmit: () => void;
	onCancel: () => void;
	users: User[];
}

function BodyForm(props: Props) {
	const { value, onChange, isWorking, onSubmit, onCancel, users } = props;

	return (
		<Fragment>
			<MentionsInput
				inputRef={(input) => {
					if (input != null) {
						input.focus();
					}
				}}
				placeholder="Add a comment..."
				value={value}
				onChange={onChange}
				style={style}
			>
				<Mention
					trigger="@"
					data={users}
					renderSuggestion={(suggestion, search, highlightedDisplay) => (
						<div className="user">{highlightedDisplay}</div>
					)}
					style={{
						backgroundColor: '#cee4e5',
					}}
				/>
			</MentionsInput>

			<Grid container spacing={2} style={{ marginTop: 5 }}>
				<Grid item>
					<ContainedButton
						variant="contained"
						isLoading={isWorking}
						onClick={onSubmit}
						disabled={value.length === 0}
					>
						Save
					</ContainedButton>
				</Grid>
				<Grid item>
					<Button variant="text" onClick={onCancel}>
						Cancel
					</Button>
				</Grid>
			</Grid>
		</Fragment>
	);
}

export default BodyForm;
