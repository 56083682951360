import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import {
	createStyles,
	makeStyles,
	MuiThemeProvider,
	Theme,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, AppDrawer, SnackBar } from './components';
import { msalConfig } from './constants';
import customTheme from './customTheme';
import { getStoredAuthUser } from './helpers';
import { useOvermind } from './overmind';
import Routes from './Routes';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		content: {
			flexGrow: 1,
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginLeft: -drawerWidth,
		},
		contentShift: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		},
		offset: theme.mixins.toolbar,
	})
);

function App() {
	const classes = useStyles();
	const location = useLocation();
	const msalInstance = new PublicClientApplication(msalConfig);

	const [isDrawerOpen, setDrawerOpen] = useState(true);

	const {
		state: {
			alert,
			authentication: { isLoggedIn },
		},
		actions,
	} = useOvermind();
	const user = getStoredAuthUser();

	useEffect(() => {
		if (user && !location.pathname.includes('/login')) {
			actions.authentication.me();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCloseSnackbar = () => {
		actions.alert.clearAlert();
	};

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<MsalProvider instance={msalInstance}>
				<MuiThemeProvider theme={customTheme}>
					<div className={classes.root}>
						<CssBaseline />
						{isLoggedIn && (
							<>
								<AppBar
									openDrawer={isDrawerOpen}
									onDrawerOpen={() => setDrawerOpen((prev) => !prev)}
								/>
								<AppDrawer openDrawer={isDrawerOpen} />
							</>
						)}

						<main
							className={clsx(classes.content, {
								[classes.contentShift]: isDrawerOpen || !isLoggedIn,
							})}
						>
							{isLoggedIn && <div className={classes.offset} />}

							<Routes />
						</main>

						<SnackBar
							severity={alert.type}
							open={alert.isOpen}
							onClose={onCloseSnackbar}
							message={alert.message}
						/>
					</div>
				</MuiThemeProvider>
			</MsalProvider>
		</MuiPickersUtilsProvider>
	);
}

export default App;
