export const fetchTypes = async ({ effects, actions, state }) => {
	state.vessel.isLoading = true;

	try {
		const response = await effects.vessel.api.fetch();

		state.vessel.types = [...response];
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.vessel.isLoading = false;
};
