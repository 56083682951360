import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Page, PageTitle } from '../../components';
import { useCurrentProject } from '../../hooks';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { Form } from './components';
import { UserInitValues } from './models';

function AddScreen() {
	const {
		state: {
			user: { isLoading },
		},
		actions,
	} = useOvermind();

	const { currentProject } = useCurrentProject();

	useEffect(() => {
		if (!currentProject) actions.project.get();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues: UserInitValues = {
		name: '',
		prefix: '',
		email: '',
		phone: '',
		username: '',
		role: '',
		password: '',
		confirmPassword: '',
	};

	const handleSubmit = async (values) => {
		values.project = { id: currentProject?.id };
		await actions.user.create(values);

		history.push({
			pathname: `/users`,
		});
	};

	return (
		<Page title="New user">
			<PageTitle>New user</PageTitle>

			<Box marginTop={3}>
				<Form
					initialValues={initialValues}
					onSubmit={handleSubmit}
					isLoading={isLoading}
				/>
			</Box>
		</Page>
	);
}

export default AddScreen;
