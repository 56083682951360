export const KeyCodes = {
	TAB: 9,
	ENTER: 13,
	ESCAPE: 27,
	SPACE: 32,
	ARROW_LEFT: 37,
	ARROW_UP: 38,
	ARROW_RIGHT: 39,
	ARROW_DOWN: 40,
	M: 77,
};
