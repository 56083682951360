import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';

interface Props {
	className?: string;
	title: string;
	message: string | React.ReactNode;
	confirmText?: string;
	cancelText?: string;
	onConfirm: () => void;
	renderLink?: any;
}

function ConfirmDialog(props: Props) {
	const {
		className,
		title,
		message,
		confirmText,
		cancelText,
		onConfirm,
		renderLink,
	} = props;

	const [stateIsOpen, setStateOpen] = useState(false);
	const handleCancel = () => {
		setStateOpen(false);
	};

	const handleConfirm = () => {
		setStateOpen(false);

		onConfirm();
	};

	return (
		<>
			{renderLink({ open: () => setStateOpen(true) })}

			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth="xs"
				aria-labelledby="confirmation-dialog-title"
				open={stateIsOpen}
				className={className}
			>
				<DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>{message}</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel} color="primary">
						{cancelText}
					</Button>
					<Button onClick={handleConfirm} color="primary">
						{confirmText || 'Cancel'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ConfirmDialog;
