import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { ContainedButton, TextButton } from '../../../../components';
import { isFieldError } from '../../../../helpers';
import { ClientVessel, VesselType } from '../../../../models';
import { useOvermind } from '../../../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			maxWidth: 400,

			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		button: {
			'& > *': {
				marginRight: theme.spacing(1),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	name: Yup.string().required('This field is required'),
	type: Yup.string().required('This field is required'),
});

interface Props {
	initialValues: Partial<ClientVessel>;
	onSubmit: (values: any) => void;
	onDelete?: () => void;
	isEdit?: boolean;
	isLoading: boolean;
}

const Form = (props: Props) => {
	const { initialValues, onSubmit, onDelete, isEdit, isLoading } = props;
	const classes = useStyles();

	const { state, actions } = useOvermind();

	useEffect(() => {
		actions.vessel.fetchTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				errors,
				touched,
			}) => (
				<form onSubmit={handleSubmit} className={classes.form}>
					<TextField
						fullWidth
						label="Name"
						name="name"
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.name, errors.name)}
						helperText={touched.name && errors.name}
						value={values.name}
					/>

					<FormControl fullWidth>
						<InputLabel id="vessel-type-label">Type</InputLabel>
						<Select
							labelId="vessel-type-label"
							name="type"
							value={values.type}
							onChange={handleChange}
						>
							{state.vessel.types.map((vessel: VesselType, index: number) => (
								<MenuItem key={index} value={vessel.name}>
									{vessel.name}
								</MenuItem>
							))}
						</Select>

						{touched.type && errors.type && (
							<FormHelperText error>{errors.type}</FormHelperText>
						)}
					</FormControl>

					<div className={classes.button}>
						{isEdit ? (
							<>
								<ContainedButton type="submit" isLoading={isLoading}>
									Edit vessel
								</ContainedButton>
								<TextButton startIcon={<Icon>delete</Icon>} onClick={onDelete}>
									Delete
								</TextButton>
							</>
						) : (
							<ContainedButton type="submit" isLoading={isLoading}>
								Add vessel
							</ContainedButton>
						)}
					</div>
				</form>
			)}
		</Formik>
	);
};

export default Form;
