import { CircularProgress } from '@material-ui/core';
import React from 'react';
import Button, { ColoredButtonProps } from './Button';

interface Props extends ColoredButtonProps {
	children: React.ReactNode;
	isLoading?: boolean;
}

const TextButton = (props: Props) => {
	const { isLoading, children, ...rest } = props;

	return (
		<Button
			disabled={isLoading}
			{...rest}
			startIcon={
				isLoading ? (
					<CircularProgress size={18} color="primary" />
				) : (
					rest.startIcon
				)
			}
		>
			{children}
		</Button>
	);
};

export default TextButton;
