import { parseISO } from 'date-fns';
import { Calendar } from '../../features/calendar/models';
import { AddEventPayload, Event } from '../../models';

export const fetch = async (
	{ effects, actions, state },
	param: {
		startDate: string;
		endDate: string;
		accessToken: string;
	}
) => {
	state.event.isLoading = true;
	try {
		const data = await effects.event.api.fetch(
			param.startDate,
			param.endDate,
			param.accessToken
		);

		state.event.data = await generateData(data.value);
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.event.isLoading = false;
};

export const create = async (
	{ effects, actions, state },
	data: {
		accessToken: string;
		payload: AddEventPayload;
	}
) => {
	state.event.isLoading = true;

	try {
		const event = await effects.event.api.create(
			data.payload,
			data.accessToken
		);

		state.event.data = [
			...state.event.data,
			{
				title: event.subject,
				start: parseISO(event.start.dateTime),
				end: parseISO(event.end.dateTime),
				allDay: event.isAllDay,
			},
		];
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.event.isLoading = false;
};

const generateData = async (data: any[]) => {
	const events: Event[] = [];
	await Promise.all(
		// eslint-disable-next-line
		data.map((event: Calendar) => {
			events.push({
				title: event.subject,
				start: parseISO(event.start.dateTime),
				end: parseISO(event.end.dateTime),
				allDay: event.isAllDay,
			});
		})
	);

	return events;
};
