import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { set } from 'lodash';
import { ErrorHelper, getStoredAuthToken } from '../helpers';
import history from '../utils/history';
import { ERROR_403 } from './../constants';

declare module 'axios' {
	interface AxiosInstance {
		(config: AxiosRequestConfig): Promise<any>;
	}
}

const instance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-type': 'application/json',
	},
});

instance.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const currentAccessToken = getStoredAuthToken();

		if (
			!currentAccessToken ||
			(config.url && config.url.indexOf('weblogin') !== -1)
		) {
			return config;
		}

		if (currentAccessToken) {
			set(config, 'headers.Authorization', `Bearer ${currentAccessToken}`);
		}

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		if (response.data.error !== undefined && response.data.error.length > 0) {
			throw new Error(ErrorHelper.generalFormat(response.data.error));
		} else if (response.data.success !== undefined && !response.data.success) {
			throw new Error(ErrorHelper.generalFormat(response.data.message));
		}

		return response.data;
	},
	(error) => {
		if (axios.isCancel(error)) {
			throw error;
		}

		if (!error.response) {
			throw error;
		}

		const status = error.response.status;
		if (status && status === 403) {
			history.push({
				pathname: '/login',
				search: `returnUrl=${history.location.pathname}`,
				state: { logoutAction: 'logout' },
			});

			return Promise.reject(new Error(ErrorHelper.generalFormat(ERROR_403)));
		}

		const errorCode = error.response.data.error.code;
		return Promise.reject(new Error(ErrorHelper.generalFormat(errorCode)));
	}
);

export default instance;

export const createCancelToken = () => axios.CancelToken.source();
export const isCancel = (e: any) => axios.isCancel(e);
