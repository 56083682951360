export const TicketType = {
	PROJECT: 'project',
};

export const TicketStatus = {
	NEW: 'new',
	REPLIED: 'replied',
	ASSESSMENT: 'assessment',
	OFFER: 'offer',
	REVIEW: 'review',
	SHOPPING: 'shopping',
	INVOICE: 'invoice',
	UPLOAD: 'upload',
	WAITING: 'waiting',
	DONE: 'done',
	INVALID: 'invalid',
};

export const TicketPriority = {
	HIGHEST: '5',
	HIGH: '4',
	MEDIUM: '3',
	LOW: '2',
	LOWEST: '1',
};

export const TicketTypeCopy = {
	[TicketType.PROJECT]: 'PRO',
};

export const TicketStatusCopy = {
	[TicketStatus.NEW]: 'New',
	[TicketStatus.REPLIED]: 'Returned',
	[TicketStatus.ASSESSMENT]: 'Assessing',
	[TicketStatus.OFFER]: 'Create Quotation',
	[TicketStatus.REVIEW]: 'Need to Review',
	[TicketStatus.SHOPPING]: 'Shopping/Delivery',
	[TicketStatus.INVOICE]: 'Invoice',
	[TicketStatus.UPLOAD]: 'Upload documents',
	[TicketStatus.WAITING]: 'Waiting on Customer',
	[TicketStatus.DONE]: 'Done',
	[TicketStatus.INVALID]: 'Invalid',
};

export const TicketStatusColumn = {
	[TicketStatus.NEW]: 'New',
	[TicketStatus.REPLIED]: 'Returned',
	[TicketStatus.ASSESSMENT]: 'Assessing',
	[TicketStatus.OFFER]: 'Quotation',
	[TicketStatus.REVIEW]: 'Review',
	[TicketStatus.SHOPPING]: 'Shopping/Delivery',
	[TicketStatus.INVOICE]: 'Invoice',
	[TicketStatus.UPLOAD]: 'Upload',
	[TicketStatus.WAITING]: 'Waiting',
	[TicketStatus.DONE]: 'Done',
	[TicketStatus.INVALID]: 'Invalid',
};

export const TicketPriorityCopy = {
	[TicketPriority.HIGHEST]: 'Highest',
	[TicketPriority.HIGH]: 'High',
	[TicketPriority.MEDIUM]: 'Medium',
	[TicketPriority.LOW]: 'Low',
	[TicketPriority.LOWEST]: 'Lowest',
};

export const TicketAttachmentPath = './public';
