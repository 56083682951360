import { LoginPayload } from '../../features/authentication/models';
import {
	removeStoredAuthToken,
	removeStoredAuthUser,
	storeAuthToken,
	storeAuthUser,
} from '../../helpers';

export const me = async ({ effects, actions, state }) => {
	state.authentication.isLoading = true;

	try {
		const response = await effects.authentication.api.me();

		state.authentication.user = response;
		state.authentication.isLoggedIn = true;
	} catch (error) {
		console.log('error', error);
		actions.alert.setAlert({ type: 'error', message: error.message });
	}
	state.authentication.isLoading = false;
};

export const login = async (
	{ effects, actions, state },
	payload: LoginPayload
) => {
	state.authentication.isLoading = true;

	try {
		const response = await effects.authentication.api.login({
			email: payload.email,
			password: payload.password,
		});

		storeAuthToken(response.token);
		storeAuthUser(response.user);

		state.authentication.accessToken = response.token;
		state.authentication.user = response.user;
		state.authentication.isLoggedIn = true;
	} catch (error) {
		console.log('error', error);
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.authentication.isLoading = false;
};

export const logout = async ({ state }) => {
	state.authentication.isLoading = true;

	removeStoredAuthToken();
	removeStoredAuthUser();

	state.authentication.user = {};
	state.authentication.isLoggedIn = false;
	state.authentication.isLoading = false;
};
