import {
	Breadcrumbs as MUIBreadcrumbs,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Link,
	RouteComponentProps,
	useLocation,
	withRouter,
} from 'react-router-dom';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(2),
		},
		breadcrumbs: {
			color: theme.palette.primary.main,
		},
		icon: {
			marginRight: theme.spacing(0.5),
			width: 20,
			height: 20,
		},
		link: {
			display: 'flex',
			textDecoration: 'none',
			color: theme.palette.primary.main,
		},
	})
);

interface Props {
	children?: React.ReactNode;
	isLoading?: boolean;
}

const Breadcrumbs = (props: Props & RouteComponentProps) => {
	const { children, isLoading } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const { pathname } = useLocation();
	const pathnames = pathname.split('/').filter((x) => x);

	const breadcrumbsNameMap = {
		'/projects': t('project.title'),
		'/products': t('product.title'),
		'/clients': t('client.title'),
		'/templates': t('template.title'),
		'/users': t('user.title'),
		'/settings': t('setting.title'),
	};

	return (
		<div className={classes.root}>
			{isLoading ? (
				<Skeleton variant="rect" width={300} height={31} />
			) : (
				<MUIBreadcrumbs aria-label="breadcrumb">
					{pathnames.map((name, index) => {
						const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
						const isLast = index === pathnames.length - 1;
						return isLast ? (
							<Typography key={name} color="textPrimary">
								{children ? children : breadcrumbsNameMap[`/${name}`]}
							</Typography>
						) : (
							<Link key={name} to={routeTo} className={classes.link}>
								{breadcrumbsNameMap[routeTo]}
							</Link>
						);
					})}
				</MUIBreadcrumbs>
			)}
		</div>
	);
};

export default withRouter(Breadcrumbs);
