import { Box, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import React from 'react';
import DataTable from 'react-data-table-component';
import { useRouteMatch } from 'react-router-dom';
import { DateHelper } from '../../../../helpers';
import { Contact } from '../../../../models';
import history from '../../../../utils/history';
import { tableStyles } from '../../../../utils/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			padding: theme.spacing(3, 0, 0),
		},
		search: {
			marginRight: theme.spacing(2),
		},
		dropzone: {
			marginBottom: theme.spacing(2),
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

const columns = [
	{
		name: 'Name',
		selector: 'name',
		sortable: true,
	},
	{
		name: 'Function',
		selector: 'function',
	},
	{
		name: 'Phone',
		selector: 'phone',
	},
	{
		name: 'Email',
		selector: 'email',
	},
	{
		name: 'Created',
		cell: (row: Contact) => DateHelper.distanceToNowFormat(row.createdAt),
	},
	{
		name: 'Updated at',
		cell: (row: Contact) => DateHelper.distanceToNowFormat(row.updatedAt),
	},
];

interface Props {
	contacts: Contact[];
}

function Contacts(props: Props) {
	const { contacts } = props;
	const { url } = useRouteMatch();
	const classes = useStyles();

	const handleRowClicked = (row: Contact) => {
		history.push(`${url}/edit/${row.id}`);
	};

	return (
		<>
			<Box display="flex" justifyContent="flex-end">
				<Button
					variant="contained"
					color="inherit"
					startIcon={<AddIcon />}
					onClick={() => history.push(`${url}/add`)}
				>
					New
				</Button>
			</Box>
			<DataTable
				customStyles={tableStyles}
				columns={columns}
				data={contacts}
				pagination
				highlightOnHover
				pointerOnHover
				noHeader
				className={classes.table}
				onRowClicked={handleRowClicked}
			/>
		</>
	);
}

export default Contacts;
