import { Comment } from '../../models';

export const fetch = async ({ effects, actions, state }) => {
	state.comment.isLoading = true;

	try {
		const response = await effects.comment.api.fetch();

		state.comment = { data: response };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.comment.isLoading = false;
};

export const create = async (
	{ effects, actions, state },
	payload: Partial<Comment>
) => {
	state.comment.isLoading = true;

	try {
		const response = await effects.comment.api.create(payload);

		state.comment = {
			data: [...state.comment.data, response],
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.comment.isLoading = false;
};
