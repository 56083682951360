import {
	endOfMonth,
	format,
	formatDistanceToNow,
	parseISO,
	startOfMonth,
} from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import i18n from 'i18next';
import { generalDateFormat } from '../constants';

class DateHelper {
	static generalFormat(date: Date | number | string | undefined) {
		if (!date) {
			date = new Date();
		}

		if (typeof date === 'string') {
			date = DateHelper.stringToDate(date);
		}

		return DateHelper.formatDate(date, generalDateFormat);
	}

	static distanceToNowFormat(date: Date | number | string | undefined) {
		if (!date) {
			date = new Date();
		}

		if (typeof date === 'string') {
			date = DateHelper.stringToDate(date);
		}

		return formatDistanceToNow(date, { addSuffix: true });
	}

	static formatDate(date: Date | number, output: string) {
		return format(date, output, {
			locale: i18n.language === 'en' ? enUS : nl,
		});
	}

	static stringToDate(date: string) {
		return parseISO(date);
	}

	static startOfMonthString(date: Date) {
		return startOfMonth(date).toLocaleString('sv');
	}

	static endOfMonthString(date: Date) {
		return endOfMonth(date).toLocaleString('sv');
	}
}

export default DateHelper;
