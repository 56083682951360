import { IDataTableStyles } from 'react-data-table-component';
import { TicketPriority, TicketStatus, TicketType } from '../constants';

export const color = {
	primary: '#0052cc', // Blue
	success: '#0B875B', // green
	danger: '#E13C3C', // red
	warning: '#F89C1C', // orange
	secondary: '#F4F5F7', // light grey

	textDarkest: '#172b4d',
	textDark: '#42526E',
	textMedium: '#5E6C84',
	textLight: '#8993a4',
	textLink: '#0052cc',

	backgroundDarkPrimary: '#0747A6',
	backgroundMedium: '#dfe1e6',
	backgroundLight: '#ebecf0',
	backgroundLightest: '#F4F5F7',
	backgroundLightPrimary: '#D2E5FE',
	backgroundLightSuccess: '#E4FCEF',

	borderLightest: '#dfe1e6',
	borderLight: '#C1C7D0',
	borderInputFocus: '#4c9aff',
};

export const ticketTypeColors = {
	[TicketType.PROJECT]: '#4FADE6', // blue
};

export const ticketPriorityColors = {
	[TicketPriority.HIGHEST]: '#CD1317', // red
	[TicketPriority.HIGH]: '#E9494A', // orange
	[TicketPriority.MEDIUM]: '#E97F33', // orange
	[TicketPriority.LOW]: '#2D8738', // green
	[TicketPriority.LOWEST]: '#57A55A', // green
};

export const ticketStatusColors = {
	[TicketStatus.NEW]: '#ffffff',
	[TicketStatus.REPLIED]: '#ffffff',
	[TicketStatus.ASSESSMENT]: '#ffffff',
	[TicketStatus.OFFER]: '#ffffff',
	[TicketStatus.REVIEW]: '#ffffff',
	[TicketStatus.SHOPPING]: '#ffffff',
	[TicketStatus.UPLOAD]: '#ffffff',
	[TicketStatus.WAITING]: '#ffffff',
	[TicketStatus.DONE]: '#ffffff',
};

export const ticketStatusBackgroundColors = {
	[TicketStatus.NEW]: '#4DD091',
	[TicketStatus.REPLIED]: '#FF6F68',
	[TicketStatus.ASSESSMENT]: '#FF828B',
	[TicketStatus.OFFER]: '#00CDAC',
	[TicketStatus.REVIEW]: '#00B0BA',
	[TicketStatus.SHOPPING]: '#0065A2',
	[TicketStatus.UPLOAD]: '#6C88C4',
	[TicketStatus.WAITING]: '#00A5E3',
	[TicketStatus.DONE]: '#57A55A',
	[TicketStatus.INVALID]: '#74737A',
};

export const sizes = {
	appNavBarLeftWidth: 64,
	secondarySideBarWidth: 230,
	minViewportWidth: 1000,
};

export const zIndexValues = {
	modal: 1000,
	dropdown: 101,
	navLeft: 100,
};

export const font = {
	regular: 'font-family: "CircularStdBook"; font-weight: normal;',
	medium: 'font-family: "CircularStdMedium"; font-weight: normal;',
	bold: 'font-family: "CircularStdBold"; font-weight: normal;',
	black: 'font-family: "CircularStdBlack"; font-weight: normal;',
	size: (size) => `font-size: ${size}px;`,
};

export const tableStyles: IDataTableStyles = {
	headRow: {
		style: {
			border: 'none',
		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '14px',
			fontWeight: 700,
		},
	},
	rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			outline: '1px solid #FFFFFF',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	},
};
