import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Page, PageTitle } from '../../components';
import { UserHelper } from '../../helpers';
import { useCurrentUser } from '../../hooks';
import { Product } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { Form } from './components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

function AddScreen() {
	const classes = useStyles();
	const { state } = useLocation<{
		name: string;
		ticketId: string;
		index: number;
		isEdit: boolean;
	}>();

	const {
		state: {
			product: { isLoading },
		},
		actions,
	} = useOvermind();

	const { currentUser } = useCurrentUser();

	const userHelper = new UserHelper(currentUser);

	const initialValues: Partial<Product> = {
		name: state?.name || '',
		unit: '',
		price: '',
		margin: '',
		company: '',
		categoryId: 0,
	};

	const handleSubmit = async (values: Partial<Product>) => {
		const payload = { ...values };
		payload.isAccepted = !state || userHelper.isAdmin() ? true : false;

		await actions.product.create(payload);

		if (!state) {
			history.push(`/products`);
		} else {
			history.push({
				pathname: `/projects/ticket/${state.ticketId}/${
					state.isEdit ? `edit-quotation` : `create-quotation`
				}`,
				state: {
					index: state.index,
				},
			});
		}
	};

	return (
		<Page title="Add New Product">
			<PageTitle>Add New Product</PageTitle>

			<Grid container className={classes.form}>
				<Grid item md={4}>
					<Form
						initialValues={initialValues}
						onSubmit={handleSubmit}
						isLoading={isLoading}
					/>
				</Grid>
			</Grid>
		</Page>
	);
}

export default AddScreen;
