import { useMsal } from '@azure/msal-react';
import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { loginRequest } from '../../constants';
import { IconMicrosoft } from '../Icons';

interface Props {
	description: string;
}

function ConnectMicrosoft(props: Props) {
	const { description } = props;
	const { instance } = useMsal();

	const handleConnectMicrosoft = () => {
		instance.loginPopup({
			...loginRequest,
		});
	};

	return (
		<Box
			height={400}
			margin={3}
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
		>
			<Typography variant="body1" style={{ marginBottom: 10 }}>
				{description}
			</Typography>

			<Button
				variant="contained"
				color="primary"
				startIcon={<IconMicrosoft />}
				onClick={handleConnectMicrosoft}
			>
				Connect with Microsoft
			</Button>
		</Box>
	);
}

export default ConnectMicrosoft;
