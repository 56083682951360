import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import DateHelper from '../../../../helpers/date';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: '11px',
			paddingTop: '13px',
			lineHeight: '22px',
			borderTop: '1px solid #dfe1e6',
			color: theme.palette.grey[600],
			fontSize: 13,
		},
	})
);

interface Props {
	createdAt: string;
	updatedAt: string;
	isLoading?: boolean;
}

function Dates(props: Props) {
	const classes = useStyles();
	const { createdAt, updatedAt, isLoading } = props;

	return (
		<div className={classes.root}>
			<div>
				{isLoading ? (
					<Skeleton variant="text" width="70%" animation="wave" />
				) : (
					`Created ${DateHelper.distanceToNowFormat(createdAt)}`
				)}
			</div>
			<div>
				{isLoading ? (
					<Skeleton variant="text" width="70%" animation="wave" />
				) : (
					`Updated at ${DateHelper.distanceToNowFormat(updatedAt)}`
				)}
			</div>
		</div>
	);
}

export default Dates;
