import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const AntTabs = withStyles({
	root: {
		minHeight: 'auto',
		borderBottom: 0,
	},
	indicator: {
		display: 'none',
	},
})(Tabs);

export default AntTabs;
