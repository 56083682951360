import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Page, PageTitle } from '../../components';
import { Template } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { Form } from './components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

function EditScreen() {
	const classes = useStyles();
	const { templateId } = useParams<{
		templateId: string;
	}>();

	const {
		state: {
			template: { data, isLoading },
		},
		actions,
	} = useOvermind();

	const template = data.filter(
		(template: Template) => template.id.toString() === templateId
	);

	const initialValues: {
		name: string;
		body: string;
	} = {
		name: template.length ? template[0].name : '',
		body: template.length ? template[0].body : '',
	};

	useEffect(() => {
		actions.template.getById(templateId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async (values) => {
		await actions.template.update({
			templateId,
			payload: values,
		});

		history.push({
			pathname: `/templates`,
		});
	};

	const handleDelete = async () => {
		await actions.template.remove(templateId);

		history.push({
			pathname: `/templates`,
		});
	};

	return (
		<Page title="Templates">
			<PageTitle>Templates</PageTitle>

			<Grid container className={classes.form}>
				<Grid item xs={8}>
					<Form
						initialValues={initialValues}
						onSubmit={handleSubmit}
						onDelete={handleDelete}
						isEdit
						isLoading={isLoading}
					/>
				</Grid>
			</Grid>
		</Page>
	);
}

export default EditScreen;
