import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { snackbarTimer } from '../constants';

interface Props {
	severity: 'success' | 'info' | 'warning' | 'error';
	message: string;
	open: boolean;
	onClose: () => void;
}

const SnackBar = (props: Props) => {
	const { severity, message, open, onClose } = props;

	return (
		<Snackbar
			open={open}
			autoHideDuration={snackbarTimer}
			onClose={onClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		>
			<Alert severity={severity} variant="filled">
				{message}
			</Alert>
		</Snackbar>
	);
};

export default SnackBar;
