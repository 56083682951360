import { Client, Invoice } from '../../models';

export interface ClientState {
	isLoading: boolean;
	isEditing: boolean;
	isDeleting: boolean;
	data: Client[];
	currentClient: Client | null;
	invoices: Invoice[];
}

export const state: ClientState = {
	isLoading: false,
	isEditing: false,
	isDeleting: false,
	data: [],
	currentClient: null,
	invoices: [],
};
