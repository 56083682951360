/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAccount, useMsal } from '@azure/msal-react';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { useParams } from 'react-router-dom';
import { Page, TextButton } from '../../components';
import { loginRequest } from '../../constants';
import { DateHelper, MsGraph } from '../../helpers';
import history from '../../utils/history';
import { FormCompose } from './components';
import { Email, Recipient } from './models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		list: {
			width: '100%',
			backgroundColor: theme.palette.grey[100],
			marginBottom: theme.spacing(2),
		},
		inline: {
			display: 'inline',
		},
		actions: {
			'& > *': {
				marginLeft: theme.spacing(1),
			},
		},
		body: {
			'& table': {
				margin: 'auto',
			},
		},
	})
);

function DetailsScreen() {
	const classes = useStyles();
	const { messageId } = useParams<{
		messageId: string;
	}>();
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});
	const [data, setData] = useState<Email | null>(null);
	const [isLoading, setLoading] = useState(true);
	const [isLoadingAttachment, setLoadingAttachment] = useState(true);
	const [isLoadingDelete, setLoadingDelete] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [attachments, setAttachments] = useState<any[]>([]);
	const [toRecipients, setToRecipients] = useState<Recipient[]>([]);
	const formCompose = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (account) {
			instance
				.acquireTokenSilent({
					...loginRequest,
					account,
				})
				.then(async (response) => {
					const client = new MsGraph(response.accessToken);
					const email: Email = await client.getEmailById(messageId);

					if (!email.isRead) {
						await client.updateEmail(email.id, { isRead: true });
					}

					setData(email);
					setLoading(false);

					if (email.hasAttachments) {
						setLoadingAttachment(true);
						const attachments = await client.getAttachmentsByEmailId(email.id);

						setAttachments(attachments.value);
						setLoadingAttachment(false);
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, messageId]);

	const convertBase64ToBlobData = (base64Data: string, contentType: string) => {
		// Decode Base64 string
		const decodedData = window.atob(base64Data);

		// Create UNIT8ARRAY of size same as row data length
		const uInt8Array = new Uint8Array(decodedData.length);

		// Insert all character code into uInt8Array
		for (let i = 0; i < decodedData.length; ++i) {
			uInt8Array[i] = decodedData.charCodeAt(i);
		}

		// Return BLOB image after conversion
		return new Blob([uInt8Array], { type: contentType });
	};

	const handleDownload = (
		fileName: string,
		contentBytes: string,
		contentType: string
	) => {
		const blobData = convertBase64ToBlobData(contentBytes, contentType);

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			//IE
			window.navigator.msSaveOrOpenBlob(blobData, fileName);
		} else {
			// chrome
			const blob = new Blob([blobData], { type: contentType });
			const url = window.URL.createObjectURL(blob);
			// window.open(url);
			const link = document.createElement('a');
			link.href = url;
			link.download = fileName;
			link.click();
		}
	};

	const handleReply = (toRecipients) => {
		setShowForm(true);
		setToRecipients(toRecipients);

		setTimeout(() => {
			formCompose.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		});
	};

	const handleDelete = () => {
		if (account) {
			setLoadingDelete(true);
			instance
				.acquireTokenSilent({
					...loginRequest,
					account,
				})
				.then(async (response) => {
					const client = new MsGraph(response.accessToken);

					await client.deleteEmail(messageId);

					setLoadingDelete(false);

					history.push('/mailbox');
				});
		}
	};

	const getExtensionFile = (fileName: string) => {
		return fileName.substr(fileName.lastIndexOf('.') + 1);
	};

	return (
		<Page title={data?.subject}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				marginBottom={2}
			>
				<Box display="flex" alignItems="center">
					{isLoading ? (
						<Skeleton
							variant="circle"
							width={24}
							height={24}
							animation="wave"
						/>
					) : (
						<IconButton onClick={() => history.goBack()}>
							<ArrowBackIcon />
						</IconButton>
					)}

					<Box marginLeft={1}>
						{isLoading ? (
							<Skeleton variant="text" width={250} animation="wave" />
						) : (
							<strong>{data?.subject}</strong>
						)}
					</Box>
				</Box>

				<Box display="flex" alignItems="center" className={classes.actions}>
					{isLoading ? (
						<Skeleton variant="rect" width={76} height={36} animation="wave" />
					) : (
						<Button
							variant="text"
							startIcon={<ReplyIcon />}
							onClick={() => handleReply([data?.from])}
						>
							Reply
						</Button>
					)}

					{isLoading ? (
						<Skeleton variant="rect" width={76} height={36} animation="wave" />
					) : (
						<>
							{data?.toRecipients && data?.toRecipients.length > 1 ? (
								<Button
									variant="text"
									startIcon={<ReplyAllIcon />}
									onClick={() => {
										const toRecipients = [
											data?.from,
											...data?.toRecipients.filter(
												(recipient: Recipient) =>
													recipient.emailAddress.address !== account?.username
											),
										];

										handleReply(toRecipients);
									}}
								>
									Reply all
								</Button>
							) : null}
						</>
					)}

					{isLoading ? (
						<Skeleton variant="rect" width={76} height={36} animation="wave" />
					) : (
						<TextButton
							variant="text"
							startIcon={<DeleteIcon />}
							onClick={handleDelete}
							isLoading={isLoadingDelete}
						>
							Delete
						</TextButton>
					)}
				</Box>
			</Box>
			<List className={classes.list}>
				<ListItem>
					<ListItemAvatar>
						{isLoading ? (
							<Skeleton
								variant="circle"
								width={32}
								height={32}
								animation="wave"
							/>
						) : (
							<Avatar>{data?.from.emailAddress.name?.charAt(0)}</Avatar>
						)}
					</ListItemAvatar>
					<ListItemText
						primary={
							isLoading ? (
								<Skeleton variant="text" width={150} animation="wave" />
							) : (
								DateHelper.distanceToNowFormat(data?.sentDateTime)
							)
						}
						secondary={
							isLoading ? (
								<Skeleton variant="text" width={250} animation="wave" />
							) : (
								<>
									<Typography
										component="span"
										variant="body2"
										className={classes.inline}
										color="textPrimary"
									>
										{data?.from.emailAddress.name}
									</Typography>
									{` — <${data?.from.emailAddress.address}>`}
								</>
							)
						}
					/>
				</ListItem>
			</List>
			{isLoading ? (
				<Skeleton variant="rect" width={'100%'} height={400} animation="wave" />
			) : (
				<div
					dangerouslySetInnerHTML={{ __html: data?.body.content }}
					className={classes.body}
				/>
			)}

			{data?.hasAttachments && (
				<Box display="flex" marginTop={5}>
					{(isLoadingAttachment ? Array.from(new Array(1)) : attachments).map(
						(attachment: any, index: number) => (
							<Box
								key={index}
								width={64}
								marginX={1}
								onClick={() =>
									!isLoadingAttachment
										? handleDownload(
												attachment.name,
												attachment.contentBytes,
												attachment.contentType
										  )
										: null
								}
								style={{ cursor: 'pointer' }}
							>
								{isLoadingAttachment ? (
									<FileIcon />
								) : (
									<FileIcon
										extension={getExtensionFile(attachment.name)}
										{...defaultStyles[getExtensionFile(attachment.name)]}
									/>
								)}
							</Box>
						)
					)}
				</Box>
			)}

			{!isLoading && showForm && (
				<FormCompose
					ref={formCompose}
					subject={data?.subject || ''}
					toRecipients={toRecipients}
					onHideForm={() => setShowForm(false)}
				/>
			)}
		</Page>
	);
}

export default DetailsScreen;
