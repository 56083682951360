import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Page, PageTitle } from '../../components';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { Form } from './components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

function AddScreen() {
	const classes = useStyles();

	const {
		state: {
			template: { isLoading },
		},
		actions,
	} = useOvermind();

	const initialValues: {
		name: string;
		body: string;
	} = {
		name: '',
		body: '',
	};

	const handleSubmit = async (values) => {
		await actions.template.create(values);

		history.push({
			pathname: `/templates`,
		});
	};

	return (
		<Page title="Add New Template">
			<PageTitle>Add New Template</PageTitle>

			<Grid container className={classes.form}>
				<Grid item xs={8}>
					<Form
						initialValues={initialValues}
						onSubmit={handleSubmit}
						isLoading={isLoading}
					/>
				</Grid>
			</Grid>
		</Page>
	);
}

export default AddScreen;
