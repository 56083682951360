import { AlertState } from './state';

export const setAlert = ({ state }, payload: Partial<AlertState>) => {
	state.alert.isOpen = true;
	state.alert.type = payload.type;
	state.alert.message = payload.message;
};

export const clearAlert = ({ state }) => {
	state.alert.isOpen = false;
	state.alert.message = '';
};
