import AttachFileIcon from '@material-ui/icons/AttachFile';
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomToolbar = () => (
	<div id="toolbar">
		<select
			className="ql-size"
			defaultValue={''}
			onChange={(e) => e.persist()}
			onBlur={(e) => e.persist()}
		>
			<option value="small" />
			<option selected />
			<option value="large" />
			<option value="huge" />
		</select>
		<button className="ql-bold" />
		<button className="ql-italic" />
		<button className="ql-strike" />
		<button className="ql-script" value="sub" />
		<button className="ql-script" value="super" />
		<select className="ql-color">
			<option value="red" />
			<option value="green" />
			<option value="blue" />
			<option value="orange" />
			<option value="violet" />
			<option value="#d0d1d2" />
			<option selected />
		</select>
		<button className="ql-attachFile">
			<AttachFileIcon />
		</button>
	</div>
);

function Editor() {
	const quillRef = useRef(null);

	const attachFile = () => {
		console.log('attachFile');
	};

	const modules = {
		toolbar: {
			container: '#toolbar',
			handlers: {
				attachFile: attachFile,
			},
		},
		clipboard: {
			matchVisual: false,
		},
	};

	return (
		<>
			<CustomToolbar />
			<ReactQuill ref={quillRef} theme="snow" modules={modules} />
		</>
	);
}

export default Editor;
