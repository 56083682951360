import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { toInteger } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PageTitle } from '../../../../components';
import { Contact } from '../../../../models';
import { useOvermind } from '../../../../overmind';
import history from '../../../../utils/history';
import Form from './Form';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

function Edit() {
	const classes = useStyles();
	const { clientId, contactId } = useParams<{
		clientId: string;
		contactId: string;
	}>();
	const {
		state: {
			client: { currentClient, isLoading },
		},
		actions,
	} = useOvermind();

	const contact = currentClient?.contacts.find(
		(contact: Contact) => contact.id.toString() === contactId
	);

	const initialValues: Partial<Contact> = {
		name: contact?.name || '',
		email: contact?.email || '',
		phone: contact?.phone || '',
		function: contact?.function || '',
	};

	const handleSubmit = async (values: Partial<Contact>) => {
		values.id = toInteger(contactId);
		await actions.client.updateContact(values);

		history.push(`/clients/${clientId}/contacts`);
	};

	const handleDelete = async () => {
		await actions.client.removeContact(contactId);

		history.push(`/clients/${clientId}/contacts`);
	};

	return (
		<>
			<PageTitle>Edit contact</PageTitle>

			<Grid container className={classes.form}>
				<Grid item xs={8}>
					<Form
						initialValues={initialValues}
						onSubmit={handleSubmit}
						onDelete={handleDelete}
						isLoading={isLoading}
						isEdit
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default Edit;
