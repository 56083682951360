/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAccount, useMsal } from '@azure/msal-react';
import {
	Dialog,
	DialogActions,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Switch,
	TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { differenceInCalendarDays, formatISO, parseISO } from 'date-fns';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { ContainedButton, Page, TextButton } from '../../components';
import { loginRequest, pattern } from '../../constants';
import { isFieldError } from '../../helpers';
import { useCurrentProject } from '../../hooks';
import { AddEventPayload } from '../../models';
import { useOvermind } from '../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	title: Yup.string().required('This field is required.'),
	attendees: Yup.array(),
	startDate: Yup.string().required('This field is required.'),
	endDate: Yup.string().required('This field is required.'),
	body: Yup.string().required('This field is required.'),
});

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

interface Props {
	modalClose: () => void;
}

function AddScreen(props: Props) {
	const { modalClose } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const emailPattern = new RegExp(pattern.email);
	const query = useQuery();
	const startDate = query.get('startDate');
	const endDate = query.get('endDate');
	const isAllDay = JSON.parse(query.get('isAllDay') || 'false');
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});
	const [accessToken, setAccessToken] = useState<string>('');

	const {
		state: {
			event: { isLoading },
		},
		actions,
	} = useOvermind();

	const { currentProject } = useCurrentProject();

	const initialValues: AddEventPayload = {
		title: '',
		attendees: [],
		startDate: startDate || '',
		endDate: endDate || '',
		body: '',
		isAllDay: isAllDay,
	};

	useEffect(() => {
		if (account) {
			instance
				.acquireTokenSilent({
					...loginRequest,
					account,
				})
				.then(async (response) => {
					setAccessToken(response.accessToken);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	useEffect(() => {
		if (!currentProject) actions.project.get();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		modalClose();
	};

	const handleSubmit = async (values: AddEventPayload) => {
		await actions.event.create({
			accessToken,
			payload: values,
		});

		handleClose();
	};

	const validateEmail = (email: string) => {
		return emailPattern.test(email.toLowerCase());
	};

	return (
		<Dialog open onClose={handleClose} maxWidth="sm">
			<Page title="Add new event">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{({
						values,
						errors,
						touched,
						setFieldValue,
						handleChange,
						handleBlur,
						handleSubmit,
					}) => (
						<form noValidate onSubmit={handleSubmit} className={classes.form}>
							<TextField
								fullWidth
								label="Title"
								name="title"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.title}
								error={isFieldError(touched.title, errors.title)}
								helperText={touched.title && errors.title}
							/>

							<FormControl
								fullWidth
								error={
									touched.attendees &&
									errors.attendees &&
									errors.attendees.length > 0
										? true
										: false
								}
							>
								<Autocomplete
									freeSolo
									multiple
									selectOnFocus
									blurOnSelect
									disableClearable
									options={currentProject?.users || []}
									value={values.attendees}
									onChange={(event, newValue) => {
										setFieldValue('attendees', newValue);
									}}
									getOptionLabel={(option) => {
										if (typeof option === 'string') {
											const email = validateEmail(option);

											if (!email) {
												actions.alert.setAlert({
													type: 'error',
													message: t('general.validation.email.pattern'),
												});
											}

											return option;
										}

										return option.email;
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Invite attendees"
											placeholder="add attendee"
											error={
												touched.attendees && errors.attendees ? true : false
											}
											onKeyDown={(event: any) => {
												const value = event?.target.value;
												if (
													(event.keyCode === 13 ||
														event.keyCode === 32 ||
														event.keyCode === 188) &&
													value
												) {
													if (!validateEmail(value)) {
														actions.alert.setAlert({
															type: 'error',
															message: t('general.validation.email.pattern'),
														});

														return;
													}

													setFieldValue(
														'attendees',
														values.attendees.concat(value)
													);
												}
											}}
											onBlur={(event: any) => {
												const value = event?.target.value;
												if (!value) return;

												if (!validateEmail(value)) {
													actions.alert.setAlert({
														type: 'error',
														message: t('general.validation.email.pattern'),
													});

													return;
												}

												setFieldValue(
													'attendees',
													values.attendees.concat(value)
												);
											}}
										/>
									)}
								/>

								{errors.attendees && touched.attendees && (
									<FormHelperText error>{errors.attendees}</FormHelperText>
								)}
							</FormControl>

							<Grid
								container
								spacing={4}
								alignItems="center"
								style={{ marginBottom: 0 }}
							>
								<Grid item sm={12} md={4}>
									{values.isAllDay ? (
										<DatePicker
											name="startDate"
											label="Start date"
											value={parseISO(values.startDate)}
											onChange={(date) => {
												if (!date) return;

												setFieldValue(
													'startDate',
													formatISO(date, { representation: 'date' })
												);
											}}
											onBlur={handleBlur}
											error={isFieldError(touched.startDate, errors.startDate)}
											helperText={touched.startDate && errors.startDate}
										/>
									) : (
										<DateTimePicker
											ampm={false}
											name="startDate"
											label="Start date"
											format="MM/dd/yyyy hh:mm"
											value={parseISO(values.startDate)}
											onChange={(date) => {
												if (!date) return;

												setFieldValue('startDate', formatISO(date));

												if (
													differenceInCalendarDays(
														parseISO(values.endDate),
														date
													) < 0
												) {
													setFieldValue(
														'endDate',
														formatISO(date, { representation: 'date' })
													);
												}
											}}
											onBlur={handleBlur}
											error={isFieldError(touched.startDate, errors.startDate)}
											helperText={touched.startDate && errors.startDate}
										/>
									)}
								</Grid>
								<Grid item sm={12} md={4}>
									{values.isAllDay ? (
										<DatePicker
											name="endDate"
											label="End date"
											value={parseISO(values.endDate)}
											onChange={(date) => {
												if (!date) return;

												setFieldValue(
													'endDate',
													formatISO(date, { representation: 'date' })
												);
											}}
											minDate={values.startDate}
											onBlur={handleBlur}
											error={isFieldError(touched.endDate, errors.endDate)}
											helperText={touched.endDate && errors.endDate}
										/>
									) : (
										<DateTimePicker
											ampm={false}
											name="endDate"
											label="End date"
											format="MM/dd/yyyy HH:mm"
											value={parseISO(values.endDate)}
											onChange={(date) => {
												if (!date) return;

												setFieldValue('endDate', formatISO(date));
											}}
											onBlur={handleBlur}
											error={isFieldError(touched.endDate, errors.endDate)}
											helperText={touched.endDate && errors.endDate}
										/>
									)}
								</Grid>
								<Grid item sm={12} md={4}>
									<FormControlLabel
										control={
											<Switch
												checked={values.isAllDay}
												onChange={handleChange}
												name="isAllDay"
											/>
										}
										label="All day"
									/>
								</Grid>
							</Grid>

							<TextField
								fullWidth
								multiline
								rows={3}
								label="Body"
								name="body"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.body}
								error={isFieldError(touched.body, errors.body)}
								helperText={touched.body && errors.body}
							/>

							<DialogActions>
								<TextButton onClick={modalClose}>Cancel</TextButton>
								<ContainedButton type="submit" isLoading={isLoading}>
									Save
								</ContainedButton>
							</DialogActions>
						</form>
					)}
				</Formik>
			</Page>
		</Dialog>
	);
}

export default AddScreen;
