/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { AntTab, AntTabs, TabPanel } from '../../../../../components';
import { useCurrentProject } from '../../../../../hooks';
import { Ticket } from '../../../../../models';
import Comments from './Comments';
import Emails from './Emails';

interface Props {
	ticket: Ticket;
}

function Tabs(props: Props) {
	const { ticket } = props;

	const [tabValue, setTabValue] = useState(0);

	const { currentProject } = useCurrentProject();

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<>
			<Box mt={3} mb={1}>
				<Typography variant="h6">Activity</Typography>
			</Box>

			<Grid container alignItems="center">
				<Grid item>Show:</Grid>
				<Grid item>
					<AntTabs value={tabValue} onChange={handleChangeTab}>
						<AntTab label="Emails" />
						<AntTab label="Comments" />
					</AntTabs>
				</Grid>
			</Grid>

			<Box mt={2}>
				{ticket && (
					<>
						<TabPanel value={tabValue} index={0}>
							<Emails emails={ticket.emails} />
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							{currentProject && (
								<Comments
									ticketId={ticket.id}
									projectUsers={currentProject?.users!}
								/>
							)}
						</TabPanel>
					</>
				)}
			</Box>
		</>
	);
}

export default Tabs;
