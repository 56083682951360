import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import React from 'react';

const AntTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: 3,
			textTransform: 'none',
			minHeight: 24,
			fontWeight: theme.typography.fontWeightRegular,
			margin: theme.spacing(0, 1),
			padding: theme.spacing(0, 1),
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			color: theme.palette.common.white,
			background: theme.palette.primary.light,

			'&:hover': {
				opacity: 1,
				background: theme.palette.primary.light,
			},
			'&$selected': {
				background: theme.palette.primary.dark,
			},
			'&:focus': {
				background: theme.palette.primary.dark,
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
	label: string;
}

export default AntTab;
