import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grid,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { TicketStatus, TicketStatusCopy } from '../../../../constants';
import { useOvermind } from '../../../../overmind';
import {
	ticketStatusBackgroundColors,
	ticketStatusColors,
} from '../../../../utils/styles';

const useStyles = makeStyles(
	createStyles({
		hidden: {
			display: 'none',
		},
	})
);

interface Props {
	status: string;
}

function Status(props: Props) {
	const { status } = props;
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const { actions } = useOvermind();

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	const handleChangeStatus = (status: string) => {
		actions.ticket.update({
			status,
		});
		setOpen(false);
	};

	return (
		<Grid container direction="column">
			<Grid item xs={12}>
				<Typography>Status</Typography>
				<ButtonGroup
					disableElevation
					variant="contained"
					color="primary"
					ref={anchorRef}
					aria-label="split button"
				>
					<Button
						style={{
							background: ticketStatusBackgroundColors[status],
							color: ticketStatusColors[status],
							paddingLeft: 8,
							paddingRight: 8,
						}}
					>
						{TicketStatusCopy[status]}
					</Button>
					<Button
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label="select status"
						aria-haspopup="menu"
						onClick={handleToggle}
						style={{
							background: ticketStatusBackgroundColors[status],
							color: ticketStatusColors[status],
						}}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu">
										{Object.values(TicketStatus).map((option) => (
											<MenuItem
												key={option}
												disabled={option === status}
												selected={option === status}
												onClick={() => handleChangeStatus(option)}
												className={
													(status === TicketStatus.NEW &&
														option !== TicketStatus.WAITING &&
														option !== TicketStatus.REPLIED &&
														option !== TicketStatus.INVALID) ||
													(status === TicketStatus.ASSESSMENT &&
														option !== TicketStatus.NEW &&
														option !== TicketStatus.INVALID) ||
													(status === TicketStatus.INVOICE &&
														option !== TicketStatus.REPLIED &&
														option !== TicketStatus.DONE)
														? classes.hidden
														: ''
												}
											>
												{TicketStatusCopy[option]}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Grid>
		</Grid>
	);
}

export default Status;
