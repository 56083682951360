import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Page, PageTitle } from '../../components';
import { Client } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import Form from './components/Basic/Form';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

function AddScreen() {
	const classes = useStyles();
	const {
		state: {
			client: { currentClient, isLoading },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (currentClient && currentClient.id) {
			history.push(`/clients/${currentClient?.id}/basic`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentClient?.id]);

	const initialValues: Partial<Client> = {
		name: '',
		nationality: '',
		durationStay: '',
		crewMembers: '',
		status: '',
		invoiceAddress: '',
	};

	const handleSubmit = async (values: Partial<Client>, { setSubmitting }) => {
		await actions.client.create(values);

		setSubmitting(false);
	};

	return (
		<Page title="Add New Client">
			<PageTitle>Add New Client</PageTitle>

			<Grid container className={classes.form}>
				<Grid item md={4}>
					<Form
						initialValues={initialValues}
						onSubmit={handleSubmit}
						isLoading={isLoading}
					/>
				</Grid>
			</Grid>
		</Page>
	);
}

export default AddScreen;
