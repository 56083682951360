import i18n from 'i18next';
import { ERROR_401, DATA_NOT_FOUND } from '../constants';

class ErrorHelper {
	static generalFormat(errorCode: string | undefined) {
		switch (errorCode) {
			case ERROR_401:
				return i18n.t('general.error.httpRequest.e401');

			case DATA_NOT_FOUND:
				return i18n.t('general.error.dataNotFound');

			default:
				return i18n.t('general.error.somethingWentWrong');
		}
	}
}

export default ErrorHelper;
