/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'react-scrollbars-custom';
import { Page, PageTitle } from '../../components';
import { useMergeState, useWindowSize } from '../../hooks';
import { useOvermind } from '../../overmind';
import { Filters, Lists } from './components/projects';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			padding: theme.spacing(3, 3, 2),
		},
		lists: {
			display: 'flex',
		},
	})
);

const defaultFilters = {
	searchTerm: '',
	userIds: [],
	myOnly: false,
	recent: false,
};

const ListScreen = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [filters, mergeFilters] = useMergeState(defaultFilters);

	const {
		state: {
			project: { data },
		},
		actions,
	} = useOvermind();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [width, height] = useWindowSize();

	useEffect(() => {
		actions.project.get();

		const timer = setInterval(() => {
			actions.project.get();
		}, 180000); // 3minute

		return () => {
			clearInterval(timer);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page title={t('project.title')} disableGutters>
			{data ? (
				<>
					<Grid
						container
						alignItems="center"
						justify="space-between"
						className={classes.header}
					>
						<Grid item>
							<PageTitle>Overview</PageTitle>
						</Grid>
						<Grid item>
							<Filters
								projectUsers={data.users}
								defaultFilters={defaultFilters}
								filters={filters}
								mergeFilters={mergeFilters}
							/>
						</Grid>
					</Grid>

					<Scrollbar style={{ width: '100%', height: height - 145 }}>
						<div className={classes.lists}>
							<Lists project={data} filters={filters} />
						</div>
					</Scrollbar>
				</>
			) : (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					width={'100vw'}
					height={'100vh'}
				>
					<CircularProgress />
				</Box>
			)}
		</Page>
	);
};

export default ListScreen;
