/* eslint-disable react-hooks/exhaustive-deps */
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { isFieldError } from '../../../helpers';
import { Contact } from '../../../models';
import { useOvermind } from '../../../overmind';

interface Fields {
	clientId: string;
	contactId: string;
	contactName: string;
	contactPhone: string;
	contactEmail: string;
}

const ContactFields = () => {
	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
	} = useFormikContext<Fields>();

	const {
		state: {
			ticket: { currentTicket },
			client: { currentClient },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (!values.contactName) return;

		if (values.clientId) {
			const contactSelected =
				currentClient?.contacts &&
				currentClient?.contacts.find(
					(contact) => contact.name === values.contactName
				);

			const contactId = contactSelected?.id;
			const contactPhone = contactSelected?.phone;
			const contactEmail = contactSelected?.email;

			setFieldValue('contactId', contactId);
			setFieldValue('contactPhone', contactPhone);
			setFieldValue('contactEmail', contactEmail);
		}
	}, [values.contactName]);

	useEffect(() => {
		if (values.clientId) {
			actions.client.fetchContact(values.clientId);
			if (
				currentTicket?.vessel &&
				values.clientId === (currentTicket?.client && currentTicket?.client.id)
			) {
				setFieldValue('contactName', currentTicket?.contact.name);
				setFieldValue('contactPhone', currentTicket?.contact.phone);
				setFieldValue('contactEmail', currentTicket?.contact.email);
			} else {
				if (currentClient?.contacts) {
					setFieldValue(
						'contactName',
						currentClient?.contacts.length
							? currentClient?.contacts[0].name
							: ''
					);
					setFieldValue(
						'contactPhone',
						currentClient?.contacts.length
							? currentClient?.contacts[0].phone
							: ''
					);
					setFieldValue(
						'contactEmail',
						currentClient?.contacts.length
							? currentClient?.contacts[0].email
							: ''
					);
				}
			}
		} else {
			setFieldValue('contactName', '');
			setFieldValue('contactPhone', '');
			setFieldValue('contactEmail', '');
		}
	}, [values.clientId]);

	return (
		<>
			<Field type="hidden" name="contactId" value={values.contactId} />

			{values.clientId ? (
				<>
					{currentClient && (
						<FormControl fullWidth>
							<InputLabel id="contact-name-label">Contact Name</InputLabel>
							<Select
								labelId="contact-name-label"
								name="contactName"
								value={values.contactName}
								onChange={handleChange}
								onBlur={handleBlur}
							>
								{currentClient?.contacts &&
									currentClient?.contacts.map(
										(contact: Contact, index: number) => (
											<MenuItem key={index} value={contact.name}>
												{contact.name}
											</MenuItem>
										)
									)}
							</Select>

							{touched.contactName && errors.contactName && (
								<FormHelperText error>{errors.contactName}</FormHelperText>
							)}
						</FormControl>
					)}
				</>
			) : (
				<TextField
					label="Contact Name"
					name="contactName"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.contactName}
					error={isFieldError(touched.contactName, errors.contactName)}
					helperText={touched.contactName && errors.contactName}
				/>
			)}

			<TextField
				label="Contact Phone"
				name="contactPhone"
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.contactPhone}
				error={isFieldError(touched.contactPhone, errors.contactPhone)}
				helperText={touched.contactPhone && errors.contactPhone}
			/>

			<TextField
				label="Contact Email"
				name="contactEmail"
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.contactEmail}
				error={isFieldError(touched.contactEmail, errors.contactEmail)}
				helperText={touched.contactEmail && errors.contactEmail}
			/>
		</>
	);
};

export default ContactFields;
