import { InputAdornment } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from './TextField';

interface Props {
	icon?: string;
	value: string;
	onChange: (newValue: string) => void;
}

function InputDebounced(props: Props) {
	const { icon, value: propsValue, onChange, ...inputProps } = props;
	const [value, setValue] = useState(propsValue);
	const isControlled = propsValue !== undefined;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleChange = useCallback(
		debounce((newValue: string) => onChange(newValue), 500),
		[]
	);

	const valueRef = useRef(value);
	valueRef.current = value;

	useEffect(() => {
		if (propsValue !== valueRef.current) {
			setValue(propsValue);
		}
	}, [propsValue]);

	return (
		<TextField
			{...inputProps}
			variant="outlined"
			placeholder="Search"
			type="search"
			value={isControlled ? value : undefined}
			onChange={(newValue) => {
				setValue(newValue.target.value);
				handleChange(newValue.target.value);
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Icon>{icon}</Icon>
					</InputAdornment>
				),
			}}
		/>
	);
}

export default InputDebounced;
