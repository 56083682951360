/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Page, PageTitle } from '../../components';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import {
	Assignee,
	Attachments,
	Description,
	Details,
} from './components/details';
import { TableForm } from './components/quotations';
import { QuotationPayload } from './models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		breadcrumbs: {
			marginBottom: theme.spacing(2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: 'none',

			'&:hover': {
				color: theme.palette.primary.light,
			},
		},
	})
);

const CreateQuotationScreen = () => {
	const classes = useStyles();
	const { ticketId } = useParams<{
		ticketId: string;
	}>();

	const {
		state: {
			ticket: { currentTicket, isQuotationCreated },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (ticketId) {
			actions.ticket.getById(ticketId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketId]);

	useEffect(() => {
		if (isQuotationCreated) {
			clearStorage();

			actions.ticket.resetCreateQuotation();
			history.push({
				pathname: `/projects/ticket/${ticketId}`,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isQuotationCreated]);

	const handleSubmit = async (payload: QuotationPayload) => {
		await actions.ticket.createQuotation(payload);
	};

	const clearStorage = () => {
		localStorage.removeItem(`quotation-row-${ticketId}`);
	};

	return (
		<Page title="Create Quotation">
			<Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
				<Link to={`/projects`} className={classes.link}>
					Overview
				</Link>
				<Link to={`/projects/ticket/${ticketId}`} className={classes.link}>
					PRO - {ticketId}
				</Link>
				<Typography color="textPrimary">Create Quotation</Typography>
			</Breadcrumbs>

			<PageTitle gutterBottom>Create Quotation</PageTitle>

			<Grid container spacing={2}>
				<Grid item md={8}>
					<TableForm ticketId={ticketId} data={[]} onSubmit={handleSubmit} />
				</Grid>
				<Grid item md={4}>
					<Details />
					<Description ticket={currentTicket!} />
					<Assignee user={currentTicket?.user!} />
					<Attachments />
				</Grid>
			</Grid>
		</Page>
	);
};

export default CreateQuotationScreen;
