import {
	FormControl,
	FormHelperText,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { ContainedButton } from '..';
import { isFieldError } from '../../helpers';
import { useOvermind } from '../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			width: 400,
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	password: Yup.string().required('This field is required'),
	confirmPassword: Yup.string()
		.required('This field is required')
		.when('password', {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('password')],
				'Both password need to be the same'
			),
		}),
});

interface InitValues {
	password: string;
	confirmPassword: string;
}

interface Props {
	userId: number;
}

function ChangePassword(props: Props) {
	const { userId } = props;
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(
		false
	);

	const {
		state: {
			user: { isLoading },
		},
		actions,
	} = useOvermind();

	const initialValues: InitValues = {
		password: '',
		confirmPassword: '',
	};

	const handleSubmit = async (values: InitValues, { resetForm }) => {
		const payload = {
			password: values.password,
		};

		await actions.user.update({
			userId: userId.toString(),
			payload,
		});

		resetForm();
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				errors,
				touched,
			}) => (
				<form onSubmit={handleSubmit} className={classes.form}>
					<FormControl fullWidth>
						<InputLabel id="password-label">Password</InputLabel>
						<Input
							fullWidth
							id="password-label"
							name="password"
							placeholder="Password"
							onChange={handleChange}
							onBlur={handleBlur}
							error={isFieldError(touched.password, errors.password)}
							type={showPassword ? 'text' : 'password'}
							value={values.password}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={() => setShowPassword((prev) => !prev)}>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							style={{ width: 300 }}
						/>
						{touched.password && errors.password && (
							<FormHelperText error>{errors.password}</FormHelperText>
						)}
					</FormControl>

					<FormControl fullWidth>
						<InputLabel id="password-confirm-label">
							Confirm password
						</InputLabel>
						<Input
							fullWidth
							id="password-confirm-label"
							name="confirmPassword"
							placeholder="Confirm password"
							onChange={handleChange}
							onBlur={handleBlur}
							error={isFieldError(
								touched.confirmPassword,
								errors.confirmPassword
							)}
							type={showPasswordConfirm ? 'text' : 'password'}
							value={values.confirmPassword}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPasswordConfirm((prev) => !prev)}
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							style={{ width: 300 }}
						/>
						{touched.confirmPassword && errors.confirmPassword && (
							<FormHelperText error>{errors.confirmPassword}</FormHelperText>
						)}
					</FormControl>

					<ContainedButton type="submit" isLoading={isLoading}>
						Change password
					</ContainedButton>
				</form>
			)}
		</Formik>
	);
}

export default ChangePassword;
