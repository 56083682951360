import instance from '../../api/axios';

export const api = {
	async fetch() {
		try {
			const response = await instance.get(`/configuration`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async update(payload) {
		try {
			const response = await instance.put(`/configuration`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
