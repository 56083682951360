import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { ContainedButton, TextButton } from '../../../../components';
import { Ticket } from '../../../../models';
import { useOvermind } from '../../../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		summary: {
			marginTop: theme.spacing(2),
			padding: 0,
		},
		details: {
			flexDirection: 'column',
			padding: 0,
		},
		button: {
			'& > *': {
				margin: theme.spacing(1, 1, 0, 0),
			},
		},
	})
);

interface Props {
	ticket: Ticket;
	isLoading?: boolean;
}

function Description(props: Props) {
	const classes = useStyles();
	const { ticket, isLoading } = props;
	const [description, setDescription] = useState(ticket?.description);
	const [isEditing, setEditing] = useState(false);

	const { actions } = useOvermind();

	const handleSubmit = async () => {
		if (description) {
			await actions.ticket.update({
				description,
			});
		}

		setEditing(false);
	};

	return (
		<Accordion elevation={0} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				className={classes.summary}
			>
				<Typography variant="h6">
					{isLoading ? (
						<Skeleton variant="rect" width={300} height={24} animation="wave" />
					) : (
						'Description'
					)}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.details}>
				{isLoading ? (
					<>
						<Skeleton variant="text" width="80%" animation="wave" />
						<Skeleton variant="text" width="80%" animation="wave" />
						<Skeleton variant="text" width="80%" animation="wave" />
						<Skeleton variant="text" width="80%" animation="wave" />
						<Skeleton variant="text" width="80%" animation="wave" />
					</>
				) : (
					<>
						{isEditing ? (
							<>
								<ReactQuill
									theme="snow"
									defaultValue={ticket?.description}
									onChange={(value) => {
										if (value) setDescription(value);
									}}
								/>
								{/*
								{errors.body && touched.body && (
									<FormHelperText error>{errors.body}</FormHelperText>
								)} */}

								<div className={classes.button}>
									<ContainedButton onClick={handleSubmit}>Save</ContainedButton>
									<TextButton onClick={() => setEditing(false)}>
										Cancel
									</TextButton>
								</div>
							</>
						) : (
							<Box
								onClick={() => setEditing(true)}
								dangerouslySetInnerHTML={{
									__html: ticket?.description,
								}}
							/>
						)}
					</>
				)}
			</AccordionDetails>
		</Accordion>
	);
}

export default Description;
