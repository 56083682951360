/* eslint-disable react/display-name */
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useAccount,
	useMsal,
} from '@azure/msal-react';
import { Button, Checkbox, Dialog, IconButton } from '@material-ui/core';
import {
	Add as AddIcon,
	AttachFile as AttachFileIcon,
	Delete as DeleteIcon,
	Drafts as MailReadIcon,
	Mail as MailUnreadIcon,
} from '@material-ui/icons';
import { memoize } from 'lodash';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Route, useRouteMatch } from 'react-router-dom';
import { ComposeScreen } from '.';
import { ConnectMicrosoft } from '../../components/User';
import { loginRequest } from '../../constants';
import { DateHelper, MsGraph } from '../../helpers';
import { useWindowSize } from '../../hooks';
import history from '../../utils/history';
import { tableStyles } from '../../utils/styles';
import { LinearIndeterminate } from './components';
import { Email } from './models';

const columns = [
	{
		name: <AttachFileIcon />,
		cell: (row: Email) => <>{row.hasAttachments ? <AttachFileIcon /> : null}</>,
		width: '50px',
	},
	{
		name: <MailUnreadIcon />,
		cell: (row: Email) => (
			<>{row.isRead ? <MailReadIcon /> : <MailUnreadIcon />}</>
		),
		width: '50px',
	},
	{
		name: 'From',
		selector: 'sender.emailAddress.name',
		sortable: true,
		conditionalCellStyles: [
			{
				when: (row: Email) => !row.isRead,
				style: {
					fontWeight: 700,
				},
			},
		],
	},
	{
		name: 'Subject',
		selector: 'subject',
		sortable: true,
		grow: 4,
		conditionalCellStyles: [
			{
				when: (row: Email) => !row.isRead,
				style: {
					fontWeight: 700,
				},
			},
		],
	},
	{
		name: 'Recieved',
		selector: 'receivedDateTime',
		cell: (row: Email) => DateHelper.distanceToNowFormat(row.receivedDateTime),
		sortable: true,
		conditionalCellStyles: [
			{
				when: (row: Email) => !row.isRead,
				style: {
					fontWeight: 700,
				},
			},
		],
	},
];

const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
const actions = (
	<Button
		variant="contained"
		color="primary"
		startIcon={<AddIcon />}
		onClick={() => history.push('/mailbox/compose')}
	>
		New message
	</Button>
);
const contextActions = memoize((unreadHandler, deleteHandler) => (
	<>
		<IconButton onClick={unreadHandler}>
			<MailReadIcon />
		</IconButton>

		<IconButton onClick={deleteHandler}>
			<DeleteIcon />
		</IconButton>
	</>
));

function ListScreen() {
	const { path, url } = useRouteMatch();
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});
	const [data, setData] = useState<Email[]>([]);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [selectedRows, setSelectedRows] = useState<Array<Email>>([]);
	const [toggleCleared, setToggleCleared] = useState<boolean>(false);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [width, height] = useWindowSize();

	useEffect(() => {
		if (account) {
			instance
				.acquireTokenSilent({
					...loginRequest,
					account,
				})
				.then(async (response) => {
					await getEmails(response.accessToken);

					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	const getEmails = async (accessToken: string) => {
		const client = new MsGraph(accessToken);

		const emails = await client.getEmails(50);

		setData(emails.value);
	};

	const updateEmail = async (messageId, accessToken: string) => {
		const client = new MsGraph(accessToken);

		return await client.updateEmail(messageId, { isRead: true });
	};

	const deleteEmail = async (messageId, accessToken: string) => {
		const client = new MsGraph(accessToken);

		return await client.deleteEmail(messageId);
	};

	const handleRowSelected = (state) => {
		setSelectedRows(state.selectedRows);
	};

	const handleRowClicked = (row: any) => {
		history.push(`${url}/id/${row.id}`);
	};

	const handleUnreadAll = () => {
		const update = Promise.all(
			selectedRows.map(async (message: Email) => {
				if (account && !message.isRead) {
					instance
						.acquireTokenSilent({
							...loginRequest,
							account,
						})
						.then(async (response) => {
							await updateEmail(message.id, response.accessToken);
							await getEmails(response.accessToken);

							await Promise.resolve('ok');
						});
				}
			})
		);

		update.then(() => setToggleCleared(!toggleCleared));
	};

	const handleDeleteAll = () => {
		const remove = Promise.all(
			selectedRows.map(async (message: Email) => {
				if (account) {
					instance
						.acquireTokenSilent({
							...loginRequest,
							account,
						})
						.then(async (response) => {
							await deleteEmail(message.id, response.accessToken);
							await getEmails(response.accessToken);

							await Promise.resolve('ok');
						});
				}
			})
		);

		remove.then(() => setToggleCleared(!toggleCleared));
	};

	return (
		<>
			<UnauthenticatedTemplate>
				<ConnectMicrosoft description="To access your mailbox, connect your email." />
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				<DataTable
					customStyles={tableStyles}
					columns={columns}
					data={data}
					highlightOnHover
					pointerOnHover
					selectableRows
					selectableRowsHighlight
					selectableRowsComponentProps={selectProps}
					selectableRowsComponent={Checkbox}
					onSelectedRowsChange={handleRowSelected}
					actions={actions}
					contextActions={contextActions(handleUnreadAll, handleDeleteAll)}
					progressPending={isLoading}
					progressComponent={<LinearIndeterminate />}
					persistTableHead
					onRowClicked={handleRowClicked}
					clearSelectedRows={toggleCleared}
					fixedHeader
					fixedHeaderScrollHeight={`${height - 180}px`}
				/>

				<Route path={`${path}/compose`}>
					<Dialog
						open
						fullWidth
						maxWidth="sm"
						onClose={() => history.push(url)}
					>
						<ComposeScreen modalClose={() => history.push(url)} />
					</Dialog>
				</Route>
			</AuthenticatedTemplate>
		</>
	);
}

export default ListScreen;
