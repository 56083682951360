import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLang } from '../constants';
import en from './locales/en.json';
import nl from './locales/nl.json';

const currentLangCode = localStorage.getItem('atsemoLanguage')
	? localStorage.getItem('atsemoLanguage')
	: defaultLang;

i18n.use(initReactI18next).init({
	lng:
		currentLangCode && currentLangCode.length ? currentLangCode : defaultLang,
	resources: {
		en: { translation: en },
		nl: { translation: nl },
	},
	fallbackLng: false,
	debug: true,
	interpolation: {
		escapeValue: false,
		formatSeparator: ',',
	},
	react: {
		wait: true,
	},
});

export default i18n;
