import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { ProductCategory } from '../models';

interface Props {
	categories: ProductCategory[];
	onChange: (categoryId: string) => void;
}

function DropdownCategory(props: Props) {
	const { categories, onChange } = props;
	const [categoryId, setCategoryId] = useState<string>('');
	const [children, setChildren] = useState<ProductCategory[]>([]);

	const handleChange = (event: React.ChangeEvent<any>) => {
		const value = event.target.value;
		setCategoryId(value);
		const categorySelected = categories.find(
			(category: ProductCategory) => category.id.toString() === value
		);

		if (categorySelected?.childrens.length) {
			setChildren(categorySelected.childrens);
		}

		onChange(value);
	};

	const renderChild = (childrens: ProductCategory[]) => {
		return (
			<DropdownCategory
				categories={childrens}
				onChange={(categoryId: string) => {
					onChange(categoryId);
				}}
			/>
		);
	};

	return (
		<>
			<FormControl fullWidth>
				<InputLabel htmlFor="category">Category</InputLabel>
				<Select
					native
					value={categoryId}
					onChange={handleChange}
					inputProps={{
						name: 'category',
						id: 'category',
					}}
				>
					<option aria-label="None" value="" />
					{categories.map((category: ProductCategory) => (
						<option key={category.id} value={category.id}>
							{category.name}
						</option>
					))}
				</Select>
			</FormControl>

			{children.length > 0 && renderChild(children)}
		</>
	);
}

export default DropdownCategory;
