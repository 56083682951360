import {
	Avatar,
	Box,
	createStyles,
	Grid,
	makeStyles,
	Theme,
} from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { useCurrentUser } from '../../../../../../../hooks';
import { User } from '../../../../../../../models';
import { useOvermind } from '../../../../../../../overmind';
import BodyForm from '../BodyForm';
import ProTip from './ProTip';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		avatar: {
			width: 32,
			height: 32,
		},

		fakeTextArea: {
			padding: theme.spacing(1, 2),
			borderRadius: 4,
			border: `1px solid ${theme.palette.grey[400]}`,
			'&:hover': {
				border: `1px solid ${theme.palette.grey[400]}`,
			},
		},
	})
);

interface Props {
	ticketId: string;
	projectUsers: User[];
}

function Create(props: Props) {
	const { ticketId, projectUsers } = props;
	const classes = useStyles();

	const [isFormOpen, setFormOpen] = useState(false);
	const [isCreating, setCreating] = useState(false);
	const [body, setBody] = useState('');

	const { actions } = useOvermind();

	const { currentUser } = useCurrentUser();

	const users = projectUsers.map((user: any) => {
		user.display = user.name;

		return user;
	});

	const handleCommentCreate = async () => {
		setCreating(true);
		await actions.comment.create({
			body,
			ticketId: ticketId,
			userId: currentUser.id,
		});

		setFormOpen(false);
		setCreating(false);
		setBody('');
	};

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item>
					{currentUser.avatarUrl ? (
						<Avatar src={currentUser.avatarUrl} />
					) : (
						<Avatar>{currentUser.name.charAt(0)}</Avatar>
					)}
				</Grid>
				<Grid item sm={11}>
					{isFormOpen ? (
						<BodyForm
							value={body}
							onChange={(event, newValue) => setBody(newValue)}
							isWorking={isCreating}
							onSubmit={handleCommentCreate}
							onCancel={() => setFormOpen(false)}
							users={users}
						/>
					) : (
						<Fragment>
							<Box
								onClick={() => setFormOpen(true)}
								className={classes.fakeTextArea}
							>
								Add a comment...
							</Box>
							<ProTip setFormOpen={setFormOpen} />
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Box>
	);
}

export default Create;
