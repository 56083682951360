/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	createStyles,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { differenceInDays, parseISO } from 'date-fns';
import React from 'react';
import { Status } from '.';
import { useOvermind } from '../../../../overmind';

const useStyles = makeStyles(
	createStyles({
		summary: {
			padding: 0,
		},
		details: {
			flexDirection: 'column',
			padding: 0,
		},
		list: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	})
);

function Details() {
	const classes = useStyles();

	const {
		state: {
			ticket: { currentTicket, isLoading },
		},
	} = useOvermind();

	return (
		<div>
			<Accordion
				elevation={0}
				defaultExpanded={true}
				TransitionProps={{ unmountOnExit: true }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					className={classes.summary}
				>
					<Typography variant="h6">
						{isLoading ? (
							<Skeleton
								variant="rect"
								width={300}
								height={24}
								animation="wave"
							/>
						) : (
							'Details'
						)}
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Grid container>
						<Grid item xs={6}>
							<List
								classes={{
									root: classes.list,
								}}
							>
								<ListItem
									disableGutters={true}
									classes={{
										root: classes.list,
									}}
								>
									<ListItemText
										primary={
											isLoading ? (
												<Skeleton variant="text" width="60%" animation="wave" />
											) : (
												'Client Name'
											)
										}
										secondary={
											isLoading ? (
												<Skeleton variant="text" width="35%" animation="wave" />
											) : currentTicket?.client ? (
												currentTicket?.client?.name
											) : (
												'-'
											)
										}
									/>
								</ListItem>

								<ListItem
									disableGutters={true}
									classes={{
										root: classes.list,
									}}
								>
									<ListItemText
										primary={
											isLoading ? (
												<Skeleton variant="text" width="60%" animation="wave" />
											) : (
												'Vessel Name'
											)
										}
										secondary={
											isLoading ? (
												<Skeleton variant="text" width="35%" animation="wave" />
											) : currentTicket?.vessel ? (
												currentTicket?.vessel.name
											) : (
												'-'
											)
										}
									/>
								</ListItem>
								<ListItem
									disableGutters={true}
									classes={{
										root: classes.list,
									}}
								>
									<ListItemText
										primary={
											isLoading ? (
												<Skeleton variant="text" width="60%" animation="wave" />
											) : (
												'Vessel Type'
											)
										}
										secondary={
											isLoading ? (
												<Skeleton variant="text" width="35%" animation="wave" />
											) : currentTicket?.vessel ? (
												currentTicket?.vessel.type
											) : (
												'-'
											)
										}
									/>
								</ListItem>
							</List>
						</Grid>
						<Grid item xs={6}>
							<List
								classes={{
									root: classes.list,
								}}
							>
								<ListItem
									disableGutters={true}
									classes={{
										root: classes.list,
									}}
								>
									<ListItemText
										primary={
											isLoading ? (
												<Skeleton variant="text" width="60%" animation="wave" />
											) : (
												'Contact Person '
											)
										}
										secondary={
											isLoading ? (
												<Skeleton variant="text" width="35%" animation="wave" />
											) : currentTicket?.contact ? (
												currentTicket?.contact.name
											) : (
												'-'
											)
										}
									/>
								</ListItem>

								<ListItem
									disableGutters={true}
									classes={{
										root: classes.list,
									}}
								>
									<ListItemText
										primary={
											isLoading ? (
												<Skeleton variant="text" width="60%" animation="wave" />
											) : (
												'Days on board'
											)
										}
										secondary={
											isLoading ? (
												<Skeleton variant="text" width="35%" animation="wave" />
											) : (
												`${differenceInDays(
													new Date(),
													parseISO(currentTicket?.createdAt!)
												)} days`
											)
										}
									/>
								</ListItem>
								{!isLoading && (
									<ListItem disableGutters>
										<Status status={currentTicket?.status!} />
									</ListItem>
								)}
							</List>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default Details;
