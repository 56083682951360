import { User } from '../../models';

export interface UserState {
	isLoggedIn: boolean;
	isLoading: boolean;
	user: User;
	accessToken: string;
}

export const state: UserState = {
	isLoggedIn: false,
	isLoading: false,
	user: {
		id: 0,
		name: '',
		position: '',
		username: '',
		prefix: '',
		email: '',
		phone: '',
		sex: 'male',
		birthDay: '',
		role: '',
		isBanned: false,
		avatarUrl: '',
		createdAt: '',
		updatedAt: '',
		projectId: 0,
	},
	accessToken: '',
};
