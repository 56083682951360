import { Theme, withStyles } from '@material-ui/core/styles';
import MUITextField from '@material-ui/core/TextField';

const TextField = withStyles((theme: Theme) => ({
	root: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: theme.palette.grey[100],
			'& fieldset': {
				borderColor: theme.palette.grey[300],
			},
			'&:hover fieldset': {},
			'&.Mui-focused fieldset': {
				background: 'none',
			},
		},
		'& .MuiOutlinedInput-input': {
			paddingTop: 10,
			paddingBottom: 10,
		},
	},
}))(MUITextField);

export default TextField;
