import { Box, Button, Icon, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Page, PageTitle, TextField } from '../../components';
import DateHelper from '../../helpers/date';
import { User } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { tableStyles } from '../../utils/styles';
import { LinearIndeterminate } from '../mails/components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			padding: theme.spacing(3, 0, 0),
		},
		action: {
			'& > *': {
				marginLeft: theme.spacing(1),
			},
		},
		dropzone: {
			marginBottom: theme.spacing(2),
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

const ButtonDelete = (props: any) => {
	const { row } = props;
	const { actions } = useOvermind();

	const handleClick = () => {
		actions.user.remove(row.id);
	};

	return (
		<IconButton onClick={handleClick}>
			<Icon>delete</Icon>
		</IconButton>
	);
};

const columns = [
	{
		name: 'Name',
		selector: 'name',
		sortable: true,
	},
	{
		name: 'Email',
		selector: 'email',
	},
	{
		name: 'Role',
		selector: 'role',
		format: (row) => startCase(row.role),
	},
	{
		name: 'Created',
		selector: 'createdAt',
		format: (row) => DateHelper.distanceToNowFormat(row.createdAt),
		sortable: true,
	},
	{
		name: 'Modified',
		selector: 'updatedAt',
		format: (row) => DateHelper.distanceToNowFormat(row.updatedAt),
		sortable: true,
	},
	{
		// eslint-disable-next-line react/display-name
		cell: (row) => <ButtonDelete row={row} />,
		name: '',
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
];

function ListScreen() {
	const classes = useStyles();

	const {
		state: {
			user: { data, isLoading },
		},
		actions,
	} = useOvermind();

	const [filterText, setFilterText] = useState('');
	const filteredItems = data.filter(
		(user: User) =>
			user.name && user.name.toLowerCase().includes(filterText.toLowerCase())
	);

	useEffect(() => {
		actions.user.fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRowClicked = (row: User) => {
		history.push(`/users/${row.id}/edit`);
	};

	return (
		<Page title="Users">
			<PageTitle>Users</PageTitle>

			<Box paddingX={2} paddingY={3}>
				<Box
					display="flex"
					justifyContent="flex-end"
					className={classes.action}
				>
					<TextField
						variant="outlined"
						placeholder="Search"
						type="search"
						value={filterText}
						onChange={(e) => setFilterText(e.target.value)}
					/>

					<Button
						variant="contained"
						color="inherit"
						startIcon={<AddIcon />}
						onClick={() => history.push('/users/new')}
					>
						New
					</Button>
				</Box>

				<DataTable
					customStyles={tableStyles}
					columns={columns}
					data={filteredItems}
					pagination
					highlightOnHover
					pointerOnHover
					noHeader
					progressPending={isLoading}
					progressComponent={<LinearIndeterminate />}
					persistTableHead
					className={classes.table}
					onRowClicked={handleRowClicked}
				/>
			</Box>
		</Page>
	);
}

export default ListScreen;
