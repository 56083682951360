import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { DateHelper } from '../../../../helpers';
import { Invoice } from '../../../../models';
import { useOvermind } from '../../../../overmind';
import { tableStyles } from '../../../../utils/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			padding: theme.spacing(3, 0, 0),
		},
		search: {
			marginRight: theme.spacing(2),
		},
		dropzone: {
			marginBottom: theme.spacing(2),
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

const columns = [
	{
		name: 'Created',
		cell: (row: Invoice) => DateHelper.generalFormat(row.createdAt),
	},
	{
		name: 'Number',
		selector: 'number',
	},
	{
		name: 'Value',
		selector: 'value',
	},
	{
		name: 'Status',
		sortable: true,
		cell: (row: Invoice) => (row.payed === 'yes' ? 'Payed' : 'Not Payed'),
	},
];

function Invoices() {
	const classes = useStyles();

	const { state, actions } = useOvermind();

	useEffect(() => {
		actions.client.getInvoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRowClicked = (row: Invoice) => {
		window.open(
			`${process.env.REACT_APP_AWS_S3_URL}/${row.filename}`,
			'_blank'
		);
	};

	return (
		<DataTable
			customStyles={tableStyles}
			columns={columns}
			data={state.client.invoices || []}
			pagination
			highlightOnHover
			pointerOnHover
			noHeader
			className={classes.table}
			onRowClicked={handleRowClicked}
		/>
	);
}

export default Invoices;
