export interface AlertState {
	isOpen: boolean;
	type: 'success' | 'warning' | 'error' | 'info';
	message: string;
}

export const state: AlertState = {
	isOpen: false,
	type: 'success',
	message: '',
};
