import instance from '../../api/axios';
import { Ticket } from '../../models';

export const api = {
	async getById(ticketId: string) {
		try {
			const response = await instance.get<Ticket>(`/tickets/${ticketId}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async update(ticketId: string, payload) {
		try {
			const response = await instance.put(`/tickets/${ticketId}`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async createQuotation(ticketId, payload) {
		try {
			const response = await instance.post(
				`/tickets/${ticketId}/quotation`,
				payload
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async updateQuotation(ticketId, quotationId, payload) {
		try {
			const response = await instance.put(
				`/tickets/${ticketId}/quotation/${quotationId}`,
				payload
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async createInvoice(ticketId) {
		try {
			const response = await instance.post(`/tickets/${ticketId}/invoice`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async createPO(ticketId, payload) {
		try {
			const response = await instance.post(`/tickets/${ticketId}/po`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async createDeliveryNote(ticketId) {
		try {
			const response = await instance.post(`/tickets/${ticketId}/shopping`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async sendEmail(ticketId, payload) {
		try {
			const response = await instance.post(
				`/tickets/${ticketId}/send-email`,
				payload,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async uploadDocuments(ticketId, payload) {
		try {
			const response = await instance.post(
				`/tickets/${ticketId}/documents`,
				payload,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async updateDocuments(ticketId, attachmentId, payload) {
		try {
			const response = await instance.put(
				`/tickets/${ticketId}/documents/${attachmentId}`,
				payload
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async remove(ticketId) {
		try {
			const response = await instance.delete(`/tickets/${ticketId}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async removeDocument(ticketId, attachmentId, fileName) {
		try {
			const response = await instance.delete(
				`/tickets/${ticketId}/documents/${attachmentId}/${fileName}`
			);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
