import { Product } from '../../models';

export const fetch = async ({ effects, actions, state }) => {
	state.product.isLoading = true;
	try {
		const data = await effects.product.api.fetch();
		state.product.data = data;
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isLoading = false;
};

export const fetchCategories = async ({ effects, actions, state }) => {
	state.product.isLoading = true;
	try {
		const categories = await effects.product.api.fetchCategories();
		state.product.categories = categories;
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isLoading = false;
};

export const getById = async (
	{ effects, actions, state },
	productId: string
) => {
	state.product.isLoading = true;

	try {
		const response = await effects.product.api.getById(productId);

		state.product = {
			...state.product,
			currentProduct: { ...state.product.currentProduct, ...response },
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isLoading = false;
};

export const create = async (
	{ effects, actions, state },
	payload: Partial<Product>
) => {
	state.product.isLoading = true;

	try {
		const product = await effects.product.api.create(payload);

		state.product.data = [...state.product.data, product];
		state.product.currentProduct = product;
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isLoading = false;
};

export const update = async (
	{ effects, actions, state },
	payload: Partial<Product>
) => {
	state.product.isEditing = true;

	try {
		const response = await effects.product.api.update(
			state.product.currentProduct.id,
			payload
		);

		const data = state.product.data.map((product: Product) =>
			product.id === response.id ? { ...product, ...response } : product
		);
		state.product.data = data;
		state.product.currentProduct = {
			...state.product.currentProduct,
			...response,
		};

		actions.alert.setAlert({
			type: 'success',
			message: `${response.name} updated succesfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isEditing = false;
};

export const accept = async (
	{ effects, actions, state },
	productId: string
) => {
	state.product.isEditing = true;

	try {
		const response = await effects.product.api.update(productId, {
			isAccepted: true,
		});

		const data = state.product.data.map((product: Product) =>
			product.id === response.id ? { ...product, ...response } : product
		);
		state.product.data = data;
		state.product.currentProduct = {
			...state.product.currentProduct,
			...response,
		};

		actions.alert.setAlert({
			type: 'success',
			message: `${response.name} has been accepted.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isEditing = false;
};

export const remove = async ({ effects, actions, state }, id: string) => {
	state.product.isDeleting = true;

	try {
		const response = await effects.product.api.remove(id);

		state.product.data = state.product.data.filter((item) => item.id !== id);
		actions.alert.setAlert({
			type: 'success',
			message: `${response.name} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isDeleting = false;
};

export const importProducts = async (
	{ effects, actions, state },
	payload: FormData
) => {
	state.product.isLoading = true;

	try {
		const data = await effects.product.api.import(payload);

		state.product = { data };

		actions.alert.setAlert({
			type: 'success',
			message: 'Products succesfully imported.',
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.product.isLoading = false;
};
