import instance from '../../api/axios';

export const api = {
	async fetch() {
		try {
			const response = await instance.get(`/clients`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async fetchVessel(clientId: string) {
		try {
			const response = await instance.get(`/clients/${clientId}/vessels`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async fetchContact(clientId: string) {
		try {
			const response = await instance.get(`/clients/${clientId}/contacts`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async getById(clientId: string) {
		try {
			const response = await instance.get(`/clients/${clientId}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async getInvoices(clientId: string) {
		try {
			const response = await instance.get(`/clients/${clientId}/invoices`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async create(payload) {
		try {
			const response = await instance.post(`/clients`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async createVessel(clientId, payload) {
		try {
			const response = await instance.post(
				`/clients/${clientId}/vessels`,
				payload
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async createContact(clientId, payload) {
		try {
			const response = await instance.post(
				`/clients/${clientId}/contacts`,
				payload
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async update(clientId, payload) {
		try {
			const response = await instance.put(`/clients/${clientId}`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async updateVessel(clientId, payload) {
		try {
			const response = await instance.put(
				`/clients/${clientId}/vessels/${payload.id}`,
				payload
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async updateContact(clientId, payload) {
		try {
			const response = await instance.put(
				`/clients/${clientId}/contacts/${payload.id}`,
				payload
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async remove(clientId) {
		try {
			const response = await instance.delete(`/clients/${clientId}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async removeVessel(clientId, vesselId) {
		try {
			const response = await instance.delete(
				`/clients/${clientId}/vessels/${vesselId}`
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async removeContact(clientId, contactId) {
		try {
			const response = await instance.delete(
				`/clients/${clientId}/contacts/${contactId}`
			);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async import(payload) {
		try {
			const response = await instance.post(`/clients/imports`, payload, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			});

			return response;
		} catch (error) {
			throw error;
		}
	},
};
