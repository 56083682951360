import { Product, ProductCategory } from '../../models';

export type ProductState = {
	isLoading: boolean;
	isDeleting: boolean;
	data: Product[];
	currentProduct: Product | null;
	categories: ProductCategory[];
};

export const state: ProductState = {
	isLoading: false,
	isDeleting: false,
	data: [],
	currentProduct: null,
	categories: [],
};
