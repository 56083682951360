import React from 'react';
import { Client } from '../../../../models';
import { useOvermind } from '../../../../overmind';
import history from '../../../../utils/history';
import Form from './Form';

interface Props {
	client: Client;
	isEditing: boolean;
	isDeleting: boolean;
}

function Basic(props: Props) {
	const { client, isEditing, isDeleting } = props;

	const { actions } = useOvermind();

	const initialValues: Partial<Client> = {
		name: client?.name || '',
		nationality: client?.nationality || '',
		durationStay: client?.durationStay || '',
		crewMembers: client?.crewMembers || '',
		status: client?.status || '',
		invoiceAddress: client?.invoiceAddress || '',
	};

	const handleSubmit = async (values: Partial<Client>, { setSubmitting }) => {
		await actions.client.update(values);

		setSubmitting(false);
	};

	const handleDelete = async () => {
		await actions.client.remove(client.id);

		history.push('/clients');
	};

	return (
		<Form
			initialValues={initialValues}
			onSubmit={handleSubmit}
			onDelete={handleDelete}
			isLoading={isEditing}
			isDeleting={isDeleting}
			isEdit
		/>
	);
}

export default Basic;
