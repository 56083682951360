import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const RedButton = withStyles({
	root: {
		color: '#ffffff',
		backgroundColor: '#b2102f',
		'&:hover': {
			backgroundColor: '#b2102f',
		},
	},
	disabled: {
		backgroundColor: '#d3d3d3',
	},
})(Button);

export default RedButton;
