import { IConfig } from 'overmind';
import { createHook } from 'overmind-react';
import { merge, namespaced } from 'overmind/config';
import * as alert from './alert';
import * as authentication from './authentication';
import * as client from './client';
import * as comment from './comment';
import * as configuration from './configuration';
import * as product from './product';
import * as project from './project';
import * as event from './event';
import * as template from './template';
import * as ticket from './ticket';
import * as user from './user';
import * as vessel from './vessel';

export const config = merge(
	{
		state: {
			isLoading: false,
		},
	},
	namespaced({
		alert,
		authentication,
		configuration,
		project,
		ticket,
		client,
		product,
		comment,
		template,
		user,
		vessel,
		event,
	})
);

declare module 'overmind' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface Config extends IConfig<typeof config> {}
}

export const useOvermind = createHook<typeof config>();
