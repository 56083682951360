import { Template } from '../../models';

type TemplateState = {
	isLoading: boolean;
	data: Template[];
};

export const state: TemplateState = {
	isLoading: false,
	data: [],
};
