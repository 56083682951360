import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Helmet } from 'react-helmet';
import { appName } from '../constants';
import { getPageTitle } from '../helpers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		gutters: {
			padding: theme.spacing(3, 3, 2),
		},
	})
);

interface Props {
	title?: string;
	children: React.ReactNode;
	disableGutters?: boolean;
}

const Page: ForwardRefRenderFunction<HTMLDivElement, Props> = (
	props: Props,
	ref
) => {
	const { title, children, disableGutters, ...rest } = props;
	const classes = useStyles();

	return (
		<div ref={ref} className={!disableGutters ? classes.gutters : ''} {...rest}>
			<Helmet>
				<title>{title ? getPageTitle(title) : appName}</title>
			</Helmet>
			{children}
		</div>
	);
};

export default forwardRef(Page);
