/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { json } from 'overmind';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Page, PageTitle } from '../../components';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import {
	Assignee,
	Attachments,
	Description,
	Details,
} from './components/details';
import { TableForm } from './components/quotations';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		breadcrumbs: {
			marginBottom: theme.spacing(2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: 'none',

			'&:hover': {
				color: theme.palette.primary.light,
			},
		},
	})
);

const EditQuotation = () => {
	const classes = useStyles();
	const { ticketId } = useParams<{
		ticketId: string;
	}>();
	const {
		state: {
			ticket: { currentTicket },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (ticketId) {
			actions.ticket.getById(ticketId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketId]);

	const handleSubmit = async (payload) => {
		await actions.ticket.updateQuotation(payload);

		clearStorage();

		history.push({
			pathname: `/projects/ticket/${ticketId}`,
		});
	};

	const clearStorage = () => {
		localStorage.removeItem(`quotation-row-${ticketId}`);
	};

	return (
		<Page title="Edit Quotation">
			<Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
				<Link to={`/projects`} className={classes.link}>
					Overview
				</Link>
				<Link to={`/projects/ticket/${ticketId}`} className={classes.link}>
					PRO - {ticketId}
				</Link>
				<Typography color="textPrimary">Edit Quotation</Typography>
			</Breadcrumbs>

			<PageTitle gutterBottom>Edit Quotation</PageTitle>

			<Grid container spacing={3}>
				<Grid item md={8}>
					<TableForm
						ticketId={ticketId}
						data={json(currentTicket?.quotation.products) || []}
						onSubmit={handleSubmit}
						isEdit
					/>
				</Grid>
				<Grid item md={4}>
					<Details />
					<Description ticket={currentTicket!} />
					<Assignee user={currentTicket?.user!} />
					<Attachments />
				</Grid>
			</Grid>
		</Page>
	);
};

export default EditQuotation;
