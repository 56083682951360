import { User } from '../../models';

export const fetch = async ({ effects, actions, state }) => {
	state.user.isLoading = true;

	try {
		const users = await effects.user.api.fetch();

		state.user = { data: users };
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.user.isLoading = false;
};

export const getById = async ({ effects, actions, state }, id: string) => {
	state.user.isLoading = true;

	try {
		const user = await effects.user.api.getById(id);

		state.user = {
			data: [user],
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.user.isLoading = false;
};

export const create = async (
	{ effects, actions, state },
	payload: {
		name: string;
		email: string;
		role: string;
		password?: string;
	}
) => {
	state.user.isLoading = true;

	try {
		const users = await effects.user.api.create(payload);

		state.user = {
			data: [...state.user.data, users],
		};
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.user.isLoading = false;
};

export const update = async (
	{ effects, actions, state },
	data: {
		userId: string;
		payload: Partial<User>;
	}
) => {
	state.user.isLoading = true;

	try {
		const user = await effects.user.api.update(data.userId, data.payload);

		if (data.userId === state.authentication.user.id.toString()) {
			state.authentication.user = { ...state.authentication.user, ...user };

			actions.alert.setAlert({
				type: 'success',
				message: 'Your account successfully updated.',
			});
		} else {
			state.user = {
				data: state.user.data.map((item) =>
					item.id === user.id ? { ...item, ...user } : item
				),
			};

			actions.alert.setAlert({
				type: 'success',
				message: 'User successfully updated.',
			});
		}
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.user.isLoading = false;
};

export const remove = async ({ effects, actions, state }, userId: string) => {
	state.user.isLoading = true;

	try {
		const response = await effects.user.api.remove(userId);

		const data = state.user.data.filter((item) => item.id !== userId);

		state.user.data = data;

		actions.alert.setAlert({
			type: 'success',
			message: `${response.name} deleted successfully.`,
		});
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.user.isLoading = false;
};

export const uploadAvatar = async (
	{ effects, actions, state },
	data: {
		userId: string;
		payload: FormData;
	}
) => {
	state.user.isLoading = true;

	try {
		const user = await effects.user.api.uploadAvatar(data.userId, data.payload);

		if (data.userId === state.authentication.user.id.toString()) {
			state.authentication.user = { ...state.authentication.user, ...user };

			actions.alert.setAlert({
				type: 'success',
				message: 'Your account successfully updated.',
			});
		} else {
			state.user = {
				data: state.user.data.map((item) =>
					item.id === user.id ? { ...item, ...user } : item
				),
			};

			actions.alert.setAlert({
				type: 'success',
				message: 'User successfully updated.',
			});
		}
	} catch (error) {
		actions.alert.setAlert({ type: 'error', message: error.message });
	}

	state.user.isLoading = false;
};
