import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

const CheckboxFormik = (props: any) => {
	const { field, form, label, onChange, ...rest } = props;
	const { name, value: formikValue } = field;
	const { setFieldValue } = form;

	const handleChange = () => {
		if (onChange) {
			onChange(formikValue, rest.value);
		} else {
			const values = formikValue || [];
			const index = values.indexOf(rest.value);
			if (index === -1) {
				values.push(rest.value);
			} else {
				values.splice(index, 1);
			}
			setFieldValue(name, values);
		}
	};

	return (
		<FormControlLabel
			control={<Checkbox onChange={handleChange} color="primary" />}
			label={label}
			checked={formikValue.indexOf(rest.value) !== -1}
			{...rest}
		/>
	);
};

export default CheckboxFormik;
