/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormGroup,
	FormHelperText,
	FormLabel,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import * as Yup from 'yup';
import {
	CheckboxFormik,
	ContainedButton,
	Page,
	TextButton,
} from '../../components';
import { useCurrentProject } from '../../hooks';
import { useOvermind } from '../../overmind';

interface InitValues {
	companyName: string;
	products: any[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogTitle: {
			paddingBottom: 0,
		},
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	companyName: Yup.string().required('This field is required.'),
	products: Yup.array().min(1, 'This field is required.'),
});

interface Props {
	modalClose: () => void;
}

function CreatePOScreen(props: Props) {
	const { modalClose } = props;
	const classes = useStyles();
	const formikRef = useRef<FormikProps<any>>(null);

	const companies = [
		'Kooyman',
		'Caribbean Fasteners',
		'Catis N.V.',
		'Napa',
		'Western Tire',
		'- blank -',
	];

	const {
		state: {
			ticket: { currentTicket, isLoadingPO, isPOCreated },
		},
		actions,
	} = useOvermind();

	const { currentProject } = useCurrentProject();

	const initialValues: InitValues = {
		companyName: '',
		products: [],
	};

	useEffect(() => {
		if (!currentProject) actions.project.get();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isPOCreated) {
			formikRef.current?.resetForm({
				values: { companyName: '', products: [] },
			});
			actions.ticket.resetCreatePO();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPOCreated]);

	const handleClose = () => {
		modalClose();
	};

	const handleSubmit = async (values: InitValues) => {
		const totalPrice = values.products.reduce(
			(n, item) => n + item.amountPrice,
			0
		);

		const payload = {
			...values,
			totalPrice,
		};

		await actions.ticket.createPO(payload);
	};

	return (
		<Page title="Create PO">
			<Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
				<DialogTitle disableTypography className={classes.dialogTitle}>
					<Typography variant="h5">Create PO</Typography>
				</DialogTitle>

				<DialogContent>
					<Formik
						innerRef={formikRef}
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, setFieldValue }) => (
							<form noValidate className={classes.form}>
								<FormControl fullWidth>
									<InputLabel id="select-company-name-label">
										Company name
									</InputLabel>
									<Select
										labelId="select-company-name-label"
										value={values.companyName}
										label="Company name"
										onChange={(
											event: React.ChangeEvent<{ value: unknown }>
										) => {
											const companyName = event.target.value as string;

											setFieldValue('companyName', companyName);
										}}
									>
										{companies.map((company, index) => (
											<MenuItem key={index} value={company}>
												{company}
											</MenuItem>
										))}
									</Select>

									{errors.companyName && touched.companyName && (
										<FormHelperText error>{errors.companyName}</FormHelperText>
									)}
								</FormControl>

								{values.companyName && (
									<FormControl fullWidth>
										<FormLabel component="legend">Choose products</FormLabel>
										<FormGroup>
											<Scrollbar style={{ width: '100%', height: 145 }}>
												{currentTicket?.quotation.products.map((item: any) => (
													<Box key={item.product.id}>
														<Field
															component={CheckboxFormik}
															name="products"
															value={item}
															label={item.product.name}
														/>
													</Box>
												))}
											</Scrollbar>
										</FormGroup>

										{errors.products && touched.products && (
											<FormHelperText error>{errors.products}</FormHelperText>
										)}
									</FormControl>
								)}
							</form>
						)}
					</Formik>
				</DialogContent>

				<DialogActions>
					<TextButton onClick={handleClose}>Cancel</TextButton>

					<ContainedButton
						onClick={() => {
							formikRef.current?.submitForm();
						}}
						isLoading={isLoadingPO}
						disabled={isLoadingPO}
					>
						Create PO
					</ContainedButton>
				</DialogActions>
			</Dialog>
		</Page>
	);
}

export default CreatePOScreen;
