import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { PageTitle } from '../../../../components';
import { ClientVessel } from '../../../../models';
import { useOvermind } from '../../../../overmind';
import history from '../../../../utils/history';
import Form from './Form';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

const initialValues: Partial<ClientVessel> = {
	name: '',
	type: '',
};

function Add() {
	const classes = useStyles();
	const {
		state: {
			client: { currentClient, isLoading },
		},
		actions,
	} = useOvermind();

	const handleSubmit = async (values: Partial<ClientVessel>) => {
		await actions.client.createVessel(values);

		history.push(`/clients/${currentClient?.id}/vessels`);
	};

	return (
		<>
			<PageTitle>Add new vessel</PageTitle>

			<Grid container className={classes.form}>
				<Grid item xs={8}>
					<Form
						initialValues={initialValues}
						onSubmit={handleSubmit}
						isLoading={isLoading}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default Add;
