import { Project } from '../../models';

export interface ProjectState {
	isLoading: boolean;
	data: Project | null;
}

export const state: ProjectState = {
	isLoading: true,
	data: null,
};
