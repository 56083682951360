import { User } from '../../models';

type UserState = {
	isLoading: boolean;
	data: User[];
};

export const state: UserState = {
	isLoading: false,
	data: [],
};
