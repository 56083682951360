import {
	Box,
	Button,
	CircularProgress,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core';
import {
	AirportShuttle as AirportShuttleIcon,
	Assignment as AssignmentIcon,
	CloudUpload as UploadIcon,
	Edit as EditIcon,
	MoodBad as MoodBadIcon,
	Send as SendIcon,
	Receipt as InvoiceIcon,
	ShoppingBasket as POIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ContainedButton, RedButton } from '../../../../components';
import { TicketStatus, UserRole } from '../../../../constants';
import { useCurrentUser } from '../../../../hooks';
import { useOvermind } from '../../../../overmind';
import history from '../../../../utils/history';
import UploadDocuments from './UploadDocuments';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		actions: {
			'& > *': {
				marginLeft: theme.spacing(1),
			},
		},
	})
);

function Actions() {
	const classes = useStyles();
	const { url } = useRouteMatch();

	const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);

	const { currentUser } = useCurrentUser();

	const {
		state: {
			ticket: {
				isUploading,
				isLoadingDeliveryNote,
				isLoadingInvoice,
				isInvoiceCreated,
				currentTicket,
			},
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (isInvoiceCreated) history.push(`${url}/send-email`);

		return () => {
			if (isInvoiceCreated) {
				actions.ticket.resetCreateInvoice();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInvoiceCreated]);

	const onEdit = async () => {
		history.push(`${url}/edit`);
	};

	const onCreateDelivery = async () => {
		await actions.ticket.createDeliveryNote();
	};

	const onUploadDocuments = async () => {
		setOpenUploadDialog((prev) => !prev);
	};

	const onCreateInvoice = async () => {
		await actions.ticket.createInvoice();
	};

	const onCreatePO = () => {
		history.push(`${url}/create-po`);
	};

	const onCreateQuotation = () => {
		history.push(`${url}/create-quotation`);
	};

	const onEditQuotation = async () => {
		history.push(`${url}/edit-quotation`);
	};

	const onSendQuotation = () => {
		history.push(`${url}/send-email`);
	};

	const onNoDeal = () => {
		history.push(`${url}/no-deal`);
	};

	const onDone = async () => {
		await actions.ticket.update({
			status: TicketStatus.DONE,
		});
	};

	const hasQuotation = () => {
		return currentTicket?.quotation ? true : false;
	};

	return (
		<Box display="flex" flexWrap="wrap" justifyContent={'space-between'}>
			<Button
				size="small"
				variant="contained"
				startIcon={<EditIcon />}
				onClick={onEdit}
			>
				Edit
			</Button>

			<div className={classes.actions}>
				{currentTicket?.status === TicketStatus.OFFER &&
					currentTicket.client !== null && (
						<Button
							size="small"
							variant="contained"
							color="primary"
							startIcon={<AssignmentIcon />}
							onClick={onCreateQuotation}
						>
							Make quotation
						</Button>
					)}

				{(currentTicket?.status === TicketStatus.SHOPPING ||
					currentTicket?.status === TicketStatus.UPLOAD) && (
					<>
						<ContainedButton
							size="small"
							onClick={onUploadDocuments}
							startIcon={<UploadIcon />}
							isLoading={isUploading}
						>
							Upload Documents
						</ContainedButton>

						<UploadDocuments
							open={openUploadDialog}
							onClose={() => setOpenUploadDialog(false)}
						/>

						<ContainedButton
							size="small"
							color="inherit"
							onClick={onCreateInvoice}
							startIcon={<InvoiceIcon />}
							isLoading={isLoadingInvoice}
						>
							Create Invoice
						</ContainedButton>

						<ContainedButton
							size="small"
							color="inherit"
							onClick={onCreatePO}
							startIcon={<POIcon />}
						>
							Create PO
						</ContainedButton>
					</>
				)}

				{(currentTicket?.status === TicketStatus.REPLIED ||
					currentTicket?.status === TicketStatus.INVOICE) &&
					currentTicket.isInvoiced && (
						<ContainedButton
							size="small"
							color="primary"
							onClick={onDone}
							startIcon={<InvoiceIcon />}
						>
							Done
						</ContainedButton>
					)}

				{currentTicket?.status === TicketStatus.REVIEW &&
					currentUser?.role === UserRole.ADMIN &&
					currentTicket?.quotation?.id && (
						<Button
							size="small"
							variant="contained"
							color="primary"
							startIcon={<SendIcon />}
							onClick={onSendQuotation}
						>
							Send quotation
						</Button>
					)}

				{currentTicket?.status === TicketStatus.REVIEW &&
					currentTicket?.quotation?.id && (
						<Button
							size="small"
							variant="contained"
							startIcon={<EditIcon />}
							onClick={onEditQuotation}
						>
							Edit Quotation
						</Button>
					)}

				{currentTicket?.status === TicketStatus.REPLIED && hasQuotation() && (
					<>
						<Button
							size="small"
							variant="contained"
							color="primary"
							onClick={onCreateDelivery}
							startIcon={
								isLoadingDeliveryNote ? (
									<CircularProgress size={18} color="primary" />
								) : (
									<AirportShuttleIcon />
								)
							}
							disabled={isLoadingDeliveryNote}
						>
							Create delivery
						</Button>

						<RedButton
							size="small"
							startIcon={<MoodBadIcon />}
							onClick={onNoDeal}
						>
							No deal
						</RedButton>
					</>
				)}

				{currentTicket?.status === TicketStatus.UPLOAD && (
					<Button size="small" variant="contained" onClick={onDone}>
						Done
					</Button>
				)}
			</div>
		</Box>
	);
}

export default Actions;
