import {
	Avatar,
	Button,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core';
import { xor } from 'lodash';
import React from 'react';
import { InputDebounced } from '../../../../components';
import { User } from '../../../../models';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
		},
		searchInput: {
			marginRight: theme.spacing(3),
			width: 200,
		},
		button: {
			marginRight: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		users: {
			display: 'flex',
			flexDirection: 'row-reverse',
			margin: '0 12px 0 2px',
		},
		avatar: {
			display: 'inline-flex',
			marginLeft: '-2px',
			borderRadius: '50%',
			transition: 'transform 0.1s',
			cursor: 'pointer',
			userSelect: 'none',
			'&:hover': {
				transform: 'translateY(-5px)',
			},
		},
		avatarBackground: {
			background: '#42526E',
			textTransform: 'uppercase',
			fontSize: '14px',
			fontWeight: 'bold',
		},
		isActiveUser: {
			boxShadow: `0 0 0 4px ${theme.palette.secondary.main}`,
		},
	})
);

interface Props {
	filters: any;
	projectUsers: User[];
	defaultFilters: any;
	mergeFilters: (filters: any) => void;
}

function nameToInitials(fullName) {
	const namesArray = fullName.trim().split(' ');
	if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
	else
		return `${namesArray[0].charAt(0)}${namesArray[
			namesArray.length - 1
		].charAt(0)}`;
}

function Filters(props: Props) {
	const classes = useStyles();
	const { filters, projectUsers, defaultFilters, mergeFilters } = props;
	const { searchTerm, userIds, myOnly, recent } = filters;

	const areFiltersCleared =
		!searchTerm && userIds.length === 0 && !myOnly && !recent;

	return (
		<div className={classes.root}>
			<div className={classes.searchInput}>
				<InputDebounced
					icon="search"
					value={searchTerm}
					onChange={(value) => mergeFilters({ searchTerm: value })}
				/>
			</div>

			<div className={classes.users}>
				{projectUsers.map((user) => (
					<div
						className={clsx(
							classes.avatar,
							userIds.includes(user.id) && classes.isActiveUser
						)}
						key={user.id}
					>
						<Avatar
							className={classes.avatarBackground}
							src={user.avatarUrl}
							style={{ width: 32, height: 32 }}
							onClick={() => mergeFilters({ userIds: xor(userIds, [user.id]) })}
						>
							{nameToInitials(user.name)}
						</Avatar>
					</div>
				))}
			</div>

			<Button
				variant={!myOnly ? 'text' : 'contained'}
				color={!myOnly ? 'default' : 'primary'}
				onClick={() => mergeFilters({ myOnly: !myOnly })}
				className={classes.button}
			>
				Only My Tickets
			</Button>
			<Button
				variant={!recent ? 'text' : 'contained'}
				color={!recent ? 'default' : 'primary'}
				onClick={() => mergeFilters({ recent: !recent })}
				className={classes.button}
			>
				Recently Updated
			</Button>

			{!areFiltersCleared && (
				<Button variant="text" onClick={() => mergeFilters(defaultFilters)}>
					Clear all
				</Button>
			)}
		</div>
	);
}

export default Filters;
