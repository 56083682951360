import instance from '../../api/axios';
import { Project } from '../../models';

export const api = {
	async get() {
		try {
			const response = await instance.get<Project>(`/project`);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
