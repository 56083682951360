import { makeStyles } from '@material-ui/core';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

export type ColorTypes =
	| 'primary'
	| 'secondary'
	| 'error'
	| 'default'
	| 'inherit';

export type ColoredButtonProps = { color?: ColorTypes } & Omit<
	ButtonProps,
	'color'
>;
const useStyles = makeStyles((theme) => ({
	textError: {
		color: theme.palette.error.main,
		'&:hover': {
			backgroundColor: fade(
				theme.palette.error.main,
				theme.palette.action.hoverOpacity
			),

			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
	},
	outlinedError: {
		color: theme.palette.error.main,
		border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
		'&:hover': {
			border: `1px solid ${theme.palette.error.main}`,
			backgroundColor: fade(
				theme.palette.error.main,
				theme.palette.action.hoverOpacity
			),

			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
	},
	containedError: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,

			'@media (hover: none)': {
				backgroundColor: theme.palette.error.main,
			},
		},
	},
}));

const Button = (props: ColoredButtonProps, ref: any) => {
	const { variant = 'text', color, className, ...rest } = props;
	const classes = useStyles();

	return (
		<MuiButton
			variant={variant}
			color={color === 'error' ? 'primary' : color}
			className={clsx(className, {
				[classes[`${variant}Error`]]: color === 'error',
			})}
			ref={ref}
			{...rest}
		/>
	);
};

export default forwardRef(Button);
