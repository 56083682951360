import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
	AmpStories as AmpStoriesIcon,
	Business as BusinessIcon,
	Contacts as ContactsIcon,
	Dashboard as DashboardIcon,
	List as ListIcon,
	Mail as MailIcon,
	CalendarToday as CalendarTodayIcon,
	People as PeopleIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/css-logo-small.png';
import { UserHelper } from '../helpers';
import { useCurrentUser } from '../hooks';
import history from '../utils/history';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: theme.spacing(0, 2),
			backgroundColor: theme.palette.grey[50],

			...theme.mixins.toolbar,
		},
		categoryHeader: {
			paddingTop: theme.spacing(2),
		},
		categoryHeaderPrimary: {
			fontWeight: 700,
		},
		item: {},
		itemActiveItem: {
			background: theme.palette.grey[300],
		},
		itemPrimary: {
			fontSize: 'inherit',
		},
		itemIcon: {
			minWidth: 'auto',
			marginRight: theme.spacing(2),
		},
		hidden: {
			display: 'none',
		},

		logo: {
			height: 50,
		},
	})
);

const categories: {
	id: string;
	isAdmin?: boolean;
	children: {
		id: string;
		icon: any;
		path: string;
		isAdmin?: boolean;
	}[];
}[] = [
	{
		id: 'Activities',
		children: [
			{ id: 'Overview', icon: <DashboardIcon />, path: '/projects' },
			{ id: 'Mail', icon: <MailIcon />, path: '/mailbox' },
			{ id: 'Calendar', icon: <CalendarTodayIcon />, path: '/calendar' },
		],
	},
	{
		id: 'Database',
		children: [
			{ id: 'Clients', icon: <ContactsIcon />, path: '/clients' },
			{ id: 'Products', icon: <ListIcon />, path: '/products' },
		],
	},
	{
		id: 'Configuration',
		isAdmin: true,
		children: [
			{
				id: 'Company',
				icon: <BusinessIcon />,
				path: '/settings',
				isAdmin: true,
			},
			{ id: 'Users', icon: <PeopleIcon />, path: '/users', isAdmin: true },
			{
				id: 'Templates',
				icon: <AmpStoriesIcon />,
				path: '/templates',
				isAdmin: true,
			},
		],
	},
];

interface Props {
	openDrawer: boolean;
}

export default function AppDrawer(props: Props) {
	const classes = useStyles();
	const { openDrawer } = props;
	const location = useLocation();

	const { currentUser } = useCurrentUser();

	const userHelper = new UserHelper(currentUser);

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="left"
			open={openDrawer}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.drawerHeader}>
				<Link to="/">
					<img src={logo} alt="CSS Logo" className={classes.logo} />
				</Link>
			</div>

			{categories.map(({ id, isAdmin, children }) => (
				<Fragment key={id}>
					<ListItem
						className={clsx(
							classes.categoryHeader,
							userHelper.isChandler() && isAdmin && classes.hidden
						)}
					>
						<ListItemText
							classes={{
								primary: classes.categoryHeaderPrimary,
							}}
						>
							{id}
						</ListItemText>
					</ListItem>
					{children.map(({ id: childId, icon, path, isAdmin }) => (
						<ListItem
							key={childId}
							button
							className={clsx(
								classes.item,
								location.pathname.includes(path) && classes.itemActiveItem,
								userHelper.isChandler() && isAdmin && classes.hidden
							)}
							onClick={() => history.push(path)}
						>
							<ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
							<ListItemText
								classes={{
									primary: classes.itemPrimary,
								}}
							>
								{childId}
							</ListItemText>
						</ListItem>
					))}
				</Fragment>
			))}
		</Drawer>
	);
}
