import React from 'react';
import ImageDropZone from 'react-image-dropzone';
import avatarDefault from '../../assets/images/avatar-default.png';
import { useOvermind } from '../../overmind';

interface Props {
	userId: number;
	value: string;
}

function ChangeAvatar(props: Props) {
	const { userId, value } = props;
	const { actions } = useOvermind();

	const handleUpload = (image) => {
		const formData = new FormData();
		formData.append('file', image.file);

		actions.user.uploadAvatar({
			userId: userId.toString(),
			payload: formData,
		});
	};

	return (
		<ImageDropZone
			style={{
				width: '128px',
				height: '128px',
			}}
			anySize
			showButton
			width={128}
			height={128}
			imageDefault={value || avatarDefault}
			imagePicked={handleUpload}
		/>
	);
}

export default ChangeAvatar;
