import {
	AppBar as MUIAppBar,
	Avatar,
	Divider,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	Theme,
	Toolbar,
	Typography,
	Box,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import clsx from 'clsx';
import React from 'react';
import { useOvermind } from '../overmind';
import history from '../utils/history';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
	flexGrow: {
		flexGrow: 1,
	},
}));

interface Props {
	openDrawer: boolean;
	onDrawerOpen: () => void;
}

const AppBar = (props: Props) => {
	const classes = useStyles();
	const { openDrawer, onDrawerOpen } = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const open2 = Boolean(anchorEl2);

	const {
		state: {
			authentication: { user },
		},
		actions,
	} = useOvermind();

	const handleDrawerOpen = () => {
		onDrawerOpen();
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenu2 = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl2(event.currentTarget);
	};

	const handleClose2 = () => {
		setAnchorEl2(null);
	};

	const handleProfile = () => {
		history.push('/profile');
		handleClose();
	};

	const handleLogout = () => {
		actions.authentication.logout();
		history.push('/login');
	};

	const handleShow = async (filePath: string) => {
		window.open(
			filePath,
			'_blank' // <- This is what makes it open in a new window.
		);
	};

	return (
		<MUIAppBar
			elevation={0}
			className={clsx(classes.appBar, {
				[classes.appBarShift]: openDrawer,
			})}
		>
			<Toolbar>
				<IconButton onClick={handleDrawerOpen}>
					{openDrawer ? <MenuOpenIcon /> : <MenuIcon />}
				</IconButton>

				<div className={classes.flexGrow} />

				{user && (
					<Box display="flex" alignItems="center" flexWrap="nowrap">
						<Box marginRight={2}>
							<IconButton size="medium" title="Help" onClick={handleMenu2}>
								<HelpOutlineIcon fontSize="large" />
							</IconButton>
							<Menu
								id="menu-appbar2"
								anchorEl={anchorEl2}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={open2}
								onClose={handleClose2}
							>
								<MenuItem
									onClick={() =>
										handleShow(
											`https://share.vidyard.com/watch/QPbvubMvcVWepwot3aUU6T?`
										)
									}
								>
									New ticket
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleShow(
											`https://share.vidyard.com/watch/e3Gr65uXGmPAPJiHz2nkcd?`
										)
									}
								>
									How to make a quotation
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleShow(
											`https://share.vidyard.com/watch/R45VFoBXbVaBeEMhXnpkNF?`
										)
									}
								>
									How to edit a quotation and Comment
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleShow(
											`https://share.vidyard.com/watch/mUD7e8Dhnt58BA3wwbLZ2f?`
										)
									}
								>
									What to do when a client accepts the offer (Shopping and
									Delivery)
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleShow(
											'https://share.vidyard.com/watch/HenTjRFNk82sTo4tNkBWU2?'
										)
									}
								>
									What to do when a client doesn’t accept the offer?
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleShow(
											`https://share.vidyard.com/watch/6s58K2TCGE9pFcMF42Deg6?`
										)
									}
								>
									Uploading signed delivery note and invoices
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleShow(
											`https://share.vidyard.com/watch/JdDYKNnoqMPCTLhr1R3KwS?`
										)
									}
								>
									How to edit a quotation after reply from client
								</MenuItem>
							</Menu>
						</Box>
						<Box>
							<Avatar
								src={user.avatarUrl}
								onClick={handleMenu}
								style={{ cursor: 'pointer' }}
							/>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={open}
								onClose={handleClose}
							>
								<Typography
									variant="subtitle2"
									style={{ paddingLeft: 16, paddingRight: 16 }}
								>
									{user?.name}
								</Typography>
								<MenuItem onClick={handleProfile}>Profile</MenuItem>
								<Divider />
								<MenuItem onClick={handleLogout}>Logout</MenuItem>
							</Menu>
						</Box>
					</Box>
				)}
			</Toolbar>
		</MUIAppBar>
	);
};

export default AppBar;
