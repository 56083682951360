/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Breadcrumbs,
	Button,
	CircularProgress,
	Grid,
	Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
	EmailOutlined as EmailOutlinedIcon,
	DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { Link, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Page, PrivateRoute, RedButton } from '../../components';
import { TicketStatus, UserRole } from '../../constants';
import { useCurrentProject } from '../../hooks';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import {
	Actions,
	Assignee,
	Attachments,
	Dates,
	Description,
	Details,
	Tabs,
	Title,
} from './components/details';
import CreatePOScreen from './CreatePOScreen';
import NoDealScreen from './NoDealScreen';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		breadcrumbs: {
			marginBottom: theme.spacing(2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: 'none',

			'&:hover': {
				color: theme.palette.primary.light,
			},
		},
	})
);

const DetailsScreen = () => {
	const classes = useStyles();
	const { ticketId } = useParams<{
		ticketId: string;
	}>();
	const { path, url } = useRouteMatch();

	const {
		state: {
			ticket: { currentTicket, isLoading, isDeleting },
		},
		actions,
	} = useOvermind();

	const { currentProject } = useCurrentProject();

	useEffect(() => {
		if (!currentProject) actions.project.get();

		if (ticketId) {
			actions.ticket.getById(ticketId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketId]);
	const onDelete = async () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		await actions.ticket.remove(currentTicket?.id!);
		history.push('/projects');
	};

	return (
		<>
			<Page title={currentTicket?.title}>
				<Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
					<Link to={`/projects`} className={classes.link}>
						Overview
					</Link>
					<Typography color="textPrimary">PRO - {ticketId}</Typography>
				</Breadcrumbs>

				<Grid container spacing={3}>
					<Grid item md={8}>
						{currentTicket?.id.toString() === ticketId && (
							<Title title={currentTicket?.title!} />
						)}
					</Grid>

					<Grid item md={8}>
						{currentTicket?.status !== TicketStatus.INVALID && <Actions />}

						<Details />

						<Description ticket={currentTicket!} isLoading={isLoading} />

						<Tabs ticket={currentTicket!} />
					</Grid>

					<Grid item md={4}>
						{currentTicket?.status !== TicketStatus.INVALID ? (
							<>
								{isLoading ? (
									<Skeleton
										variant="rect"
										width={115}
										height={30}
										animation="wave"
									/>
								) : (
									<Button
										variant="contained"
										color="secondary"
										size="small"
										startIcon={<EmailOutlinedIcon />}
										onClick={() => {
											history.push({
												pathname: `${url}/send-email`,
											});
										}}
									>
										Send email
									</Button>
								)}

								{!isLoading && <Assignee user={currentTicket?.user!} />}

								{currentTicket?.attachments &&
									currentTicket?.attachments.length > 0 && <Attachments />}
							</>
						) : (
							<RedButton
								size="small"
								onClick={onDelete}
								startIcon={
									isDeleting ? (
										<CircularProgress size={18} color="primary" />
									) : (
										<DeleteForeverIcon />
									)
								}
								disabled={isDeleting}
							>
								Delete this ticket
							</RedButton>
						)}

						<Dates
							createdAt={currentTicket?.createdAt!}
							updatedAt={currentTicket?.updatedAt!}
							isLoading={isLoading}
						/>
					</Grid>
				</Grid>
			</Page>

			<Switch>
				<PrivateRoute
					path={`${path}/create-po`}
					roles={[UserRole.ADMIN, UserRole.CHANDLER]}
				>
					<CreatePOScreen modalClose={() => history.push(url)} />
				</PrivateRoute>

				<PrivateRoute
					path={`${path}/no-deal`}
					roles={[UserRole.ADMIN, UserRole.CHANDLER]}
				>
					<NoDealScreen modalClose={() => history.push(url)} />
				</PrivateRoute>
			</Switch>
		</>
	);
};

export default DetailsScreen;
