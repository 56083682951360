import React from 'react';
import { Page } from '../../components';
import { FormEdit } from '../../components/User';
import { useOvermind } from '../../overmind';

function ProfileScreen() {
	const {
		state: {
			authentication: { user },
		},
	} = useOvermind();

	return (
		<Page title="Profile">
			<FormEdit user={user} />
		</Page>
	);
}

export default ProfileScreen;
