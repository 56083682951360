/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	createStyles,
	Divider,
	FormControlLabel,
	Grid,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	Theme,
	Typography,
} from '@material-ui/core';
import {
	CallMade as CallMadeIcon,
	CallReceived as CallReceivedIcon,
	ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { json } from 'overmind';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DateHelper } from '../../../../../../helpers';
import { Email } from '../../../../../../models';
import history from '../../../../../../utils/history';
import Attachments from '../../Attachments';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			width: 24,
			height: 24,
			margin: theme.spacing(0, 2),
		},
	})
);

interface Props {
	emails: Email[];
}

const EmailMenu = (props: { email: Email }) => {
	const { email } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { url } = useRouteMatch();

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				style={{
					marginTop: '8px',
					minWidth: '30px',
				}}
			>
				<MoreHorizIcon />
			</Button>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => {
						history.push({
							pathname: `${url}/send-email`,
							state: {
								isReply: true,
								email: email,
								isForward: false,
							},
						});
					}}
				>
					Reply
				</MenuItem>
				<MenuItem
					onClick={() => {
						history.push({
							pathname: `${url}/send-email`,
							state: {
								isReply: true,
								email: email,
								isForward: false,
							},
						});
					}}
				>
					Reply all
				</MenuItem>
				<MenuItem
					onClick={() => {
						history.push({
							pathname: `${url}/send-email`,
							state: {
								isReply: false,
								email: email,
								isForward: true,
							},
						});
					}}
				>
					Forward
				</MenuItem>
			</Menu>
		</>
	);
};

function Emails(props: Props) {
	const { emails } = props;
	const classes = useStyles();

	const sortByNewest = (items: Email[], sortField: string) =>
		items.sort((a, b) => -a[sortField].localeCompare(b[sortField]));

	return (
		<>
			{sortByNewest(json(emails), 'createdAt').map(
				(email: Email, index: number) => (
					<>
						<Box key={index} flexWrap="nowrap" display="flex">
							<Box>
								<Accordion elevation={0}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`email-${email.id}-content`}
										id={`email-${email.id}-header`}
									>
										<Grid container alignItems="center" justify="space-between">
											<Grid item>
												<FormControlLabel
													onClick={(event) => event.stopPropagation()}
													onFocus={(event) => event.stopPropagation()}
													control={
														email.category === 'sent' ? (
															<CallMadeIcon className={classes.icon} />
														) : (
															<CallReceivedIcon className={classes.icon} />
														)
													}
													label={email.subject}
												/>
											</Grid>
											<Grid item>
												<Typography variant="caption">
													{DateHelper.distanceToNowFormat(email.createdAt)}
												</Typography>
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails
										style={{ backgroundColor: 'rgba(0, 0, 0, .01)' }}
									>
										<Grid container spacing={3}>
											<Grid item md={6}>
												<ListItemText primary="From" secondary={email.from} />
											</Grid>
											<Grid item md={6}>
												<ListItemText primary="To" secondary={email.to} />
											</Grid>
											<Grid item md={12}>
												<Box dangerouslySetInnerHTML={{ __html: email.body }} />
											</Grid>

											{email.attachments.length > 0 && (
												<Grid item md={6}>
													<Attachments />
												</Grid>
											)}
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Box>
							<Box width={50}>
								<EmailMenu email={email} />
							</Box>
						</Box>

						<Divider />
					</>
				)
			)}
		</>
	);
}

export default Emails;
