import { Configuration } from '../../models';

type ConfigurationState = {
	isLoading: boolean;
	data: Configuration[];
};

export const state: ConfigurationState = {
	isLoading: false,
	data: [],
};
