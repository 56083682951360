import { Event } from '../../models';

export type EventState = {
	isLoading: boolean;
	data: Event[];
};

export const state: EventState = {
	isLoading: false,
	data: [],
};
