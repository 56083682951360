import { Quotation, Ticket } from '../../models';

export type TicketState = {
	isLoading: boolean;
	isLoadingQuotation: boolean;
	isLoadingInvoice: boolean;
	isLoadingPO: boolean;
	isLoadingDeliveryNote: boolean;
	isUploading: boolean;
	isDeleting: boolean;
	isQuotationCreated: boolean;
	isInvoiceCreated: boolean;
	isPOCreated: boolean;
	data: Ticket[];
	currentTicket: Ticket | null;
	quotation: Quotation | null;
};

export const state: TicketState = {
	isLoading: false,
	isLoadingQuotation: false,
	isLoadingInvoice: false,
	isLoadingPO: false,
	isLoadingDeliveryNote: false,
	isUploading: false,
	isDeleting: false,
	isQuotationCreated: false,
	isInvoiceCreated: false,
	isPOCreated: false,
	data: [],
	currentTicket: null,
	quotation: null,
};
