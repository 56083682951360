import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Page, PageTitle } from '../../components';
import { Product } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { Form } from './components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			margin: theme.spacing(3, 0, 0),
		},
	})
);

function EditScreen() {
	const classes = useStyles();
	const { productId } = useParams<{ productId: string }>();

	const {
		state: {
			product: { currentProduct, isLoading, isDeleting },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		if (productId) actions.product.getById(productId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productId]);

	const initialValues: Partial<Product> = {
		name: currentProduct?.name || '',
		unit: currentProduct?.unit || '',
		price: currentProduct?.price || '',
		margin: currentProduct?.margin || '',
		company: currentProduct?.company || '',
		tags: currentProduct?.tags || '',
	};

	const handleSubmit = async (values: Partial<Product>) => {
		await actions.product.update(values);

		history.push(`/products`);
	};

	const handleDelete = async () => {
		await actions.product.remove(productId);

		history.push(`/products`);
	};

	return (
		<Page title="Edit Product">
			<PageTitle>Edit Product</PageTitle>

			<Grid container className={classes.form}>
				<Grid item md={4}>
					<Form
						initialValues={initialValues}
						onSubmit={handleSubmit}
						onDelete={handleDelete}
						isLoading={isLoading}
						isDeleting={isDeleting}
						isEdit
					/>
				</Grid>
			</Grid>
		</Page>
	);
}

export default EditScreen;
