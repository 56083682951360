import { useAccount, useMsal } from '@azure/msal-react';
import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import { Formik } from 'formik';
import ChipInput from 'material-ui-chip-input';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { ContainedButton, Page } from '../../components';
import { loginRequest, pattern } from '../../constants';
import { getBase64, isFieldError, MsGraph } from '../../helpers';
import { useOvermind } from '../../overmind';
import { Attachment, ComposeInitValues, Recipient } from './models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},

			'& .MuiTypography-root.MuiTypography-subtitle1': {
				display: 'none',
			},
		},
		body: {
			backgroundColor: theme.palette.common.white,

			'& .ql-toolbar': {
				borderTopRightRadius: 5,
				borderTopLeftRadius: 5,
			},

			'& .ql-container': {
				height: 130,
				borderBottomRightRadius: 5,
				borderBottomLeftRadius: 5,
			},
		},
		dropzone: {
			minHeight: 95,
		},
		dropzoneParagraph: {
			fontSize: 14,
			margin: theme.spacing(1, 0),
		},
		previewChip: {
			maxWidth: 210,
		},
	})
);

const validationSchema = Yup.object().shape({
	recipients: Yup.array().of(Yup.string()).required('This field is required'),
	subject: Yup.string().required('This field is required'),
	body: Yup.string().required('This field is required'),
});

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

interface Props {
	modalClose: () => void;
}

function ComposeScreen(props: Props) {
	const { modalClose } = props;
	const classes = useStyles();
	const query = useQuery();
	const emailPattern = new RegExp(pattern.email);
	const [isLoading, setLoading] = useState(false);

	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});

	const { actions } = useOvermind();

	const initialValues: ComposeInitValues = {
		recipients: query.get('recipients')?.split(',') || [],
		subject: query.get('subject') || '',
		body: '',
		attachments: [],
	};

	const handleSubmit = async (values: ComposeInitValues) => {
		const payload = {
			message: {
				subject: values.subject,
				body: {
					contentType: 'HTML',
					content: values.body,
				},
				toRecipients: await generateRecipients(values.recipients),
				attachments: await generateAttachments(values.attachments),
			},
			saveToSentItems: true,
		};

		if (account) {
			setLoading(true);
			instance
				.acquireTokenSilent({
					...loginRequest,
					account,
				})
				.then(async (response) => {
					const client = new MsGraph(response.accessToken);
					await client.sendEmail(payload);

					setLoading(false);

					actions.alert.setAlert({
						type: 'success',
						message: 'Message sent.',
					});

					modalClose();
				});
		}
	};

	const generateRecipients = async (recipients: string[]) => {
		const toRecipients: Recipient[] = [];

		await Promise.all(
			recipients.map(async (recipient) => {
				toRecipients.push({
					emailAddress: {
						address: recipient,
					},
				});
			})
		);

		return toRecipients;
	};

	const generateAttachments = async (files: File[]) => {
		const attachments: Attachment[] = [];

		await Promise.all(
			files.map(async (file) => {
				const res = await getBase64(file).then((result) => {
					const contentType = (result as string)
						.split(';base64,')[0]
						.split(':')[1];
					const contentBytes = (result as string).split(';base64,')[1];

					return {
						'@odata.type': '#microsoft.graph.fileAttachment',
						name: file.name,
						contentType: contentType,
						contentBytes: contentBytes,
					};
				});

				attachments.push(res);
			})
		);

		return attachments;
	};

	const handleBeforeAddRecipient = (recipient: string) => {
		if (recipient.length && !emailPattern.test(recipient)) {
			actions.alert.setAlert({
				type: 'error',
				message: `The address "${recipient}" in the "Recipients" field was not recognised.`,
			});

			return false;
		}

		return true;
	};

	return (
		<Page title="New Message">
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="h6">New Message</Typography>
				<IconButton onClick={() => modalClose()}>
					<CloseIcon />
				</IconButton>
			</Box>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					values,
					errors,
					touched,
				}) => (
					<form onSubmit={handleSubmit} className={classes.form}>
						<ChipInput
							fullWidth
							placeholder="Recipients"
							id="recipients"
							defaultValue={values.recipients}
							onChange={(chips) => setFieldValue('recipients', chips)}
							onBlur={handleBlur}
							onDelete={(chip) =>
								setFieldValue(
									'recipients',
									values.recipients.filter((value) => value !== chip)
								)
							}
							onBeforeAdd={(chip) => handleBeforeAddRecipient(chip)}
							newChipKeyCodes={[13, 32, 188]}
							blurBehavior="add"
							error={isFieldError(
								touched.recipients,
								errors.recipients as string
							)}
							helperText={touched.recipients && errors.recipients}
						/>

						<TextField
							fullWidth
							placeholder="Subject"
							name="subject"
							onChange={handleChange}
							onBlur={handleBlur}
							error={isFieldError(touched.subject, errors.subject)}
							helperText={touched.subject && errors.subject}
							value={values.subject}
						/>

						<ReactQuill
							theme="snow"
							className={classes.body}
							value={values.body}
							onChange={(value) => setFieldValue('body', value)}
						/>

						<DropzoneArea
							onChange={(files) => setFieldValue('attachments', files)}
							showPreviews={true}
							showPreviewsInDropzone={false}
							useChipsForPreview
							previewGridProps={{
								container: { spacing: 1, direction: 'row' },
							}}
							previewChipProps={{ classes: { root: classes.previewChip } }}
							previewText="Selected files"
							dropzoneText="Drop files to attach or click"
							dropzoneClass={classes.dropzone}
							dropzoneParagraphClass={classes.dropzoneParagraph}
							showAlerts={false}
						/>

						<ContainedButton
							type="submit"
							startIcon={<SendIcon />}
							isLoading={isLoading}
						>
							Send
						</ContainedButton>
					</form>
				)}
			</Formik>
		</Page>
	);
}

export default ComposeScreen;
