import { TextField } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { ContainedButton, TextButton } from '../../../../components';
import { pattern } from '../../../../constants';
import { isFieldError } from '../../../../helpers';
import { Contact } from '../../../../models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			maxWidth: 400,

			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		button: {
			'& > *': {
				marginRight: theme.spacing(1),
			},
		},
	})
);

const validationSchema = Yup.object().shape({
	name: Yup.string().required('This field is required'),
	email: Yup.string()
		.required('This field is required')
		.email('This email address is invalid. Please enter a valid address.'),
	phone: Yup.string()
		.required('This field is required')
		.matches(
			pattern.phoneNumber,
			'This phone number is invalid. Please enter a valid phone number.'
		),
	function: Yup.string().required('This field is required'),
});

interface Props {
	initialValues: Partial<Contact>;
	onSubmit: (values: any) => void;
	onDelete?: () => void;
	isEdit?: boolean;
	isLoading: boolean;
}

const Form = (props: Props) => {
	const { initialValues, onSubmit, onDelete, isEdit, isLoading } = props;
	const classes = useStyles();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				errors,
				touched,
			}) => (
				<form onSubmit={handleSubmit} className={classes.form}>
					<TextField
						fullWidth
						label="Name"
						name="name"
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.name, errors.name)}
						helperText={touched.name && errors.name}
						value={values.name}
					/>

					<TextField
						fullWidth
						label="Email"
						name="email"
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.email, errors.email)}
						helperText={touched.email && errors.email}
						value={values.email}
					/>

					<TextField
						fullWidth
						label="Phone"
						name="phone"
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.phone, errors.phone)}
						helperText={touched.phone && errors.phone}
						value={values.phone}
					/>

					<TextField
						fullWidth
						label="Position"
						name="function"
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.function, errors.function)}
						helperText={touched.function && errors.function}
						value={values.function}
					/>

					<div className={classes.button}>
						{isEdit ? (
							<>
								<ContainedButton type="submit" isLoading={isLoading}>
									Edit contact
								</ContainedButton>
								<TextButton startIcon={<Icon>delete</Icon>} onClick={onDelete}>
									Delete
								</TextButton>
							</>
						) : (
							<ContainedButton type="submit" isLoading={isLoading}>
								Add contact
							</ContainedButton>
						)}
					</div>
				</form>
			)}
		</Formik>
	);
};

export default Form;
