import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';
import App from './App';
import './index.css';
import { config } from './overmind';
import * as serviceWorker from './serviceWorker';
import history from './utils/history';
import i18n from './utils/i18n';

const overmind = createOvermind(config);

ReactDOM.render(
	// Note: Disabled StrictMode for now, since there's warning about findDOMNode for most of Material UI Components
	// <React.StrictMode>
	<Provider value={overmind}>
		<I18nextProvider i18n={i18n}>
			<Router history={history}>
				<App />
			</Router>
		</I18nextProvider>
	</Provider>,

	// </React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
