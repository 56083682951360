import instance from '../../api/axios';

export const api = {
	async fetch() {
		try {
			const response = await instance.get(`/products`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async fetchCategories() {
		try {
			const response = await instance.get(`/products/categories`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async getById(productId: string) {
		try {
			const response = await instance.get(`/products/${productId}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async create(payload) {
		try {
			const response = await instance.post(`/products`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async update(productId, payload) {
		try {
			const response = await instance.put(`/products/${productId}`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async remove(id) {
		try {
			const response = await instance.delete(`/products/${id}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async import(payload) {
		try {
			const response = await instance.post(`/products/imports`, payload, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			});

			return response;
		} catch (error) {
			throw error;
		}
	},
};
