import instance from '../../api/axios';

export const api = {
	async fetch() {
		try {
			const response = await instance.get(`/templates`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async getById(id) {
		try {
			const response = await instance.get(`/templates/${id}`);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async create(payload) {
		try {
			const response = await instance.post(`/templates`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async update(id, payload) {
		try {
			const response = await instance.put(`/templates/${id}`, payload);

			return response;
		} catch (error) {
			throw error;
		}
	},

	async remove(id) {
		try {
			const response = await instance.delete(`/templates/${id}`);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
