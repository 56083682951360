import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface Props {
	children: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: Props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box paddingY={2} paddingX={1}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

export default TabPanel;
