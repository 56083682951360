import { FormHelperText, TextField as MUITextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { pattern } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		field: {
			margin: theme.spacing(1, 0),
		},
	})
);

interface Props {
	label: string;
	name: string;
	value: string | number;
	type?: 'text' | 'password';
	readOnly?: boolean;
	onUpdate?: (payload: any) => void;
}
function AutoUpdateField(props: Props) {
	const { label, name, value, type, readOnly, onUpdate } = props;
	const classes = useStyles();
	const [currentValue, setCurrentValue] = useState<string | number>(value);
	const [error, setError] = useState<string | null>(null);
	const emailPattern = new RegExp(pattern.email);
	const phonePattern = new RegExp(pattern.phoneNumber);

	useEffect(() => {
		if (value) setCurrentValue(value);
	}, [value]);

	const handleTitleChange = (event: any) => {
		setError(null);

		const newValue = event.target.value;

		if (newValue === value) return;

		if (!newValue) {
			setError('This field is required');
		} else if (newValue.length >= 200) {
			setError('Must be at most 200 characters');
		} else if (name === 'email' && !emailPattern.test(newValue)) {
			setError('This email address is invalid. Please enter a valid address.');
		} else if (name === 'phone' && !phonePattern.test(newValue)) {
			setError(
				'This phone number is invalid. Please enter a valid phone number.'
			);
		} else {
			if (onUpdate)
				onUpdate({
					[name]: newValue,
				});
		}
	};

	const handleChange = (event: any) => {
		const value = event.target.value;
		setCurrentValue(value);
	};

	return (
		<>
			<MUITextField
				fullWidth
				label={label}
				value={currentValue}
				onChange={handleChange}
				type={type === 'password' ? 'password' : 'text'}
				onBlur={handleTitleChange}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						event.currentTarget.blur();
					}
				}}
				className={classes.field}
				InputProps={{
					readOnly: readOnly ? true : false,
				}}
				inputProps={{
					form: {
						autocomplete: 'off',
					},
				}}
			/>

			{error && <FormHelperText error>{error}</FormHelperText>}
		</>
	);
}

export default AutoUpdateField;
