import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { getStoredAuthUser } from '../helpers';
import { useOvermind } from '../overmind';

interface Props extends RouteProps {
	roles: string[];
	children: React.ReactElement<any>;
}

const PrivateRoute: FunctionComponent<Props> = (props: Props) => {
	const { children, roles, ...rest } = props;

	const {
		actions: { authentication },
	} = useOvermind();

	const user = getStoredAuthUser();

	return (
		<Route
			{...rest}
			render={(routeProps) => {
				const { location } = routeProps;
				if (!user) {
					if (location.pathname === '/login') {
						return;
					}

					return (
						<Redirect
							to={{
								pathname: '/login',
							}}
						/>
					);
				}

				if (roles && user && roles.indexOf(user.role) === -1) {
					if (user) {
						authentication.logout();
					}

					return (
						<Redirect
							to={{
								pathname: '/login',
							}}
						/>
					);
				}

				return React.cloneElement(children, { ...routeProps });
			}}
		/>
	);
};

export default PrivateRoute;
