import { VesselType } from '../../models';

type VesselState = {
	isLoading: boolean;
	types: VesselType[];
};

export const state: VesselState = {
	isLoading: false,
	types: [],
};
