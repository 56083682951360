import { FormHelperText, TextField } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import {
	ContainedButton,
	DropdownCategory,
	NumberField,
	TextButton,
} from '../../../components';
import { isFieldError } from '../../../helpers';
import { Product } from '../../../models';
import { useOvermind } from '../../../overmind';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		body: {
			minHeight: 200,
		},
		bodyField: {
			height: 150,
		},
		button: {
			'& > *': {
				marginRight: theme.spacing(1),
			},
		},
	})
);

interface Props {
	initialValues: Partial<Product>;
	onSubmit: (values: any) => void;
	onDelete?: () => void;
	isEdit?: boolean;
	isLoading: boolean;
	isDeleting?: boolean;
}

const Form = (props: Props) => {
	const {
		initialValues,
		onSubmit,
		onDelete,
		isEdit,
		isLoading,
		isDeleting,
	} = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		state: {
			product: { categories },
		},
		actions,
	} = useOvermind();

	useEffect(() => {
		actions.product.fetchCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('general.validation.required'),
		unit: Yup.string().required('general.validation.required'),
		price: Yup.string().required('general.validation.required'),
		margin: Yup.string().required('general.validation.required'),
		company: Yup.string().required('general.validation.required'),
		categoryId: !isEdit
			? Yup.number()
					.required('general.validation.required')
					.positive('general.validation.required')
					.integer()
			: Yup.number(),
		tags: Yup.string(),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				values,
				errors,
				touched,
			}) => (
				<form onSubmit={handleSubmit} className={classes.form}>
					<TextField
						fullWidth
						label="Name"
						name="name"
						value={values.name}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.name, errors.name)}
						helperText={touched.name && errors.name && t(errors.name)}
					/>
					<TextField
						fullWidth
						label="Unit"
						name="unit"
						value={values.unit}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.unit, errors.unit)}
						helperText={touched.unit && errors.unit && t(errors.unit)}
					/>

					<TextField
						fullWidth
						label="Price (ƒ)"
						name="price"
						value={values.price}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.price, errors.price)}
						helperText={touched.price && errors.price && t(errors.price)}
						InputProps={{
							inputComponent: NumberField as any,
						}}
					/>

					<TextField
						fullWidth
						label="Margin (%)"
						name="margin"
						value={values.margin}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.margin, errors.margin)}
						helperText={touched.margin && errors.margin && t(errors.margin)}
						InputProps={{
							inputComponent: NumberField as any,
							inputProps: {
								decimalScale: 0,
							},
						}}
					/>

					<TextField
						fullWidth
						label="Company"
						name="company"
						value={values.company}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.company, errors.company)}
						helperText={touched.company && errors.company && t(errors.company)}
					/>

					<TextField
						fullWidth
						multiline
						rows={3}
						label="Tags"
						name="tags"
						value={values.tags}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isFieldError(touched.tags, errors.tags)}
						helperText={touched.tags && errors.tags && t(errors.tags)}
					/>

					{!isEdit && (
						<DropdownCategory
							categories={categories || []}
							onChange={(categoryId: string) => {
								setFieldValue('categoryId', categoryId);
							}}
						/>
					)}

					{errors.categoryId && touched.categoryId && (
						<FormHelperText error>{t(errors.categoryId)}</FormHelperText>
					)}

					<div className={classes.button}>
						{isEdit ? (
							<>
								<ContainedButton type="submit" isLoading={isLoading}>
									Edit product
								</ContainedButton>
								<TextButton
									startIcon={<Icon>delete</Icon>}
									onClick={onDelete}
									isLoading={isDeleting}
								>
									Delete
								</TextButton>
							</>
						) : (
							<ContainedButton type="submit" isLoading={isLoading}>
								Add product
							</ContainedButton>
						)}
					</div>
				</form>
			)}
		</Formik>
	);
};

export default Form;
