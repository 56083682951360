/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addDays } from 'date-fns';
import { toInteger } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContainedButton, NumberField } from '../../../../components';
import { defaultLocale } from '../../../../constants';
import { DateHelper } from '../../../../helpers';
import { useStickyState } from '../../../../hooks';
import { Client, Contact, Vessel } from '../../../../models';
import { useOvermind } from '../../../../overmind';
import history from '../../../../utils/history';
import { QuotationPayload } from '../../models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			marginBottom: theme.spacing(2),
		},
		header: {
			background: theme.palette.grey[300],

			'& > *': {
				padding: theme.spacing(1, 2),
			},
		},
		product: {
			'& > *': {
				padding: theme.spacing(1, 2),
				borderBottom: 0,

				'&:last-child': {
					padding: theme.spacing(1, 0),
				},
			},

			'&:nth-child(even)': {
				background: theme.palette.grey[100],
			},
		},
		notAccepted: {
			'& > *': {
				color: red[900],
			},
		},
		footer: {
			background: theme.palette.grey[300],

			'& > *': {
				padding: theme.spacing(1, 2),
				borderBottom: 0,
				fontWeight: 700,
			},
		},
		form: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		button: {
			margin: theme.spacing(2, 0),
		},
	})
);

const initialRow = [
	{
		product: '',
		quantity: '-',
		price: 0,
		deliveryTime: 0,
	},
];

interface Props {
	ticketId: string;
	data: any[];
	isEdit?: boolean;
	onSubmit: (payload: QuotationPayload) => void;
}

const TableForm = (props: Props) => {
	const { ticketId, data, isEdit, onSubmit } = props;
	const classes = useStyles();
	const { state } = useLocation<any>();

	const [totalDiscount, setTotalDiscount] = useState<number>(0);

	const {
		state: {
			ticket: { currentTicket, isLoadingQuotation: isLoading },
			product: { data: products, currentProduct },
		},
		actions,
	} = useOvermind();

	const [rows, setRows] = useStickyState(
		JSON.parse(JSON.stringify(initialRow)),
		`quotation-row-${ticketId}`
	);

	useEffect(() => {
		if (!state && data.length) {
			setRows(data);
		}
	}, [data]);

	useEffect(() => {
		if (state && currentProduct) {
			const _tempProducts = [...rows];

			_tempProducts[state.index].product = currentProduct;
			_tempProducts[state.index].amountPrice = currentProduct?.sellingPrice;

			setRows(_tempProducts);
		}
	}, [state, currentProduct]);

	useEffect(() => {
		actions.product.fetch();

		return () => {
			if (!rows.length) localStorage.removeItem(`quotation-row-${ticketId}`);
		};
	}, []);

	const handleChangeProduct = (index, name, value) => {
		const _tempProducts = [...rows];

		const row = _tempProducts[index];
		row[name] = value;

		if (name === 'product') {
			row['amountPrice'] = parseFloat(
				(row['quantity'] * value.sellingPrice).toFixed(2)
			);
		}

		if (name === 'quantity') {
			const totalPrice = parseFloat(value) * row.product['sellingPrice'];

			row['amountPrice'] = parseFloat(totalPrice.toFixed(2));
		}

		setRows(_tempProducts);
	};

	const handleAddNewRow = () => {
		setRows((prevRows) => [
			...prevRows,
			...JSON.parse(JSON.stringify(initialRow)),
		]);
	};

	const getSubTotalPrice = () => {
		const total: number =
			rows.reduce((price, item) => {
				return price + Number(item.amountPrice);
			}, 0) || 0;

		return Number(total.toFixed(2));
	};

	const handleRemoveRow = (index) => {
		rows.splice(index, 1);

		setRows([...rows]);
	};

	const formatPrice = (price: number) => {
		if (!price) return 0;

		return price.toLocaleString(defaultLocale, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	};

	const handleSubmit = () => {
		const products: any[] = rows.filter((row) => row.product !== '');

		const outOfStock = products.filter(
			(product: any) => product.deliveryTime > 0
		);

		const subTotalPrice = getSubTotalPrice();
		const totalPrice = Number((subTotalPrice - totalDiscount).toFixed(2));
		const payload: QuotationPayload = {
			title: currentTicket?.title as string,
			products,
			totalDiscount,
			subTotalPrice,
			totalPrice,
			vessel: currentTicket?.vessel as Vessel,
			contact: currentTicket?.contact as Contact,
			client: currentTicket?.client as Client,
			purchaseOrderNumber: currentTicket?.purchaseOrderNumber as string,
			startDate: DateHelper.generalFormat(new Date()),
			endDate: DateHelper.generalFormat(addDays(new Date(), 7)),
			deliveryTime:
				outOfStock.length === products.length ? 'Out of stock' : 'In stock',
		};

		onSubmit(payload);
	};

	const isRowProductEmpty = () => {
		if (rows.find((row) => row.product === '')) {
			return true;
		}

		return false;
	};

	const isRowQuantityError = () => {
		if (rows.find((row) => row.quantity === '-')) {
			return true;
		}

		return false;
	};

	const hasError = () => {
		if (getSubTotalPrice() === 0 || isRowQuantityError()) {
			return true;
		}

		return false;
	};

	return (
		<>
			<Grid container>
				<Grid item>
					<TableContainer className={classes.table}>
						<Table aria-label="quotation table">
							<TableHead>
								<TableRow className={classes.header}>
									<TableCell width="4%" align="center">
										No.
									</TableCell>
									<TableCell width="33%">Product</TableCell>
									<TableCell align="right" width="10%">
										Delivery time
									</TableCell>
									<TableCell align="right" width="10%">
										Qty
									</TableCell>
									<TableCell align="center" width="5%">
										Unit
									</TableCell>
									<TableCell align="right" width="13%">
										Unit Price
									</TableCell>
									<TableCell align="right" width="15%">
										Amount
									</TableCell>
									<TableCell align="center" width="10%" />
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, index) => (
									<TableRow key={index} className={classes.product}>
										<TableCell width="10px" align="center">
											{index + 1}
										</TableCell>
										<TableCell component="th" scope="row">
											<Box display="flex" alignItems="center">
												{row.product && !row.product.isAccepted && (
													<InputAdornment position="start">
														<NewReleasesIcon color="error" />
													</InputAdornment>
												)}
												<Autocomplete
													data-id={index}
													fullWidth
													freeSolo
													disableClearable
													value={row.product}
													onChange={(event, product) => {
														if (!product) return;

														if (typeof product === 'string') {
															// timeout to avoid instant validation of the dialog's form.
															setTimeout(() => {
																history.push('/products/add', {
																	name: product,
																	ticketId: currentTicket?.id,
																	index,
																	isEdit,
																});
															});
														} else if (product && product.inputValue) {
															history.push('/products/add', {
																name: product.inputValue,
																ticketId: currentTicket?.id,
																index,
																isEdit,
															});
														} else {
															handleChangeProduct(index, 'product', product);
														}
													}}
													filterOptions={(options, { inputValue }) => {
														const filtered = matchSorter(options, inputValue, {
															keys: ['name', 'tags'],
														});

														if (inputValue !== '') {
															filtered.push({
																inputValue: inputValue,
																name: `Add "${inputValue}"`,
															});
														}

														return filtered;
													}}
													options={products}
													getOptionLabel={(option) => {
														if (typeof option === 'string') {
															return option;
														}

														if (option.inputValue) {
															return option.inputValue;
														}

														return option.name;
													}}
													renderOption={(option) => option.name}
													renderInput={(params) => <TextField {...params} />}
												/>
											</Box>
										</TableCell>

										<TableCell align="right">
											<TextField
												fullWidth
												name="deliveryTime"
												value={row.deliveryTime}
												onChange={(event) =>
													handleChangeProduct(
														index,
														event.target.name,
														event.target.value
													)
												}
												InputProps={{
													inputComponent: NumberField as any,
												}}
												inputProps={{ style: { textAlign: 'right' } }}
											/>
										</TableCell>

										<TableCell align="right">
											<TextField
												fullWidth
												name="quantity"
												value={row.quantity}
												onChange={(event) => {
													const value =
														typeof event.target.value === 'number'
															? parseFloat(event.target.value)
															: event.target.value;
													handleChangeProduct(index, event.target.name, value);
												}}
												inputProps={{ style: { textAlign: 'right' } }}
												error={row.product !== '' && row.quantity === '-'}
											/>
										</TableCell>

										<TableCell align="center">
											{(row.product && row.product.unit) || '-'}
										</TableCell>
										<TableCell align="right">
											{formatPrice(
												(row.product && row.product.sellingPrice) || 0
											)}
										</TableCell>

										<TableCell align="right">
											{formatPrice(row.amountPrice)}
										</TableCell>

										<TableCell align="center">
											{rows.length - 1 === index && row.product && (
												<Button
													size="small"
													variant="contained"
													color="default"
													endIcon={<Icon>add</Icon>}
													onClick={handleAddNewRow}
												>
													Add
												</Button>
											)}

											{index !== 0 &&
												(rows.length - 1 !== index || isRowProductEmpty()) && (
													<IconButton
														size="small"
														onClick={() => handleRemoveRow(index)}
													>
														<DeleteOutlineIcon />
													</IconButton>
												)}
										</TableCell>
									</TableRow>
								))}
								<TableRow className={classes.footer}>
									<TableCell colSpan={6} align="right">
										Discount
									</TableCell>
									<TableCell align="right">
										<TextField
											fullWidth
											name="totalDiscount"
											defaultValue={totalDiscount}
											onChange={(event) => {
												const subTotalPrice = getSubTotalPrice();
												const totalDiscount =
													(subTotalPrice * toInteger(event.target.value)) / 100;

												setTotalDiscount(Number(totalDiscount.toFixed(2)));
											}}
											InputProps={{
												inputComponent: NumberField as any,
												endAdornment: (
													<InputAdornment position="start">%</InputAdornment>
												),
											}}
											inputProps={{
												style: { textAlign: 'right' },
											}}
										/>
									</TableCell>
									<TableCell />
								</TableRow>
								<TableRow className={classes.footer}>
									<TableCell colSpan={6} align="right">
										Total
									</TableCell>
									<TableCell align="right">
										USD {formatPrice(getSubTotalPrice() - totalDiscount)}
									</TableCell>
									<TableCell />
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>

					<Box display="flex" flexWrap="wrap" className={classes.button}>
						<ContainedButton
							endIcon={<Icon>navigate_next</Icon>}
							onClick={() => handleSubmit()}
							disabled={hasError() || isLoading}
							isLoading={isLoading}
						>
							Next
						</ContainedButton>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default TableForm;
