/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Breadcrumbs,
	Button,
	createStyles,
	DialogActions,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ContainedButton, Page, PageTitle } from '../../components';
import { pattern, TicketStatus } from '../../constants';
import { isFieldError } from '../../helpers';
import { Client, Ticket, User } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import ContactFields from './components/ContactFields';
import {
	Assignee,
	Attachments,
	Description,
	Details,
} from './components/details';
import VesselFields from './components/VesselFields';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			marginBottom: theme.spacing(3),
		},
		form: {
			'& > *': {
				width: '100%',
				marginBottom: theme.spacing(2),
			},
		},
		breadcrumbs: {
			marginBottom: theme.spacing(2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: 'none',

			'&:hover': {
				color: theme.palette.primary.light,
			},
		},
	})
);

const colors = [
	'#E57373',
	'#F06292',
	'#BA68C8',
	'#9575CD',
	'#7986CB',
	'#7986CB',
	'#4FC3F7',
	'#4DD0E1',
	'#4DB6AC',
	'#81C784',
	'#AED581',
	'#DCE775',
	'#FFF176',
	'#FFD54F',
	'#FFB74D',
	'#A1887F',
	'#E0E0E0',
];

const validationSchema = Yup.object().shape({
	clientId: Yup.string(),
	company: Yup.string().required('This field is required.'),
	vesselName: Yup.string().when('clientId', {
		is: (val) => {
			return val && val.length ? false : true;
		},
		then: Yup.string().required('This field is required'),
	}),
	vesselType: Yup.string().when('clientId', {
		is: (val) => {
			return val && val.length ? false : true;
		},
		then: Yup.string().required('This field is required'),
	}),
	contactName: Yup.string().when('clientId', {
		is: (val) => {
			return val && val.length ? false : true;
		},
		then: Yup.string().required('This field is required'),
	}),
	contactPhone: Yup.string().when('clientId', {
		is: (val) => {
			return val && val.length ? false : true;
		},
		then: Yup.string()
			.required('This field is required')
			.matches(
				pattern.phoneNumber,
				'This phone number is invalid. Please enter a valid phone number.'
			),
	}),
	contactEmail: Yup.string().when('clientId', {
		is: (val) => {
			return val && val.length ? false : true;
		},
		then: Yup.string()
			.required('This field is required')
			.email('This email address is invalid. Please enter a valid address.'),
	}),
	assignee: Yup.string().required('This field is required.'),
});

interface ClientOptionType extends Partial<Client> {
	inputValue?: string;
}

const filter = createFilterOptions<ClientOptionType>();

const EditScreen = () => {
	const classes = useStyles();
	const { ticketId } = useParams<{
		ticketId: string;
	}>();

	const { state, actions } = useOvermind();

	useEffect(() => {
		if (!state.project.data) actions.project.get();

		if (ticketId) {
			actions.ticket.getById(ticketId);

			actions.client.fetch();

			if (state.ticket.currentTicket?.status === TicketStatus.NEW) {
				onUpdate({ status: TicketStatus.ASSESSMENT });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketId]);

	useEffect(() => {
		if (state.ticket.currentTicket && state.ticket.currentTicket.client) {
			actions.client.setCurrentClient(state.ticket.currentTicket.client);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.ticket.currentTicket]);

	const clientData: ClientOptionType[] = [...state.client.data];

	const color = colors[Math.floor(Math.random() * colors.length)];

	const initialValues = {
		clientId:
			(state.ticket.currentTicket?.client &&
				state.ticket.currentTicket?.client.id) ||
			'',
		company:
			(state.ticket.currentTicket?.client &&
				state.ticket.currentTicket?.client.name) ||
			'',
		vesselId:
			(state.ticket.currentTicket?.vessel &&
				state.ticket.currentTicket?.vessel.id) ||
			'',
		vesselName:
			(state.ticket.currentTicket?.vessel &&
				state.ticket.currentTicket?.vessel.name) ||
			'',
		vesselType:
			(state.ticket.currentTicket?.vessel &&
				state.ticket.currentTicket?.vessel.type) ||
			'',
		contactId:
			(state.ticket.currentTicket?.contact &&
				state.ticket.currentTicket?.contact.id) ||
			'',
		contactName:
			(state.ticket.currentTicket?.contact &&
				state.ticket.currentTicket?.contact.name) ||
			'',
		contactPhone:
			(state.ticket.currentTicket?.contact &&
				state.ticket.currentTicket?.contact.phone) ||
			'',
		contactEmail:
			(state.ticket.currentTicket?.contact &&
				state.ticket.currentTicket?.contact.email) ||
			'',
		invoiceAddress:
			(state.ticket.currentTicket?.client &&
				state.ticket.currentTicket?.client.invoiceAddress) ||
			'',
		nationality:
			(state.ticket.currentTicket?.client &&
				state.ticket.currentTicket?.client.nationality) ||
			'',
		durationStay:
			(state.ticket.currentTicket?.client &&
				state.ticket.currentTicket?.client.durationStay) ||
			'',
		crewMembers:
			(state.ticket.currentTicket?.client &&
				state.ticket.currentTicket?.client.crewMembers) ||
			'',
		purchaseOrderNumber: state.ticket.currentTicket?.purchaseOrderNumber || '',
		assignee:
			(state.ticket.currentTicket?.user &&
				state.ticket.currentTicket?.user.id) ||
			'',
		submitted: false,
	};

	const handleSubmit = async (values) => {
		const payload: Partial<Ticket> = {};

		if (state.ticket.currentTicket?.status === TicketStatus.ASSESSMENT) {
			payload.status = TicketStatus.OFFER;
			payload.color = color;
		}

		if (values.clientId) {
			if (values.vesselId) {
				payload.vessel = { id: values.vesselId };
			}

			if (values.contactId) {
				payload.contact = { id: values.contactId };
			}

			await onUpdate({
				...payload,
				client: {
					id: values.clientId,
				},
				purchaseOrderNumber: values.purchaseOrderNumber,
				user: { id: values.assignee } as User,
			});

			await actions.client.update({
				nationality: values.nationality,
				durationStay: values.durationStay,
				crewMembers: values.crewMembers,
				invoiceAddress: values.invoiceAddress,
			});

			if (values.vesselId) {
				await actions.client.updateVessel({
					id: values.vesselId,
					type: values.vesselType,
				});
			}

			if (values.contactId) {
				await actions.client.updateContact({
					id: values.contactId,
					phone: values.contactPhone,
					email: values.contactEmail,
				});
			}
		} else {
			await actions.client.create({
				name: values.company,
				nationality: values.nationality,
				durationStay: values.durationStay,
				crewMembers: values.crewMembers,
				invoiceAddress: values.invoiceAddress,
				vessel: {
					name: values.vesselName,
					type: values.vesselType,
				},
				contact: {
					name: values.contactName,
					phone: values.contactPhone,
					email: values.contactEmail,
				},
			});

			console.log(state.client.currentClient);

			await onUpdate({
				...payload,
				client: { id: state.client.currentClient?.id },
				vessel: {
					id: state.client.currentClient?.vessels[0].id,
				},
				contact: {
					id: state.client.currentClient?.contacts[0].id,
				},
				purchaseOrderNumber: values.purchaseOrderNumber,
				user: { id: values.assignee } as User,
			});
		}

		history.push({
			pathname: `/projects/ticket/${ticketId}`,
		});
	};

	const onChangeClient = async (
		clientId: string | undefined,
		setFieldValue: any
	) => {
		if (!clientId) return;

		await actions.client.fetchVessel(clientId);

		const client = state.client.data.find(
			(item: Client) => item.id === clientId
		);

		if (!client) return;

		actions.client.setCurrentClient(client);

		setFieldValue('clientId', client.id);
		setFieldValue('nationality', client.nationality);
		setFieldValue('durationStay', client.durationStay);
		setFieldValue('crewMembers', client.crewMembers);
		setFieldValue('invoiceAddress', client.invoiceAddress);
	};

	const clearFields = (setFieldValue: any) => {
		setFieldValue('clientId', '');
		setFieldValue('nationality', '');
		setFieldValue('durationStay', '');
		setFieldValue('crewMembers', '');
		setFieldValue('invoiceAddress', '');
		setFieldValue('contactName', '');
		setFieldValue('contactPhone', '');
		setFieldValue('contactEmail', '');
	};

	const onUpdate = async (payload: Partial<Ticket>) => {
		await actions.ticket.update(payload);
	};

	return (
		<Page title="Edit Ticket">
			<Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
				<Link to={`/projects`} className={classes.link}>
					Overview
				</Link>
				<Link to={`/projects/ticket/${ticketId}`} className={classes.link}>
					PRO - {ticketId}
				</Link>
				<Typography color="textPrimary">Edit Ticket</Typography>
			</Breadcrumbs>

			<PageTitle gutterBottom>Edit Ticket</PageTitle>

			<Grid container spacing={4}>
				<Grid item md={8}>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						{({
							values,
							errors,
							touched,
							setFieldValue,
							handleChange,
							handleBlur,
							handleSubmit,
						}) => (
							<form noValidate onSubmit={handleSubmit}>
								<Grid container spacing={5}>
									<Grid item xs={6} className={classes.form}>
										<Field
											type="hidden"
											name="clientId"
											value={values.clientId}
										/>

										<Autocomplete
											selectOnFocus
											clearOnBlur
											handleHomeEndKeys
											value={values.company}
											onChange={(event, newValue) => {
												if (!newValue) {
													setFieldValue('company', '');
													clearFields(setFieldValue);

													return;
												}

												if (typeof newValue === 'string') {
													setFieldValue('company', newValue);
													setFieldValue('clientId', '');
													setFieldValue('vesselName', '');
													setFieldValue('vesselType', '');
													clearFields(setFieldValue);
												} else if (newValue && newValue.inputValue) {
													setFieldValue('company', newValue.inputValue);
													setFieldValue('clientId', '');
													setFieldValue('vesselName', '');
													setFieldValue('vesselType', '');
													clearFields(setFieldValue);
												} else {
													setFieldValue('company', newValue.name);

													onChangeClient(newValue.id, setFieldValue);
												}
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params);

												if (params.inputValue !== '') {
													filtered.push({
														inputValue: params.inputValue,
														name: `Add "${params.inputValue}"`,
													});
												}

												return filtered;
											}}
											options={clientData}
											getOptionLabel={(option) => {
												if (typeof option === 'string') {
													return option;
												}

												if (option.inputValue) {
													return option.inputValue;
												}

												return option.name!;
											}}
											renderOption={(option) => option.name}
											freeSolo
											renderInput={(params) => (
												<TextField
													{...params}
													label="Company name"
													error={isFieldError(touched.company, errors.company)}
													helperText={touched.company && errors.company}
												/>
											)}
										/>
										<VesselFields />

										<ContactFields />
									</Grid>

									<Grid item xs={6} className={classes.form}>
										<TextField
											label="Nationality"
											name="nationality"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.nationality}
										/>

										<TextField
											label="Duration stay"
											name="durationStay"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.durationStay}
										/>
										<TextField
											label="Crew members"
											name="crewMembers"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.crewMembers}
										/>

										<TextField
											label="Invoice address"
											name="invoiceAddress"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.invoiceAddress}
										/>

										<TextField
											label="Request/PO number"
											name="purchaseOrderNumber"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.purchaseOrderNumber}
										/>

										<FormControl fullWidth>
											<InputLabel id="assignee-label">Assignee</InputLabel>
											<Select
												labelId="assignee-label"
												name="assignee"
												value={values.assignee}
												onChange={handleChange}
												onBlur={handleBlur}
											>
												{state.project.data &&
													state.project.data?.users &&
													state.project.data?.users.map(
														(user: User, index: number) => (
															<MenuItem key={index} value={user.id}>
																{user.name}
															</MenuItem>
														)
													)}
											</Select>

											{touched.assignee && errors.assignee && (
												<FormHelperText error>{errors.assignee}</FormHelperText>
											)}
										</FormControl>
									</Grid>
								</Grid>

								<DialogActions>
									<Button
										variant="text"
										color="primary"
										onClick={() => history.goBack()}
									>
										Cancel
									</Button>
									<ContainedButton
										type="submit"
										isLoading={state.ticket.isLoading || state.client.isLoading}
									>
										Save
									</ContainedButton>
								</DialogActions>
							</form>
						)}
					</Formik>
				</Grid>
				<Grid item md={4}>
					<Details />
					<Description ticket={state.ticket.currentTicket!} />
					<Assignee user={state.ticket.currentTicket?.user!} />
					<Attachments />
				</Grid>
			</Grid>
		</Page>
	);
};

export default EditScreen;
