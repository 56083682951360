import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import DataTable from 'react-data-table-component';
import { Ticket } from '../../../../models';
import history from '../../../../utils/history';
import { tableStyles } from '../../../../utils/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			padding: theme.spacing(3, 0, 0),
		},
		search: {
			marginRight: theme.spacing(2),
		},
		dropzone: {
			marginBottom: theme.spacing(2),
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

const columns = [
	{
		name: 'Number',
		sortable: true,
		cell: (row: Ticket) => `PRO-${row.id}`,
	},
	{
		name: 'Title',
		selector: 'title',
		sortable: true,
	},
	{
		name: 'Assignee',
		sortable: true,
		cell: (row: Ticket) => (row.user ? row.user.name : '-'),
	},
	{
		name: 'Open / Closed',
		sortable: true,
		cell: (row: Ticket) =>
			row.status === 'done' || row.status === 'invalid' ? 'Closed' : 'Open',
	},
];

interface Props {
	tickets: Ticket[];
}

function Projects(props: Props) {
	const { tickets } = props;
	const classes = useStyles();

	const handleRowClicked = (row: Ticket) => {
		history.push(`/projects/ticket/${row.id}`);
	};

	return (
		<DataTable
			customStyles={tableStyles}
			columns={columns}
			data={tickets}
			pagination
			highlightOnHover
			pointerOnHover
			noHeader
			className={classes.table}
			onRowClicked={handleRowClicked}
		/>
	);
}

export default Projects;
