import instance from '../../api/axios';
import { LoginPayload } from '../../features/authentication/models';

export const api = {
	async me() {
		try {
			const response = await instance.get('/users/me');

			return response;
		} catch (error) {
			throw error;
		}
	},

	async login(payload: LoginPayload) {
		try {
			const response = await instance.post('/authentication/login', payload);

			return response;
		} catch (error) {
			throw error;
		}
	},
};
