import { Box, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Page, PageTitle, TextField } from '../../components';
import DateHelper from '../../helpers/date';
import { Template } from '../../models';
import { useOvermind } from '../../overmind';
import history from '../../utils/history';
import { tableStyles } from '../../utils/styles';
import { LinearIndeterminate } from '../mails/components';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			padding: theme.spacing(3, 0, 0),
		},
		action: {
			'& > *': {
				marginLeft: theme.spacing(1),
			},
		},
		dropzone: {
			marginBottom: theme.spacing(2),
		},
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

const columns = [
	{
		name: 'Name',
		selector: 'name',
		sortable: true,
	},
	{
		name: 'Created',
		selector: 'createdAt',
		format: (row) => DateHelper.distanceToNowFormat(row.createdAt),
		sortable: true,
	},
	{
		name: 'Modified',
		selector: 'updatedAt',
		format: (row) => DateHelper.distanceToNowFormat(row.updatedAt),
		sortable: true,
	},
];

function ListScreen() {
	const classes = useStyles();

	const {
		state: {
			template: { data, isLoading },
		},
		actions,
	} = useOvermind();

	const [filterText, setFilterText] = useState('');
	const filteredItems = data.filter(
		(template: Template) =>
			template.name &&
			template.name.toLowerCase().includes(filterText.toLowerCase())
	);

	useEffect(() => {
		actions.template.fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRowClicked = (row: Template) => {
		history.push(`/templates/${row.id}/edit`);
	};

	return (
		<Page title="Templates">
			<PageTitle>Templates</PageTitle>

			<Box paddingX={2} paddingY={3}>
				<Box
					display="flex"
					justifyContent="flex-end"
					className={classes.action}
				>
					<TextField
						variant="outlined"
						placeholder="Search"
						type="search"
						value={filterText}
						onChange={(e) => setFilterText(e.target.value)}
					/>

					<Button
						variant="contained"
						color="inherit"
						startIcon={<AddIcon />}
						onClick={() => history.push('/templates/new')}
					>
						New
					</Button>
				</Box>

				<DataTable
					customStyles={tableStyles}
					columns={columns}
					data={filteredItems}
					pagination
					highlightOnHover
					pointerOnHover
					noHeader
					progressPending={isLoading}
					progressComponent={<LinearIndeterminate />}
					persistTableHead
					className={classes.table}
					onRowClicked={handleRowClicked}
				/>
			</Box>
		</Page>
	);
}

export default ListScreen;
