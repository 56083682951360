import { json } from 'overmind';
import { useOvermind } from '../overmind';

function useCurrentProject() {
	const {
		state: {
			project: { data },
		},
	} = useOvermind();

	return { currentProject: json(data) };
}

export default useCurrentProject;
